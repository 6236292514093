import { createStitches } from '@stitches/react'
import type { PropertyValue, CSS as StitchesCSS } from '@stitches/react'
const breakpoints = {
    xs: '390px',
    sm: '767px',
    md: '900px',
    lg: '1024px',
    xl: '1280px',
    xxl: '1440px',
}

export const { styled, css, config, createTheme, keyframes, globalCss, theme } = createStitches({
    theme: {
        colors: {},
        space: {
            '0-25': '2px',
            '0-5': '4px',
            '0-75': '6px',
            1: '8px',
            '1-25': '10px',
            '1-5': '12px',
            '1-75': '14px',
            2: '16px',
            '2-25': '18px',
            '2-5': '20px',
            '2-75': '22px',
            3: '24px',
            '3-5': '28px',
            4: '32px',
            '4-5': '36px',
            5: '40px',
            '5-5': '44px',
            6: '48px',
            '6-5': '52px',
            7: '56px',
            '7-5': '60px',
            8: '64px',
            '8-5': '68px',
            9: '72px',
            '9-5': '76px',
            10: '80px',
        },
        fontSizes: {},
        fonts: {
            inter: `Inter, Arial, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
            Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif`,
        },
        fontWeights: {},
        lineHeights: {},
        letterSpacings: {},
        sizes: {
            '0-25': '2px',
            '0-5': '4px',
            '0-75': '6px',
            1: '8px',
            '1-25': '10px',
            '1-5': '12px',
            '1-75': '14px',
            2: '16px',
            '2-25': '18px',
            '2-5': '20px',
            '2-75': '22px',
            3: '24px',
            '3-5': '28px',
            4: '32px',
            '4-5': '36px',
            5: '40px',
            '5-5': '44px',
            6: '48px',
            '6-5': '52px',
            7: '56px',
            '7-5': '60px',
            8: '64px',
            '8-5': '68px',
            9: '72px',
            '9-5': '76px',
            10: '80px',
        },
        borderWidths: {},
        borderStyles: {},
        radii: {
            '0-25': '2px',
            '0-5': '4px',
            '0-75': '6px',
            1: '8px',
            '1-25': '10px',
            '1-5': '12px',
            '1-75': '14px',
            2: '16px',
            'round': '9999px',
        },
        shadows: {},
        zIndices: {},
        transitions: {},
    },
    media: {
        bp1: '(min-width: 767px)',
        xs: `(max-width: calc(${breakpoints.xs} - 1px))`,
        sm: `(max-width: calc(${breakpoints.sm} - 1px))`,
        md: `(min-width: ${breakpoints.sm}) and (max-width: calc(${breakpoints.md} - 1px))`,
        lg: `(min-width: ${breakpoints.md}) and (max-width: calc(${breakpoints.lg} - 1px))`,
        xl: `(min-width: ${breakpoints.lg}) and (max-width: calc(${breakpoints.xl} - 1px))`,
        xxl: `(min-width: ${breakpoints.xl}) and (max-width: ${breakpoints.xxl})`,
        notXs: `(min-width: ${breakpoints.xs})`,
        notSm: `(min-width: ${breakpoints.sm})`,
        notMd: `(min-width: ${breakpoints.md})`,
        notLg: `(min-width: ${breakpoints.lg})`,
        notXl: `(min-width: ${breakpoints.xl})`,
        notXxl: `(min-width: calc(${breakpoints.xxl} + 1px ))`,
        minXs: `(min-width: ${breakpoints.xs})`,
        minSm: `(min-width: ${breakpoints.sm})`,
        minMd: `(min-width: ${breakpoints.md})`,
        minLg: `(min-width: ${breakpoints.lg})`,
        minXl: `(min-width: ${breakpoints.xl})`,
        minXxl: `(min-width: calc(${breakpoints.xxl} + 1px ))`,
        maxXs: `(max-width: calc(${breakpoints.xs} - 1px))`,
        maxSm: `(max-width: calc(${breakpoints.sm} - 1px))`,
        maxMd: `(max-width: calc(${breakpoints.md} - 1px))`,
        maxLg: `(max-width: calc(${breakpoints.lg} - 1px))`,
        maxXl: `(max-width: calc(${breakpoints.xl} - 1px))`,
        maxXxl: `(max-width: ${breakpoints.xxl})`,
        hover: '(any-hover: hover)',
    },
    utils: {
        d: (value: PropertyValue<'display'>) => ({
            display: value,
        }),
        p: (value: PropertyValue<'padding'>) => ({
            padding: value,
        }),
        pt: (value: PropertyValue<'paddingTop'>) => ({
            paddingTop: value,
        }),
        pr: (value: PropertyValue<'paddingRight'>) => ({
            paddingRight: value,
        }),
        pb: (value: PropertyValue<'paddingBottom'>) => ({
            paddingBottom: value,
        }),
        pl: (value: PropertyValue<'paddingLeft'>) => ({
            paddingLeft: value,
        }),
        px: (value: PropertyValue<'paddingLeft'>) => ({
            paddingLeft: value,
            paddingRight: value,
        }),
        py: (value: PropertyValue<'paddingTop'>) => ({
            paddingTop: value,
            paddingBottom: value,
        }),
        m: (value: PropertyValue<'margin'>) => ({
            margin: value,
        }),
        mt: (value: PropertyValue<'marginTop'>) => ({
            marginTop: value,
        }),
        mr: (value: PropertyValue<'marginRight'>) => ({
            marginRight: value,
        }),
        mb: (value: PropertyValue<'marginBottom'>) => ({
            marginBottom: value,
        }),
        ml: (value: PropertyValue<'marginLeft'>) => ({
            marginLeft: value,
        }),
        mx: (value: PropertyValue<'marginLeft'>) => ({
            marginLeft: value,
            marginRight: value,
        }),
        my: (value: PropertyValue<'marginTop'>) => ({
            marginTop: value,
            marginBottom: value,
        }),
        ta: (value: PropertyValue<'textAlign'>) => ({
            textAlign: value,
        }),
        fd: (value: PropertyValue<'flexDirection'>) => ({
            flexDirection: value,
        }),
        fw: (value: PropertyValue<'flexWrap'>) => ({
            flexWrap: value,
        }),
        ai: (value: PropertyValue<'alignItems'>) => ({
            alignItems: value,
        }),
        ac: (value: PropertyValue<'alignContent'>) => ({
            alignContent: value,
        }),
        jc: (value: PropertyValue<'justifyContent'>) => ({
            justifyContent: value,
        }),
        as: (value: PropertyValue<'alignSelf'>) => ({
            alignSelf: value,
        }),
        fg: (value: PropertyValue<'flexGrow'>) => ({
            flexGrow: value,
        }),
        fs: (value: PropertyValue<'flexShrink'>) => ({
            flexShrink: value,
        }),
        fb: (value: PropertyValue<'flexBasis'>) => ({
            flexBasis: value,
        }),
        bc: (value: PropertyValue<'backgroundColor'>) => ({
            backgroundColor: value,
        }),
        br: (value: PropertyValue<'borderRadius'>) => ({
            borderRadius: value,
        }),
        btrr: (value: PropertyValue<'borderTopRightRadius'>) => ({
            borderTopRightRadius: value,
        }),
        bbrr: (value: PropertyValue<'borderBottomRightRadius'>) => ({
            borderBottomRightRadius: value,
        }),
        bblr: (value: PropertyValue<'borderBottomLeftRadius'>) => ({
            borderBottomLeftRadius: value,
        }),
        btlr: (value: PropertyValue<'borderTopLeftRadius'>) => ({
            borderTopLeftRadius: value,
        }),
        bs: (value: PropertyValue<'boxShadow'>) => ({
            boxShadow: value,
        }),
        lh: (value: PropertyValue<'lineHeight'>) => ({
            lineHeight: value,
        }),
        ox: (value: PropertyValue<'overflowX'>) => ({
            overflowX: value,
        }),
        oy: (value: PropertyValue<'overflowY'>) => ({
            overflowY: value,
        }),
        pe: (value: PropertyValue<'pointerEvents'>) => ({
            pointerEvents: value,
        }),
        us: (value: PropertyValue<'userSelect'>) => ({
            WebkitUserSelect: value,
            userSelect: value,
        }),
        userSelect: (value: PropertyValue<'userSelect'>) => ({
            WebkitUserSelect: value,
            userSelect: value,
        }),
        size: (value: PropertyValue<'width'>) => ({
            width: value,
            height: value,
        }),
        appearance: (value: PropertyValue<'appearance'>) => ({
            WebkitAppearance: value,
            appearance: value,
        }),
        backgroundClip: (value: PropertyValue<'backgroundClip'>) => ({
            WebkitBackgroundClip: value,
            backgroundClip: value,
        }),
    },
    prefix: 'eratar',
})

export type CSS = StitchesCSS<typeof config>

export const darkTheme = createTheme('dark-theme', {
    colors: {},
    space: {},
    fontSizes: {},
    fonts: {},
    fontWeights: {},
    lineHeights: {},
    letterSpacings: {},
    sizes: {},
    borderWidths: {},
    borderStyles: {},
    radii: {},
    shadows: {},
    zIndices: {},
    transitions: {},
})

export const globalStyles = globalCss({
    ':root': {
        fontFamily: '$inter',
    },
    '*': {
        'box-sizing': 'border-box',
    },
    '*:before': {
        'box-sizing': 'border-box',
    },
    '*:after': {
        'box-sizing': 'border-box',
    },
    'body, html': {
        border: 0,
        margin: 0,
        padding: 0,
        height: '100vh',
        fontFamily: '$inter',
        bc: '#F7F8FC',
    },
    'body': {
        fontSize: '16px',
    },
    '#root': {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
    },
    'p': {
        padding: 0,
        margin: 0,
        fontWeight: 400,
    },
    'h1, h2, h3, h4, h5, h6': {
        padding: 0,
        margin: 0,
        fontWeight: 400,
    },
    'button': {
        'cursor': 'pointer',
        'border': 'none',
        'padding': 0,
        'margin': 0,
        '&:focus': {
            outline: 'none',
        },
    },
    'ul': {
        listStyle: 'none',
        margin: 0,
        padding: 0,
    },
    'a': {
        textDecoction: 'none',
    },
    'a:-webkit-any-link': {
        textDecoration: 'none',
    },
})
