import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import App from 'app/App'

import 'library/i18n'

if (!import.meta.env.DEV) {
    Sentry.init({
        dsn: 'https://4e9a9b0341a44073b58a4512be1c7c5c@o4505017797378048.ingest.sentry.io/4505017798557696',
        integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
        // Performance Monitoring
        environment: window.location.hostname === 'beta.eratar.rs' ? 'production' : 'staging',
        tracesSampleRate: window.location.hostname === 'beta.eratar.rs' ? 0.7 : 1.0, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.StrictMode>,
)
