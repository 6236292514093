import * as DialogPrimitive from '@radix-ui/react-dialog'
import { ComponentProps, forwardRef } from 'react'

import CloseIcon from 'assets/icons/close.svg'

import { keyframes, styled } from './stitches.config'
import { Image } from './Common'
interface DialogContentProps extends ComponentProps<typeof DialogContent> {
    title?: string
    description?: string
    overlayCss?: ComponentProps<typeof DialogOverlay>['css']
    titleCss?: ComponentProps<typeof DialogTitle>['css']
    descriptionCss?: ComponentProps<typeof DialogDescription>['css']
    showCloseIcon?: boolean
    contentCss?: ComponentProps<typeof DialogContent>['css']
}

export const DialogBody = forwardRef<any, DialogContentProps>(
    (
        { children, overlayCss = {}, title, description, showCloseIcon, contentCss = {}, ...props },
        forwardedRef,
    ) => (
        <DialogPrimitive.Portal
            onClick={(e) => {
                e.stopPropagation()
            }}
        >
            <DialogOverlay css={overlayCss} />
            <DialogContent
                {...props}
                ref={forwardedRef}
                onClick={(e) => {
                    e.stopPropagation()
                }}
                css={contentCss}
            >
                {!!title && (
                    <DialogTitle
                        css={{
                            mb: description ? '$0-5' : undefined,
                        }}
                    >
                        {title}
                    </DialogTitle>
                )}
                {!!description && <DialogDescription>{description}</DialogDescription>}

                {children}
                {showCloseIcon && (
                    <DialogClose
                        aria-label="Close"
                        css={{
                            position: 'absolute',
                            top: '$3',
                            right: '$2',
                            background: 'transparent',
                            size: 14,
                        }}
                    >
                        <Image
                            src={CloseIcon}
                            css={{
                                size: 14,
                            }}
                        />
                    </DialogClose>
                )}
            </DialogContent>
        </DialogPrimitive.Portal>
    ),
)

const overlayShow = keyframes({
    '0%': { opacity: 0 },
    '100%': { opacity: 1 },
})

const contentShow = keyframes({
    '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
    '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
})

const DialogOverlay = styled(DialogPrimitive.Overlay, {
    backgroundColor: 'rgba(0, 0, 0, 0.64)',
    position: 'fixed',
    inset: 0,
    animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
    zIndex: 2,
})

const DialogContent = styled(DialogPrimitive.Content, {
    'backgroundColor': 'white',
    'borderRadius': '$1',
    'overflowY': 'auto',
    'position': 'fixed',
    'top': '50%',
    'left': '50%',
    'transform': 'translate(-50%, -50%)',
    'width': '90vw',
    'maxWidth': '450px',
    'maxHeight': '85vh',
    'padding': '$3',
    'animation': `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
    '&:focus': { outline: 'none' },
    '@maxMd': {
        maxWidth: 'none',
        width: '100vw',
        maxHeight: '100vh',
        height: '100vh',
        overflow: 'scroll',
        borderRadius: 0,
    },
    'zIndex': 2,
})
export const Dialog = DialogPrimitive.Root
export const DialogTrigger = DialogPrimitive.Trigger
export const DialogClose = styled(DialogPrimitive.Close)
export const DialogTitle = styled(DialogPrimitive.Title, {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
    mb: '$3',
})
export const DialogDescription = styled(DialogPrimitive.Description, {
    color: 'background: rgba(0, 0, 0, 0.64)',
    fontWeight: 400,
    fontSize: '14px',
    mb: '$3',
})
