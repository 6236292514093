import { Link } from 'react-router-dom'

import { useAgroCulturesQuery } from 'hooks/api'

import { Button } from 'ui/Button'
import { Box, Image, Text } from 'ui/Common'
import { DashboardPageLayout } from 'ui/DashboardPageLayout'

import PlaceholderIcon from 'assets/icons/placeholder.png'

export const CulturesPage = () => {
    const { data } = useAgroCulturesQuery()

    const filteredCultures = data ? data?.filter((culture) => culture.selected) : []
    return (
        <DashboardPageLayout>
            <Box
                css={{
                    d: 'flex',
                    gap: '$2',
                    jc: 'space-between',
                    ai: 'center',
                }}
            >
                <Box
                    css={{
                        d: 'flex',
                        fd: 'column',
                        gap: '$1',
                    }}
                >
                    <Text
                        css={{
                            color: '#2C3E50',
                            fontWeight: 600,
                            fontSize: '24px',
                            lineHeight: '28px',
                        }}
                    >
                        Kulture
                    </Text>
                    <Text
                        css={{
                            'color': 'rgba(0, 0, 0, 0.64)',
                            'fontWeight': 400,
                            'fontSize': '14px',
                            'lineHeight': '16.94px',
                            '@maxSm': {
                                display: 'none',
                            },
                        }}
                    >
                        Ispod se nalaze aktivne kulture, možete ih izmeniti u svakom trenutku.
                    </Text>
                </Box>

                <Link to="/kontrolna-tabla/podaci/kulture/izmeni">
                    <Button variant="cta">Izmeni kulture</Button>
                </Link>
            </Box>
            {!!filteredCultures && !!filteredCultures.length ? (
                <Box
                    css={{
                        'd': 'grid',
                        'gridTemplateColumns': 'repeat(3, minmax(200px, 1fr))',
                        'gap': '$2-5 $1-25',
                        '@maxSm': {
                            gridTemplateColumns: 'repeat(2, 1fr)',
                        },
                    }}
                >
                    {filteredCultures.map((culture) => {
                        const cultureIcon = (
                            <Box
                                css={{
                                    size: 28,
                                    background: 'rgba(83, 165, 81, 0.08)',
                                    br: '$0-5',
                                    d: 'flex',
                                    ai: 'center',
                                    jc: 'center',
                                }}
                            >
                                {culture?.iconPath ? (
                                    <Image
                                        src={culture.iconPath}
                                        css={{
                                            size: 20,
                                        }}
                                    />
                                ) : (
                                    <Image
                                        src={PlaceholderIcon}
                                        css={{
                                            size: 20,
                                        }}
                                    />
                                )}
                            </Box>
                        )
                        return (
                            <Box
                                key={culture.id}
                                css={{
                                    'd': 'flex',
                                    'fd': 'column',
                                    'ai': 'flex-start',
                                    'bc': '#ffffff',
                                    'border': '1px solid #E4E5E7',

                                    'transition':
                                        'box-shadow 0.2s ease-in-out, filter 0.3s ease-in-out',
                                    '&:hover': {
                                        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                                        filter: 'brightness(105%)',
                                        border: '1px solid rgba(83, 165, 81, 0.3)',
                                    },
                                    'br': '$1',
                                }}
                            >
                                <Box
                                    css={{
                                        d: 'flex',
                                        fd: 'column',
                                        ai: 'flex-start',
                                        bc: '#ffffff',
                                        gap: '$2',
                                        p: '$2',
                                        br: '$1',
                                        width: '100%',
                                    }}
                                >
                                    <Box
                                        css={{
                                            size: '$3-5',
                                            d: 'flex',
                                            ai: 'center',
                                            jc: 'center',
                                        }}
                                    >
                                        {cultureIcon ?? <div />}
                                    </Box>
                                    <Text
                                        css={{
                                            fontSize: '16px',
                                            fontWeight: 500,
                                            lineHeight: '19px',
                                            textAlign: 'left',
                                            color: '#2C3E50',
                                            fontFamily: '$inter',
                                            height: '19px',
                                        }}
                                    >
                                        {culture.name}
                                    </Text>
                                </Box>
                            </Box>
                        )
                    })}
                </Box>
            ) : (
                <Box
                    css={{
                        d: 'flex',
                        fd: 'column',
                        width: '100%',
                        justifyContent: 'center',
                        mt: 50,
                        gap: '$2',
                    }}
                >
                    <Box
                        css={{
                            d: 'flex',
                            fd: 'column',
                            gap: '$1-25',
                            textAlign: 'center',
                        }}
                    >
                        <Text
                            css={{
                                fontSize: '24px',
                                fontWeight: 600,
                                color: '#2C3E50',
                            }}
                        >
                            Još uvek niste dodali svoje kulture.
                        </Text>
                        <Text
                            css={{
                                fontSize: '14px',
                                color: 'rgba(0,0,0,0.64)',
                            }}
                        >
                            Dodajte ih klikom na dugme ispod.
                        </Text>
                    </Box>
                    <Box
                        css={{
                            d: 'flex',
                            jc: 'center',
                        }}
                    >
                        <Link to="/kontrolna-tabla/podaci/kulture/izmeni">
                            <Button variant="cta">Izmeni kulture</Button>
                        </Link>
                    </Box>
                </Box>
            )}
        </DashboardPageLayout>
    )
}
