import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { z } from 'zod'
import { t } from 'i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useState } from 'react'

import { FormInput } from 'components/FormInput'

import { useConfirmPasswordResetMutation } from 'hooks/api'

import { Button, GhostIconButton } from 'ui/Button'
import { Box, Form, Image, Text } from 'ui/Common'

import EratarLogo from 'assets/eratar_logo.png'

export const ResetPasswordPage = () => {
    const [showPassword, setShowPassword] = useState(false)
    const { state } = useLocation()
    const navigate = useNavigate()
    const params = useParams()
    const { mutateAsync, isLoading, error } = useConfirmPasswordResetMutation()

    const requestPasswordResetSchema = z
        .object({
            password: z.string().min(1, t('validation:password.min')),
            confirmPassword: z.string().min(1, t('validation:password.min')),
        })
        .superRefine(({ confirmPassword, password }, ctx) => {
            if (confirmPassword !== password) {
                ctx.addIssue({
                    code: 'custom',
                    path: ['confirmPassword'],
                    message: t('validation:password.noMatch'),
                })
            }
        })

    type requestPasswordResetValues = z.infer<typeof requestPasswordResetSchema>

    const form = useForm({
        resolver: zodResolver(requestPasswordResetSchema),
        defaultValues: { password: '', confirmPassword: '' },
    })

    const handleSubmit = async (values: requestPasswordResetValues) => {
        try {
            const response = await mutateAsync({
                newPassword: values.password,
                confirmationCode: params.id as string,
            })
            if (response.status === 200) {
                // display message for password reset
                navigate('/prijava', { state: { resetPassword: true } })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <FormProvider {...form}>
            <Form
                css={{
                    width: '100%',
                    height: '100%',
                    d: 'flex',
                    ai: 'center',
                    jc: 'center',
                    fd: 'column',
                    flexGrow: 1,
                    gap: '$5',
                    px: '$2',
                }}
                onSubmit={form.handleSubmit(handleSubmit)}
            >
                <Image src={EratarLogo} />

                <Box
                    css={{
                        maxWidth: '584px',
                        width: '100%',
                        br: '$1',
                        bc: '#ffffff',
                        border: '1px solid #E4E5E7',
                        d: 'flex',
                        fd: 'column',
                        gap: '$4',
                        py: '$8',
                        px: '$5',
                        ai: 'center',
                        margin: '0 auto',
                    }}
                >
                    <>
                        <Box
                            css={{
                                d: 'flex',
                                fd: 'column',
                                gap: '$0-5',
                                textAlign: 'center',
                            }}
                        >
                            <Text
                                as="h1"
                                css={{
                                    fontWeight: 600,
                                    fontSize: '20px',
                                    lineHeight: '24px',
                                }}
                            >
                                Resetuj lozinku
                            </Text>
                            <Text
                                as="h2"
                                css={{
                                    fontWeight: 400,
                                    lineHeight: '17px',
                                    fontSize: '14px',
                                }}
                            >
                                Unesite novu lozinku
                            </Text>
                        </Box>

                        <FormInput
                            name="password"
                            label="Lozinka"
                            placeholder="***"
                            required
                            type={showPassword ? 'text' : 'password'}
                            autoComplete="new-password"
                            rightEl={
                                <GhostIconButton
                                    onClick={() => setShowPassword((p) => !p)}
                                    type="button"
                                >
                                    {showPassword ? (
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M10.7429 5.09232C11.1494 5.03223 11.5686 5 12.0004 5C17.1054 5 20.4553 9.50484 21.5807 11.2868C21.7169 11.5025 21.785 11.6103 21.8231 11.7767C21.8518 11.9016 21.8517 12.0987 21.8231 12.2236C21.7849 12.3899 21.7164 12.4985 21.5792 12.7156C21.2793 13.1901 20.8222 13.8571 20.2165 14.5805M6.72432 6.71504C4.56225 8.1817 3.09445 10.2194 2.42111 11.2853C2.28428 11.5019 2.21587 11.6102 2.17774 11.7765C2.1491 11.9014 2.14909 12.0984 2.17771 12.2234C2.21583 12.3897 2.28393 12.4975 2.42013 12.7132C3.54554 14.4952 6.89541 19 12.0004 19C14.0588 19 15.8319 18.2676 17.2888 17.2766M3.00042 3L21.0004 21M9.8791 9.87868C9.3362 10.4216 9.00042 11.1716 9.00042 12C9.00042 13.6569 10.3436 15 12.0004 15C12.8288 15 13.5788 14.6642 14.1217 14.1213"
                                                stroke="#6B7280"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                    ) : (
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g clipPath="url(#clip0_1118_26716)">
                                                <path
                                                    d="M12 6.5C15.79 6.5 19.17 8.63 20.82 12C19.17 15.37 15.8 17.5 12 17.5C8.2 17.5 4.83 15.37 3.18 12C4.83 8.63 8.21 6.5 12 6.5ZM12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 9.5C13.38 9.5 14.5 10.62 14.5 12C14.5 13.38 13.38 14.5 12 14.5C10.62 14.5 9.5 13.38 9.5 12C9.5 10.62 10.62 9.5 12 9.5ZM12 7.5C9.52 7.5 7.5 9.52 7.5 12C7.5 14.48 9.52 16.5 12 16.5C14.48 16.5 16.5 14.48 16.5 12C16.5 9.52 14.48 7.5 12 7.5Z"
                                                    fill="#6B7280"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1118_26716">
                                                    <rect width="24" height="24" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    )}
                                </GhostIconButton>
                            }
                        />
                        <FormInput
                            name="confirmPassword"
                            label="Potvrdi lozinku"
                            placeholder="***"
                            required
                            type={showPassword ? 'text' : 'password'}
                            autoComplete="new-password"
                            rightEl={
                                <GhostIconButton
                                    onClick={() => setShowPassword((p) => !p)}
                                    type="button"
                                >
                                    {showPassword ? (
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M10.7429 5.09232C11.1494 5.03223 11.5686 5 12.0004 5C17.1054 5 20.4553 9.50484 21.5807 11.2868C21.7169 11.5025 21.785 11.6103 21.8231 11.7767C21.8518 11.9016 21.8517 12.0987 21.8231 12.2236C21.7849 12.3899 21.7164 12.4985 21.5792 12.7156C21.2793 13.1901 20.8222 13.8571 20.2165 14.5805M6.72432 6.71504C4.56225 8.1817 3.09445 10.2194 2.42111 11.2853C2.28428 11.5019 2.21587 11.6102 2.17774 11.7765C2.1491 11.9014 2.14909 12.0984 2.17771 12.2234C2.21583 12.3897 2.28393 12.4975 2.42013 12.7132C3.54554 14.4952 6.89541 19 12.0004 19C14.0588 19 15.8319 18.2676 17.2888 17.2766M3.00042 3L21.0004 21M9.8791 9.87868C9.3362 10.4216 9.00042 11.1716 9.00042 12C9.00042 13.6569 10.3436 15 12.0004 15C12.8288 15 13.5788 14.6642 14.1217 14.1213"
                                                stroke="#6B7280"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                    ) : (
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g clipPath="url(#clip0_1118_26716)">
                                                <path
                                                    d="M12 6.5C15.79 6.5 19.17 8.63 20.82 12C19.17 15.37 15.8 17.5 12 17.5C8.2 17.5 4.83 15.37 3.18 12C4.83 8.63 8.21 6.5 12 6.5ZM12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 9.5C13.38 9.5 14.5 10.62 14.5 12C14.5 13.38 13.38 14.5 12 14.5C10.62 14.5 9.5 13.38 9.5 12C9.5 10.62 10.62 9.5 12 9.5ZM12 7.5C9.52 7.5 7.5 9.52 7.5 12C7.5 14.48 9.52 16.5 12 16.5C14.48 16.5 16.5 14.48 16.5 12C16.5 9.52 14.48 7.5 12 7.5Z"
                                                    fill="#6B7280"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1118_26716">
                                                    <rect width="24" height="24" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    )}
                                </GhostIconButton>
                            }
                        />
                        <Button
                            css={{ width: '100%' }}
                            type="submit"
                            disabled={form.formState.isSubmitting}
                            isLoading={form.formState.isSubmitting}
                        >
                            Resetuj lozinku
                        </Button>
                    </>
                </Box>
            </Form>
        </FormProvider>
    )
}
