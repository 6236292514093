import { Base64 } from 'js-base64'

export enum StorageKey {
    'ACCESS_TOKEN' = 'ACCESS_TOKEN',
    'REFRESH_TOKEN' = 'REFRESH_TOKEN',
}

export class LocalStorage {
    save(key: string, value: any) {
        if (typeof window === 'undefined') return

        try {
            const encodedData = Base64.encode(JSON.stringify(value))
            localStorage.setItem(key, encodedData)
        } catch (error) {
            console.error('[ERROR] LocalStorage:', error)
            localStorage.removeItem(key)
        }
    }

    load(key: string) {
        if (typeof window === 'undefined') return

        try {
            const data = localStorage.getItem(key)
            if (!data) {
                return null
            }

            const baseData = Base64.decode(data)
            return JSON.parse(baseData)
        } catch (error) {
            console.error('[ERROR] LocalStorage:', error)
            localStorage.removeItem(key)
        }
    }

    remove(key: string) {
        if (typeof window === 'undefined') return

        try {
            localStorage.removeItem(key)
        } catch (error) {
            console.error('[ERROR] LocalStorage:', error)
        }
    }

    clear() {
        if (typeof window === 'undefined') return

        localStorage.clear()
    }
}

export const storage = new LocalStorage()
