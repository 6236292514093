import { Button } from 'ui/Button'
import { Box } from 'ui/Common'

const OnboardingNavigation = ({
    onStepComplete,
    isLoading,
}: {
    onStepComplete: () => void
    isLoading?: boolean
}) => {
    return (
        <Box
            css={{
                backgroundColor: '#fff',
                width: '100%',
                py: '$1-25',
                overflow: 'hidden',
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
            }}
        >
            <Box
                css={{
                    'd': 'flex',
                    'maxWidth': '927px',
                    'ai': 'center',
                    'height': '$8',
                    'jc': 'flex-end',
                    'margin': '0 auto',
                    'px': '$1-25',
                    'gap': '$2',
                    '@maxSm': {},
                }}
            >
                <Button onClick={onStepComplete} isLoading={isLoading} disabled={isLoading}>
                    Odaberi
                </Button>
            </Box>
        </Box>
    )
}

export default OnboardingNavigation
