import * as Progress from '@radix-ui/react-progress'
import { ComponentProps, ReactNode } from 'react'

import { styled } from './stitches.config'

interface Props extends ComponentProps<typeof ProgressRoot> {
    progress: number
    children?: ReactNode
}

export const ProgressBar = ({ children, progress = 0, ...props }: Props) => {
    return (
        <ProgressRoot max={100} {...props}>
            <ProgressIndicator style={{ transform: `translateX(-${100 - progress}%)` }} />
            {children}
        </ProgressRoot>
    )
}

const ProgressRoot = styled(Progress.Root, {
    position: 'relative',
    overflow: 'hidden',
    background: 'rgba(83, 165, 81, 0.16)',
    height: '$1',
    width: '100%',

    // Fix overflow clipping in Safari
    // https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0
    transform: 'translateZ(0)',
})

const ProgressIndicator = styled(Progress.Indicator, {
    background: '#53A551',
    width: '100%',
    height: '100%',
    transition: 'transform 400ms cubic-bezier(0.65, 0, 0.35, 1)',
})
