import { Box, Text } from 'ui/Common'
import { DashboardPageLayout } from 'ui/DashboardPageLayout'

import { PlaceholderScreen } from './CropRotationPage'

export const CalculatorsPage = () => {
    return (
        <DashboardPageLayout>
            <Box
                css={{
                    d: 'flex',
                    gap: '$2',
                    jc: 'space-between',
                    ai: 'center',
                }}
            >
                <Box
                    css={{
                        d: 'flex',
                        fd: 'column',
                        gap: '$1',
                    }}
                >
                    <Text
                        css={{
                            color: '#2C3E50',
                            fontWeight: 600,
                            fontSize: '24px',
                            lineHeight: '28px',
                        }}
                    >
                        Kalkulatori
                    </Text>
                </Box>
            </Box>
            <PlaceholderScreen />
        </DashboardPageLayout>
    )
}
