import { z } from 'zod'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { ReactNode, useEffect, useState } from 'react'
import { t } from 'i18next'

import { useFarmQuery, useFinishActivityMutation } from 'hooks/api'

import { BaseButton, Button, GhostIconButton } from 'ui/Button'
import { Box, Form, Text } from 'ui/Common'
import { DialogBody, DialogClose, DialogTrigger, Dialog } from 'ui/Dialog'

import { FormDateInput } from './FormDateInput'
import { FormInput } from './FormInput'
import { FormSelect } from './FormSelect'
import { FormInputSelect } from './FormInputSelect'

export const FinishActivityDialog = ({
    children,
    fieldId,
    fieldSeasonActivityId,
}: {
    children: ReactNode
    fieldSeasonActivityId: number
    fieldId: number
}) => {
    const [open, setOpen] = useState(false)
    const { data } = useFarmQuery()
    const { mutateAsync } = useFinishActivityMutation()
    const finishActivitySchema = z.object({
        startDate: z.date(),
        endDate: z.date(),
        notes: z.string(),
        fuelConsumption: z.coerce.number({ invalid_type_error: t('validation:number.type') }),
        expenses: z.array(
            z.object({
                name: z.string(),
                quantity: z.coerce.number({ invalid_type_error: t('validation:number.type') }),
                type: z.string(),
                measureUnit: z.string(),
            }),
        ),
    })

    type finishActivityValues = z.infer<typeof finishActivitySchema>

    const form = useForm({
        resolver: zodResolver(finishActivitySchema),
        defaultValues: {
            startDate: new Date(),
            endDate: new Date(),
            notes: '',
            fuelConsumption: 0,
            expenses: [
                {
                    name: '',
                    type: '',
                    quantity: 0,
                    measureUnit: 'kg',
                },
            ],
        },
    })

    const { fields, append, remove } = useFieldArray({
        control: form.control,
        name: 'expenses',
    })
    const handleSubmit = async (values: finishActivityValues) => {
        try {
            const response = await mutateAsync({
                fieldId: fieldId,
                fieldSeasonActivityId: fieldSeasonActivityId,
                notes: values.notes,
                fuelConsumption: values.fuelConsumption,
                startedAt: new Date(new Date(values.startDate).setHours(10))
                    .toISOString()
                    .split('T')[0],
                endedAt: new Date(new Date(values.endDate).setHours(10))
                    .toISOString()
                    .split('T')[0],
                expenses: values.expenses,
            })
            setOpen(false)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        form.reset()
    }, [open])

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>{children}</DialogTrigger>
            <DialogBody title="Završi aktivnost">
                <FormProvider {...form}>
                    <Form
                        css={{
                            d: 'flex',
                            fd: 'column',
                            gap: '$3',
                        }}
                        onSubmit={form.handleSubmit(handleSubmit)}
                    >
                        <Box
                            css={{
                                d: 'flex',
                                ai: 'center',
                                flexWrap: 'wrap',
                                gap: '$3',
                                width: '100%',
                            }}
                        >
                            <FormDateInput
                                name="startDate"
                                label="Datum početka"
                                containerCSS={{ fg: 1 }}
                            />
                            <FormDateInput
                                name="endDate"
                                label="Datum kraja"
                                containerCSS={{ fg: 1 }}
                            />
                        </Box>
                        <FormInput
                            name="fuelConsumption"
                            label={`Utrošak goriva (l/${data?.areaMeasureUnit?.abbreviation})`}
                        />

                        <Box css={{ d: 'flex', fd: 'column', gap: '$1' }}>
                            <Text
                                css={{
                                    fontSize: 12,
                                    lineHeight: '20px',
                                    color: 'rgba(44, 62, 80, 0.64)',
                                    userSelect: 'none',
                                }}
                            >
                                Repromaterijali:
                            </Text>
                            <Box css={{ d: 'flex', fd: 'column', gap: '$1' }}>
                                {fields.map((item, sectionPosition) => {
                                    return (
                                        <Box
                                            key={item.id}
                                            css={{
                                                background: 'rgb(244 246 245)',
                                                p: '$1',
                                                br: '$1',
                                                position: 'relative',
                                                d: 'flex',
                                                fd: 'column',
                                                gap: '$1',
                                            }}
                                        >
                                            <FormSelect
                                                name={`expenses[${sectionPosition}].type`}
                                                label="Tip repromaterijala"
                                                items={[
                                                    {
                                                        label: 'Seme',
                                                        value: 'Seme',
                                                    },
                                                    {
                                                        label: 'Pesticidi',
                                                        value: 'Pesticidi',
                                                    },
                                                    {
                                                        label: 'Đubrivo',
                                                        value: 'Đubrivo',
                                                    },
                                                    {
                                                        label: 'Ostalo',
                                                        value: 'Ostalo',
                                                    },
                                                ]}
                                                placeholder="Odaberite tip"
                                            />
                                            <Box
                                                css={{
                                                    d: 'flex',
                                                    ai: 'flex-start',
                                                    jc: 'space-between',
                                                    gap: '$1',
                                                }}
                                            >
                                                <FormInput
                                                    name={`expenses[${sectionPosition}].name`}
                                                    label="Naziv repromaterijala"
                                                    css={{ fg: 1 }}
                                                />
                                                <FormInput
                                                    name={`expenses[${sectionPosition}].quantity`}
                                                    label="Količina repromaterijala"
                                                    css={{ fg: 1 }}
                                                    rightEl={
                                                        <FormInputSelect
                                                            name={`expenses[${sectionPosition}].measureUnit`}
                                                            css={{
                                                                border: 'none',
                                                                right: 0,
                                                                float: 'right',
                                                            }}
                                                            items={[
                                                                {
                                                                    label: `kg/${data?.areaMeasureUnit?.abbreviation}`,
                                                                    value: `kg`,
                                                                },
                                                                {
                                                                    label: `g/${data?.areaMeasureUnit?.abbreviation}`,
                                                                    value: `g`,
                                                                },
                                                                {
                                                                    label: `sj/${data?.areaMeasureUnit?.abbreviation}`,
                                                                    value: `sj`,
                                                                },
                                                                {
                                                                    label: `l/${data?.areaMeasureUnit?.abbreviation}`,
                                                                    value: `l`,
                                                                },
                                                                {
                                                                    label: `dl/${data?.areaMeasureUnit?.abbreviation}`,
                                                                    value: `dl`,
                                                                },
                                                                {
                                                                    label: `ml/${data?.areaMeasureUnit?.abbreviation}`,
                                                                    value: `ml`,
                                                                },
                                                                {
                                                                    label: `kom/${data?.areaMeasureUnit?.abbreviation}`,
                                                                    value: `kom`,
                                                                },
                                                            ]}
                                                        />
                                                    }
                                                />
                                            </Box>

                                            {fields.length > 1 && (
                                                <GhostIconButton
                                                    onClick={() => remove(sectionPosition)}
                                                    css={{
                                                        position: 'absolute',
                                                        top: 8,
                                                        right: 8,
                                                    }}
                                                >
                                                    <svg
                                                        width="18"
                                                        height="18"
                                                        viewBox="0 0 18 18"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M3 4.95H4.33333M4.33333 4.95H15M4.33333 4.95V14.4C4.33333 14.758 4.47381 15.1014 4.72386 15.3546C4.97391 15.6078 5.31304 15.75 5.66667 15.75H12.3333C12.687 15.75 13.0261 15.6078 13.2761 15.3546C13.5262 15.1014 13.6667 14.758 13.6667 14.4V4.95H4.33333ZM6.33333 4.95V3.6C6.33333 3.24196 6.47381 2.89858 6.72386 2.64541C6.97391 2.39223 7.31304 2.25 7.66667 2.25H10.3333C10.687 2.25 11.0261 2.39223 11.2761 2.64541C11.5262 2.89858 11.6667 3.24196 11.6667 3.6V4.95M7.66667 8.325V12.375M10.3333 8.325V12.375"
                                                            stroke="#2C3E50"
                                                            stroke-width="1.5"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                    </svg>
                                                </GhostIconButton>
                                            )}
                                        </Box>
                                    )
                                })}
                            </Box>
                            <BaseButton
                                onClick={() =>
                                    append({ name: '', quantity: 0, type: '', measureUnit: 'kg' })
                                }
                                type="button"
                                css={{
                                    br: '$1',
                                    color: '#2C3E50',
                                    border: '1px solid #2C3E50',
                                    px: '$2-5',
                                    py: '$1-25',
                                    background: 'transparent',
                                    fontSize: '14px',
                                    fontWeight: 500,
                                }}
                            >
                                + Dodaj još repromaterijala
                            </BaseButton>
                        </Box>

                        <FormInput name="notes" label="Napomena" />

                        <Box
                            css={{
                                d: 'flex',
                                ai: 'center',
                                jc: 'flex-end',
                                gap: '$2',
                            }}
                        >
                            <DialogClose asChild>
                                <Button variant="secondary" type="button">
                                    Izađi
                                </Button>
                            </DialogClose>
                            <Button
                                type="submit"
                                // isLoading={isLoading}
                                // disabled={
                                //     isLoading || !form.formState.isDirty || !form.formState.isValid
                                // }
                            >
                                Završi
                            </Button>
                        </Box>
                    </Form>
                </FormProvider>
            </DialogBody>
        </Dialog>
    )
}
