import {
    useAgroActivitiesQuery,
    useAgroCultureActivitiesQuery,
    useSelectFarmAgroCultureActivityMutation,
    useUpdateAgroCultureActivitiesMutation,
    useUnselectFarmAgroCultureActivityMutation,
} from 'hooks/api'

import { Box, Image, Text } from 'ui/Common'
import { BaseButton, Button, GhostIconButton } from 'ui/Button'
import { CSS } from 'ui/stitches.config'

import AddIcon from 'assets/icons/add.svg'
import AddGreenIcon from 'assets/icons/add_green.svg'
import CloseIcon from 'assets/icons/close.svg'
import IconArrowRight from 'assets/icons/arrow_right_gray.svg'
import EditIcon from 'assets/icons/edit.svg'

import { AddActivityDialog } from './AddActivityDialog'
import { EditActivityDialog } from './EditActivityDialog'
import { AddActivitiesToCultureDialog } from './AddActivitiesToCultureDialog'

interface EditActivitiesProps {
    cultureId: number
}

interface AddActivitiesProps {
    cultureId: number
    containerCSS?: CSS
}

export const AddActivities = ({ cultureId, containerCSS }: AddActivitiesProps) => {
    const { data: activities, isFetching: isLoadingActivities } = useAgroActivitiesQuery()

    const { mutateAsync: selectFarmAgroCultureActivity } =
        useSelectFarmAgroCultureActivityMutation()

    if (isLoadingActivities) return <p>Loading...</p>

    return (
        <Box
            css={{
                d: 'flex',
                fg: 2,
                fd: 'column',
                p: '$1-5',
                gap: '$3',
                ...containerCSS,
            }}
        >
            {activities?.map((activity) => (
                <Box
                    css={{
                        d: 'flex',
                        fd: 'column',
                        gap: '$1',
                    }}
                    key={activity.id}
                >
                    <Text
                        css={{
                            fontSize: '16px',
                            color: 'rgba(44, 62, 80, 0.64)',
                        }}
                    >
                        {activity.name}
                    </Text>
                    <Box
                        css={{
                            d: 'flex',
                            flexWrap: 'wrap',
                            gap: '$1-25',
                        }}
                    >
                        {activity.id === 0 && (
                            <AddActivityDialog>
                                <BaseButton
                                    css={{
                                        d: 'flex',
                                        ai: 'center',
                                        pl: '$1',
                                        background: '#fff',
                                        pr: '$1-5',
                                        py: '$1-25',
                                        gap: '$0-5',
                                        br: '$0-5',
                                        border: '1px solid #53A551',
                                    }}
                                >
                                    <Image src={AddGreenIcon} />
                                    <Text
                                        css={{
                                            fontSize: '14px',
                                            color: '#53A551',
                                        }}
                                    >
                                        Dodaj
                                    </Text>
                                </BaseButton>
                            </AddActivityDialog>
                        )}
                        {activity.items.map((item, index) => (
                            <BaseButton
                                css={{
                                    d: 'flex',
                                    ai: 'center',
                                    background: '#FFFFFF',
                                    pl: '$1',
                                    pr: '$1-5',
                                    py: '$1-25',
                                    gap: '$0-5',
                                    br: '$0-5',
                                    border: '1px solid #D1D5DB',
                                }}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    selectFarmAgroCultureActivity({
                                        agroActivityId: item.id,
                                        agroCultureId: cultureId,
                                    })
                                }}
                                key={item.name + item.id + index}
                            >
                                <Image src={AddIcon} />
                                <Text
                                    css={{
                                        fontSize: '14px',
                                        color: '#2C3E50',
                                    }}
                                >
                                    {item.name}
                                </Text>
                                {activity.id === 0 && (
                                    <EditActivityDialog activity={item}>
                                        <Image src={EditIcon} />
                                    </EditActivityDialog>
                                )}
                            </BaseButton>
                        ))}
                    </Box>
                </Box>
            ))}
        </Box>
    )
}

const EditActivities = ({ cultureId }: EditActivitiesProps) => {
    const {
        mutateAsync: updateAgroCultureActivitiesMutation,
        isLoading: isUpdatingCultureActivities,
    } = useUpdateAgroCultureActivitiesMutation()

    const { data: cultureActivities, isRefetching: isLoadingCultureActivities } =
        useAgroCultureActivitiesQuery(cultureId)

    const isMovingDisabled = isUpdatingCultureActivities || isLoadingCultureActivities

    const { mutateAsync } = useUnselectFarmAgroCultureActivityMutation()

    return (
        <Box
            css={{
                'border': '1px solid #E4E5E7',
                'background': '#ffffff',
                'br': '$1',
                'd': 'flex',
                'p': '$2',
                'gap': '$2',
                '@maxMd': {
                    flexDirection: 'column',
                },
            }}
        >
            <AddActivities
                cultureId={cultureId}
                containerCSS={{
                    '@maxMd': {
                        display: 'none',
                    },
                }}
            />
            <AddActivitiesToCultureDialog cultureId={cultureId}>
                <Button
                    css={{
                        '@minMd': {
                            display: 'none',
                        },
                    }}
                    variant="secondary"
                >
                    Dodaj aktivnosti
                </Button>
            </AddActivitiesToCultureDialog>
            <Box
                css={{
                    d: 'flex',
                    fd: 'column',
                    p: '$1-5',
                    fg: 1,
                    background: '#F7F8FC',
                    minWidth: '274px',
                    gap: '$1-25',
                }}
            >
                {cultureActivities?.map((activity, index) => {
                    return (
                        <Box
                            css={{
                                d: 'flex',
                                ai: 'center',
                                p: '$1-5',
                                background: '#ffffff',
                                gap: '$1',
                                br: '$0-5',
                            }}
                            key={`${activity.id}-${index}`}
                        >
                            <Box
                                css={{
                                    background: '#F7F7F7',
                                    br: '$0-5',
                                    fontSize: 14,
                                    size: 24,
                                    minWidth: 24,
                                    minHeight: 24,
                                    d: 'flex',
                                    jc: 'center',
                                    ai: 'center',
                                }}
                            >
                                {index + 1}.
                            </Box>
                            <Text
                                css={{
                                    fg: 1,
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    maxWidth: 120,
                                }}
                            >
                                {activity?.name}
                            </Text>
                            <GhostIconButton
                                onClick={() => {
                                    updateAgroCultureActivitiesMutation({
                                        id: cultureId,
                                        agroActivityIds: swapElementsDown(
                                            cultureActivities,
                                            index,
                                        ).map((item) => item.agroActivityId),
                                    })
                                }}
                                disabled={index === 0 || isMovingDisabled}
                            >
                                <Image src={IconArrowRight} css={{ rotate: '270deg' }} />
                            </GhostIconButton>
                            <GhostIconButton
                                onClick={() => {
                                    updateAgroCultureActivitiesMutation({
                                        id: cultureId,
                                        agroActivityIds: swapElementsUp(
                                            cultureActivities,
                                            index,
                                        ).map((item) => item.agroActivityId),
                                    })
                                }}
                                disabled={
                                    index === cultureActivities.length - 1 || isMovingDisabled
                                }
                            >
                                <Image src={IconArrowRight} css={{ rotate: '90deg' }} />
                            </GhostIconButton>
                            <GhostIconButton
                                onClick={() => {
                                    mutateAsync({
                                        agroActivityId: activity.agroActivityId,
                                        agroCultureId: activity.agroCultureId,
                                        agroCultureActivityId: activity.id,
                                    })
                                }}
                            >
                                <Image src={CloseIcon} />
                            </GhostIconButton>
                        </Box>
                    )
                })}
            </Box>
        </Box>
    )
}

export default EditActivities

function swapElementsUp(arr: any[], index: number) {
    if (index >= 0 && index < arr.length - 1) {
        const temp = arr[index]
        arr[index] = arr[index + 1]
        arr[index + 1] = temp
    }
    return arr
}

function swapElementsDown(arr: any[], index: number) {
    if (index > 0 && index < arr.length) {
        const temp = arr[index]
        arr[index] = arr[index - 1]
        arr[index - 1] = temp
    }
    return arr
}
