import { styled } from './stitches.config'

export const Input = styled('input', {
    'backgroundColor': '#FFFFFF',
    'color': '#6D717F',
    'padding': '$1-25',
    'fontSize': '14px',
    'br': '$1',
    'border': '1px solid #E4E5E7',
    '&:disabled': {
        backgroundColor: '#F9FAFB',
        border: '1px solid #F9FAFB',
    },
})

export const BaseInput = styled('input', {
    'backgroundColor': 'transparent',
    'color': '#6D717F',
    'fontSize': '14px',
    '&:disabled': {
        backgroundColor: '#F9FAFB',
        border: '1px solid #F9FAFB',
    },
})
