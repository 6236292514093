import { FullViewLoading } from 'components/FullscreenLoading'

import { useFarmQuery, useGetFarmDashboardData } from 'hooks/api'

import { Box, Image, Link, Text } from 'ui/Common'
import { DashboardPageLayout } from 'ui/DashboardPageLayout'
import { Button } from 'ui/Button'

import MapImage from 'assets/mape.png'
import DashboardFieldsAreaImage from 'assets/icons/dashboard_fields_area.png'
import DashboardFieldsAverageImage from 'assets/icons/dashboard_fields_average.png'
import DashboardFieldsCountImage from 'assets/icons/dashboard_fields_count.svg'

export const DashboardPage = () => {
    const { data, isLoading } = useGetFarmDashboardData()
    const { data: farmData } = useFarmQuery()

    return (
        <DashboardPageLayout>
            <Box
                css={{
                    d: 'flex',
                    gap: '$2',
                    jc: 'space-between',
                    ai: 'center',
                }}
            >
                <Box
                    css={{
                        d: 'flex',
                        fd: 'column',
                        gap: '$1',
                    }}
                >
                    <Text
                        css={{
                            color: '#2C3E50',
                            fontWeight: 600,
                            fontSize: '24px',
                            lineHeight: '28px',
                        }}
                    >
                        👋🏻 Dobrodošli
                    </Text>
                </Box>
            </Box>
            {isLoading ? (
                <FullViewLoading />
            ) : (
                <Box
                    css={{
                        'd': 'grid',
                        'gap': '$2',
                        'gridTemplateColumns': '1fr 1fr 1fr',
                        '@maxXl': {
                            gridTemplateColumns: '1fr 1fr',
                        },
                        '@maxMd': {
                            gridTemplateColumns: '1fr',
                        },
                    }}
                >
                    <Box
                        css={{
                            p: '$3',
                            gap: '$2',
                            d: 'flex',
                            fd: 'column',
                            background: '#fff',
                            br: '$1',
                        }}
                    >
                        <Box
                            css={{
                                d: 'flex',
                                ai: 'center',
                                gap: '$1',
                            }}
                        >
                            <svg
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect
                                    width="32"
                                    height="32"
                                    rx="4"
                                    fill="#53A551"
                                    fillOpacity="0.08"
                                />
                                <path
                                    d="M15 23.9V8.1C15 6.6 14.36 6 12.77 6H8.73C7.14 6 6.5 6.6 6.5 8.1V23.9C6.5 25.4 7.14 26 8.73 26H12.77C14.36 26 15 25.4 15 23.9Z"
                                    fill="#53A551"
                                />
                                <path
                                    d="M25.5 14.9V8.1C25.5 6.6 24.86 6 23.27 6H19.23C17.64 6 17 6.6 17 8.1V14.9C17 16.4 17.64 17 19.23 17H23.27C24.86 17 25.5 16.4 25.5 14.9Z"
                                    fill="#53A551"
                                />
                                <path
                                    d="M25.5 23.9V21.1C25.5 19.6 24.86 19 23.27 19H19.23C17.64 19 17 19.6 17 21.1V23.9C17 25.4 17.64 26 19.23 26H23.27C24.86 26 25.5 25.4 25.5 23.9Z"
                                    fill="#53A551"
                                />
                            </svg>
                            <Text
                                css={{
                                    fontWeight: 600,
                                    fontSize: '24px',
                                }}
                            >
                                Njive
                            </Text>
                        </Box>
                        {data?.fields?.totalArea && data?.fields?.totalArea !== 0 ? (
                            <>
                                <Box
                                    css={{
                                        br: '$1',
                                        p: '$2',
                                        border: '1px solid #D1D5DB',
                                        d: 'flex',
                                        fd: 'column',
                                        gap: '$1-25',
                                    }}
                                >
                                    <Box css={{ d: 'flex', ai: 'center', gap: '$1' }}>
                                        <Box
                                            css={{
                                                p: '$0-5',
                                                background: 'rgba(83, 165, 81, 0.08)',
                                                br: '$0-5',
                                                size: '24px',
                                            }}
                                        >
                                            <Image
                                                src={DashboardFieldsAreaImage}
                                                css={{
                                                    size: '16px',
                                                }}
                                            />
                                        </Box>
                                        <Text
                                            css={{
                                                fontWeight: 600,
                                                fontSize: '24px',
                                            }}
                                        >
                                            {data?.fields?.totalArea.toFixed(0)}{' '}
                                            <Text
                                                as="span"
                                                css={{
                                                    'fontWeight': 600,
                                                    'fontSize': '24px',
                                                    '@maxSm': {
                                                        fontSize: '16px',
                                                    },
                                                }}
                                            >
                                                {farmData?.areaMeasureUnit?.abbreviation}
                                            </Text>
                                        </Text>
                                    </Box>
                                    <Text
                                        css={{
                                            fontSize: '12px',
                                            color: 'rgba(0,0,0,0.64)',
                                        }}
                                    >
                                        Ukupna površina
                                    </Text>
                                </Box>

                                <Box
                                    css={{
                                        'd': 'flex',
                                        'gap': '$3',
                                        '@maxSm': {
                                            gap: '$2',
                                        },
                                    }}
                                >
                                    <Box
                                        css={{
                                            br: '$1',
                                            p: '$2',
                                            border: '1px solid #D1D5DB',
                                            d: 'flex',
                                            fd: 'column',
                                            gap: '$1-25',
                                            fg: 1,
                                        }}
                                    >
                                        <Box css={{ d: 'flex', ai: 'center', gap: '$1' }}>
                                            <Box
                                                css={{
                                                    p: '$0-5',
                                                    background: 'rgba(83, 165, 81, 0.08)',
                                                    br: '$0-5',
                                                    size: '24px',
                                                }}
                                            >
                                                <Image
                                                    src={DashboardFieldsCountImage}
                                                    css={{
                                                        size: '16px',
                                                    }}
                                                />
                                            </Box>
                                            <Text
                                                css={{
                                                    fontWeight: 600,
                                                    fontSize: '24px',
                                                }}
                                            >
                                                {data?.fields?.count}
                                            </Text>
                                        </Box>
                                        <Text
                                            css={{
                                                fontSize: '12px',
                                                color: 'rgba(0,0,0,0.64)',
                                            }}
                                        >
                                            Ukupan broj njiva
                                        </Text>
                                    </Box>
                                    <Box
                                        css={{
                                            br: '$1',
                                            p: '$2',
                                            border: '1px solid #D1D5DB',
                                            d: 'flex',
                                            fd: 'column',
                                            gap: '$1-25',
                                            fg: 1,
                                        }}
                                    >
                                        <Box css={{ d: 'flex', ai: 'center', gap: '$1' }}>
                                            <Box
                                                css={{
                                                    p: '$0-5',
                                                    background: 'rgba(83, 165, 81, 0.08)',
                                                    br: '$0-5',
                                                    size: '24px',
                                                }}
                                            >
                                                <Image
                                                    src={DashboardFieldsAverageImage}
                                                    css={{
                                                        size: '16px',
                                                    }}
                                                />
                                            </Box>
                                            <Text
                                                css={{
                                                    fontWeight: 600,
                                                    fontSize: '24px',
                                                }}
                                            >
                                                {data?.fields?.averageArea.toFixed(0)}{' '}
                                                <Text
                                                    as="span"
                                                    css={{
                                                        'fontWeight': 600,
                                                        'fontSize': '24px',
                                                        '@maxSm': {
                                                            fontSize: '16px',
                                                        },
                                                    }}
                                                >
                                                    {farmData?.areaMeasureUnit?.abbreviation}
                                                </Text>
                                            </Text>
                                        </Box>
                                        <Text
                                            css={{
                                                fontSize: '12px',
                                                color: 'rgba(0,0,0,0.64)',
                                            }}
                                        >
                                            Prosečna površina
                                        </Text>
                                    </Box>
                                </Box>
                                <Box
                                    css={{
                                        d: 'flex',
                                        ai: 'center',
                                        jc: 'center',
                                        gap: '$0-5',
                                    }}
                                >
                                    <Link
                                        to="/kontrolna-tabla/njive"
                                        css={{
                                            color: '#53A551',
                                            fontWeight: 600,
                                            fontSize: '16px',
                                        }}
                                    >
                                        Pogledaj sve
                                    </Link>
                                    <svg
                                        width="25"
                                        height="24"
                                        viewBox="0 0 25 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g clipPath="url(#clip0_1616_29830)">
                                            <path
                                                d="M12.1667 4L10.7567 5.41L16.3367 11H4.16675V13H16.3367L10.7567 18.59L12.1667 20L20.1667 12L12.1667 4Z"
                                                fill="#53A551"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1616_29830">
                                                <rect
                                                    width="24"
                                                    height="24"
                                                    fill="white"
                                                    transform="translate(0.166748)"
                                                />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </Box>
                            </>
                        ) : (
                            <>
                                <Box
                                    css={{
                                        d: 'flex',
                                        fd: 'column',
                                        gap: '$1-25',
                                        textAlign: 'center',
                                    }}
                                >
                                    <Text
                                        css={{
                                            fontSize: '24px',
                                            fontWeight: 600,
                                            color: '#2C3E50',
                                        }}
                                    >
                                        Još uvek niste dodali svoje njive.
                                    </Text>
                                    <Text
                                        css={{
                                            fontSize: '14px',
                                            color: 'rgba(0,0,0,0.64)',
                                        }}
                                    >
                                        Dodajte ih klikom na dugme ispod.
                                    </Text>
                                </Box>
                                <Box
                                    css={{
                                        d: 'flex',
                                        jc: 'center',
                                    }}
                                >
                                    <Link to="/kontrolna-tabla/njive">
                                        <Button variant="cta">Dodaj njivu</Button>
                                    </Link>
                                </Box>
                            </>
                        )}
                    </Box>
                    <Box
                        css={{
                            p: '$3',
                            gap: '$2',
                            d: 'flex',
                            fd: 'column',
                            background: '#fff',
                            br: '$1',
                        }}
                    >
                        <Box
                            css={{
                                d: 'flex',
                                ai: 'center',
                                gap: '$1',
                            }}
                        >
                            <svg
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect
                                    width="32"
                                    height="32"
                                    rx="4"
                                    fill="#53A551"
                                    fillOpacity="0.08"
                                />
                                <path
                                    d="M15 23.9V8.1C15 6.6 14.36 6 12.77 6H8.73C7.14 6 6.5 6.6 6.5 8.1V23.9C6.5 25.4 7.14 26 8.73 26H12.77C14.36 26 15 25.4 15 23.9Z"
                                    fill="#53A551"
                                />
                                <path
                                    d="M25.5 14.9V8.1C25.5 6.6 24.86 6 23.27 6H19.23C17.64 6 17 6.6 17 8.1V14.9C17 16.4 17.64 17 19.23 17H23.27C24.86 17 25.5 16.4 25.5 14.9Z"
                                    fill="#53A551"
                                />
                                <path
                                    d="M25.5 23.9V21.1C25.5 19.6 24.86 19 23.27 19H19.23C17.64 19 17 19.6 17 21.1V23.9C17 25.4 17.64 26 19.23 26H23.27C24.86 26 25.5 25.4 25.5 23.9Z"
                                    fill="#53A551"
                                />
                            </svg>
                            <Text
                                css={{
                                    fontWeight: 600,
                                    fontSize: '24px',
                                }}
                            >
                                Kulture
                            </Text>
                        </Box>
                        {data?.agroCultures && data?.agroCultures.length !== 0 ? (
                            <>
                                {data?.agroCultures?.slice(0, 3).map((ac) => (
                                    <Box
                                        key={ac.id}
                                        css={{
                                            br: '$1',
                                            p: '$2',
                                            border: '1px solid #D1D5DB',
                                            d: 'flex',
                                            ai: 'center',
                                            gap: '$1',
                                        }}
                                    >
                                        <Box
                                            css={{
                                                background: 'rgba(83, 165, 81, 0.08)',
                                                p: '$0-5',
                                                br: '$0-5',
                                            }}
                                        >
                                            <Image
                                                src={ac.iconPath}
                                                css={{
                                                    size: 20,
                                                }}
                                            />
                                        </Box>
                                        <Text
                                            css={{
                                                fontWeight: 500,
                                                color: '#2C3E50',
                                            }}
                                        >
                                            {ac.name}
                                        </Text>
                                    </Box>
                                ))}

                                <Box
                                    css={{
                                        d: 'flex',
                                        ai: 'center',
                                        jc: 'center',
                                        gap: '$0-5',
                                    }}
                                >
                                    <Link
                                        to="/kontrolna-tabla/podaci/kulture"
                                        css={{
                                            color: '#53A551',
                                            fontWeight: 600,
                                            fontSize: '16px',
                                        }}
                                    >
                                        Pogledaj sve
                                    </Link>
                                    <svg
                                        width="25"
                                        height="24"
                                        viewBox="0 0 25 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g clipPath="url(#clip0_1616_29830)">
                                            <path
                                                d="M12.1667 4L10.7567 5.41L16.3367 11H4.16675V13H16.3367L10.7567 18.59L12.1667 20L20.1667 12L12.1667 4Z"
                                                fill="#53A551"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1616_29830">
                                                <rect
                                                    width="24"
                                                    height="24"
                                                    fill="white"
                                                    transform="translate(0.166748)"
                                                />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </Box>
                            </>
                        ) : (
                            <>
                                <Box
                                    css={{
                                        d: 'flex',
                                        fd: 'column',
                                        gap: '$1-25',
                                        textAlign: 'center',
                                    }}
                                >
                                    <Text
                                        css={{
                                            fontSize: '24px',
                                            fontWeight: 600,
                                            color: '#2C3E50',
                                        }}
                                    >
                                        Još uvek niste dodali svoje kulture.
                                    </Text>
                                    <Text
                                        css={{
                                            fontSize: '14px',
                                            color: 'rgba(0,0,0,0.64)',
                                        }}
                                    >
                                        Dodajte ih klikom na dugme ispod.
                                    </Text>
                                </Box>
                                <Box
                                    css={{
                                        d: 'flex',
                                        jc: 'center',
                                    }}
                                >
                                    <Link to="/kontrolna-tabla/podaci/kulture/izmeni">
                                        <Button variant="cta">Dodaj kulturu</Button>
                                    </Link>
                                </Box>
                            </>
                        )}
                    </Box>
                    <Box
                        css={{
                            p: '$3',
                            gap: '$2',
                            d: 'flex',
                            fd: 'column',
                            background: '#fff',
                            br: '$1',
                        }}
                    >
                        <Box
                            css={{
                                d: 'flex',
                                ai: 'center',
                                gap: '$1',
                            }}
                        >
                            <Box
                                css={{
                                    size: 32,
                                    background: 'rgba(83, 165, 81, 0.08)',
                                    br: '$0-5',
                                    d: 'flex',
                                    ai: 'center',
                                    jc: 'center',
                                }}
                            >
                                <svg
                                    width="33"
                                    height="32"
                                    viewBox="0 0 33 32"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g clipPath="url(#clip0_1616_29879)">
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M7.33301 26.6665H25.9997V29.3332H7.33301V26.6665ZM16.6663 9.33317C15.1997 9.33317 13.9997 10.5332 13.9997 11.9998C13.9997 13.4665 15.1997 14.6665 16.6663 14.6665C17.3736 14.6665 18.0519 14.3856 18.552 13.8855C19.0521 13.3854 19.333 12.7071 19.333 11.9998C19.333 11.2926 19.0521 10.6143 18.552 10.1142C18.0519 9.61412 17.3736 9.33317 16.6663 9.33317V9.33317ZM16.6663 2.6665C21.0263 2.6665 25.9997 5.9465 25.9997 12.1998C25.9997 16.3598 22.893 20.7465 16.6663 25.3332C10.4397 20.7465 7.33301 16.3598 7.33301 12.1998C7.33301 5.9465 12.3063 2.6665 16.6663 2.6665Z"
                                            fill="#53A551"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1616_29879">
                                            <rect
                                                width="32"
                                                height="32"
                                                fill="white"
                                                transform="translate(0.666504)"
                                            />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </Box>
                            <Text
                                css={{
                                    fontWeight: 600,
                                    fontSize: '24px',
                                }}
                            >
                                Mapa
                            </Text>
                            <Box css={{ d: 'flex', fg: 1, jc: 'flex-end' }}>
                                <Box
                                    css={{
                                        d: 'flex',
                                        background: 'rgba(83, 165, 81, 0.12)',
                                        color: 'rgba(83, 165, 81, 0.8)',
                                        px: '$1-75',
                                        py: '$0-5',
                                        br: '$2',
                                        fontSize: '12px',
                                        fontWeight: 500,
                                    }}
                                >
                                    USKORO
                                </Box>
                            </Box>
                        </Box>
                        <Text
                            css={{
                                fontWeight: 400,
                                fontSize: '14px',
                                color: 'rgba(0,0,0,.64)',
                            }}
                        >
                            Vizuelni prikaz svih vaših njiva na mapi uz dodavanje putem jedinstvenog
                            broja iz katastra nepokretnosti.
                        </Text>
                        <Image src={MapImage} />
                    </Box>
                </Box>
            )}
        </DashboardPageLayout>
    )
}
