import * as Label from '@radix-ui/react-label'
import { Controller, useFormContext } from 'react-hook-form'
import { ReactNode } from 'react'

import { Box, Text } from 'ui/Common'
import { CSS, styled } from 'ui/stitches.config'
import Select from 'ui/Select'

export interface FormInputProps extends React.ComponentProps<typeof Select> {
    name: string
    label?: string
    labelHelpEl?: ReactNode
    containerCSS?: CSS
    labelContainerCSS?: CSS
}

export const FormSelect = ({
    name,
    label,
    labelHelpEl,
    containerCSS = {},
    labelContainerCSS = {},
    ...selectProps
}: FormInputProps) => {
    const {
        control,
        formState: { errors },
    } = useFormContext()

    const error = errors[name]?.message?.toString()

    const borderColor = error ? '#EF4444' : 'transparent'

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => {
                return (
                    <Box
                        css={{
                            d: 'flex',
                            fd: 'column',
                            gap: '$1',
                            width: '100%',
                            ...containerCSS,
                        }}
                    >
                        {(!!label || !!labelHelpEl) && (
                            <Box
                                css={{
                                    d: 'flex',
                                    jc: 'space-between',
                                    ...labelContainerCSS,
                                }}
                            >
                                {!!label && (
                                    <LabelRoot htmlFor={name} css={{ fontSize: 12 }}>
                                        {label}
                                        {/* {!!inputProps.required && '*'} */}
                                    </LabelRoot>
                                )}
                                {!!labelHelpEl && labelHelpEl}
                            </Box>
                        )}
                        <Select
                            onValueChange={onChange}
                            value={value}
                            {...selectProps}
                            css={{
                                width: '100%',
                                ...selectProps.css,
                                border: error ? `1px solid ${borderColor}` : undefined,
                            }}
                        />

                        {!!error && (
                            <Text
                                css={{
                                    color: '#EF4444',
                                    fontSize: 14,
                                    marginTop: '$0-25',
                                }}
                            >
                                {error}
                            </Text>
                        )}
                    </Box>
                )
            }}
        />
    )
}

const LabelRoot = styled(Label.Root, {
    fontSize: 14,
    lineHeight: '20px',
    color: 'rgba(44, 62, 80, 0.64)',
    userSelect: 'none',
})
