import { httpClient } from 'library/http'

import type { CreateAgroCultureRequest, GetAllAgroCulturesResponse } from './AgroCulturesTypes'

export class AgroCulturesService {
    private _agroCulturesUrl = '/agro-cultures'

    public async getAllAgroCultures() {
        const { data } = await httpClient.get<GetAllAgroCulturesResponse>(this._agroCulturesUrl)
        return data.sort((a, b) => (!!a.iconPath === !!b.iconPath ? 0 : a?.iconPath ? -1 : 1))
    }

    public async createAgroCulture(request: CreateAgroCultureRequest) {
        return httpClient.post(this._agroCulturesUrl, request)
    }
    public async deleteAgroCulture({ cultureId }: { cultureId: number }) {
        return httpClient.delete(`${this._agroCulturesUrl}/${cultureId}`)
    }
}

export const agroCulturesService = new AgroCulturesService()
