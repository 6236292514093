import { ComponentProps } from 'react'

import { styled } from './stitches.config'

export interface ButtonProps extends ComponentProps<typeof StyledButton> {
    isLoading?: boolean
    as?: React.ElementType
}

export const Button = ({ isLoading, children, ...buttonProps }: ButtonProps) => {
    return <StyledButton {...buttonProps}>{isLoading ? 'Učitavanje...' : children}</StyledButton>
}

export const StyledButton = styled('button', {
    'boxSizing': 'border-box',
    'padding': '$1-25',
    'bc': '#149045',
    'color': '#fff',
    'br': '$0-5',
    'd': 'flex',
    'ai': 'center',
    'jc': 'center',
    'background': '#53A551',
    'fontFamily': '$inter',
    'fontWeight': 500,
    '&:hover': {
        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
        filter: 'brightness(105%)',
    },
    '&:focus-visible': {
        outline: ' -webkit-focus-ring-color auto 1px',
    },
    'variants': {
        variant: {
            secondary: {
                outline: '1px solid #D1D5DB',
                background: '#fff',
                color: '#2C3E50',
            },
            cta: {
                'px': '$3',
                'py': '$1-5',
                'fontWeight': 600,
                'fontSize': '15px',
                'lineHeight': '26px',
                'bc': '#53A551',
                'br': '$1-25',
                '@maxMd': {
                    fontSize: '12px',
                    px: '$1-5',
                    py: '$1',
                },
            },
        },
        disabled: {
            true: {
                'backgroundColor': '#EEF0F1',
                'color': '#BFC3C6',
                'cursor': 'not-allowed',
                '&:hover': {
                    boxShadow: 'none',
                    backgroundColor: '#EEF0F1',
                    filter: 'brightness(100%)',
                },
            },
        },
    },
})

export const BaseButton = styled('button', {
    'background': 'transparent',
    '&:focus-visible': {
        outline: ' -webkit-focus-ring-color auto 1px',
    },
})

export const GhostIconButton = styled('button', {
    'all': 'unset',
    'fontFamily': 'inherit',
    'borderRadius': '100%',
    'display': 'inline-flex',
    'alignItems': 'center',
    'justifyContent': 'center',
    'cursor': 'pointer',
    '&:focus-visible': {
        outline: ' -webkit-focus-ring-color auto 1px',
    },
    '&:disabled': {
        cursor: 'not-allowed',
    },
})
