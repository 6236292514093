import * as Checkbox from '@radix-ui/react-checkbox'
import { ComponentProps } from 'react'

import IconCheckmark from 'assets/icons/checkmark.svg'

import { Box, Image, Label } from './Common'
import { CSS, styled } from './stitches.config'

interface CheckboxCardProps extends ComponentProps<typeof CheckboxRoot> {
    name: string
    label: string
    icon?: React.ReactNode
    additionalEl?: React.ReactNode
    checkBoxContainerCSS?: CSS
}

export const CheckboxCard = ({
    name,
    icon,
    label,
    checkBoxContainerCSS,
    additionalEl,
    ...checkboxProps
}: CheckboxCardProps) => {
    return (
        <CheckboxContainer checked={!!checkboxProps.checked} css={checkBoxContainerCSS}>
            <CheckboxRoot id={name} {...checkboxProps}>
                <Box css={{ d: 'flex', ai: 'flex-start', jc: 'space-between', width: '100%' }}>
                    <Box
                        css={{
                            size: '$3-5',
                            d: 'flex',
                            ai: 'center',
                            jc: 'center',
                        }}
                    >
                        {icon ?? <div />}
                    </Box>
                    <Box css={{ d: 'flex', ai: 'center' }}>
                        {!!additionalEl && additionalEl}
                        <CheckboxIndicatorHolder>
                            <CheckboxIndicator>
                                <Image src={IconCheckmark} />
                            </CheckboxIndicator>
                        </CheckboxIndicatorHolder>
                    </Box>
                </Box>

                <Label
                    css={{
                        cursor: 'pointer',
                        fontSize: '16px',
                        fontWeight: 500,
                        lineHeight: '19px',
                        textAlign: 'left',
                        color: '#2C3E50',
                        fontFamily: '$inter',
                        height: '19px',
                    }}
                    htmlFor={name}
                >
                    {label || ` `}
                </Label>
            </CheckboxRoot>
        </CheckboxContainer>
    )
}

const CheckboxRoot = styled(Checkbox.Root, {
    'd': 'flex',
    'fd': 'column',
    'ai': 'flex-start',
    'bc': '#ffffff',
    'gap': '$2',
    'p': '$2',
    'br': '$1',
    'width': '100%',
    '&:focus-visible': {
        outline: ' -webkit-focus-ring-color auto 1px',
    },
})

const CheckboxContainer = styled(Box, {
    'd': 'flex',
    'fd': 'column',
    'ai': 'flex-start',
    'bc': '#ffffff',
    'border': '1px solid #E4E5E7',

    'transition': 'box-shadow 0.2s ease-in-out, filter 0.3s ease-in-out',
    '&:hover': {
        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
        filter: 'brightness(105%)',
        border: '1px solid rgba(83, 165, 81, 0.3)',
    },
    'br': '$1',
    'variants': {
        checked: {
            true: {
                'border': '1px solid #53A551',
                '&:hover': {
                    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                    filter: 'brightness(105%)',
                    border: '1px solid #53A551',
                },
            },
        },
    },
})

const CheckboxIndicatorHolder = styled(Box, {
    d: 'flex',
    ai: 'center',
    jc: 'center',
    br: '$0-5',
    border: '1px solid #53A551',
    size: '24px',
})

const CheckboxIndicator = styled(Checkbox.Indicator, {
    color: '#53A551',
    bc: '#53A551',
    size: '100%',
    d: 'flex',
    ai: 'center',
    jc: 'center',
})
