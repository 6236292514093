import { AddCultureDialog } from 'components/AddCultureDialog'

import { useAgroCulturesQuery, useSelectFarmAgroCultureMutation } from 'hooks/api'

import { CheckboxCard } from 'ui/CheckboxCard'
import { Box, Image, Text } from 'ui/Common'
import { GhostIconButton } from 'ui/Button'

import AddIcon from 'assets/icons/add_square.svg'
import TrashIcon from 'assets/icons/trash.svg'
import EditIcon from 'assets/icons/edit.svg'
import PlaceholderIcon from 'assets/icons/placeholder.png'

import { EditCultureDialog } from './EditCultureDialog'
import { DeleteCultureDialog } from './DeleteCultureDialog'

export interface EditCulturesProps {}

const EditCultures = ({}: EditCulturesProps) => {
    const { data: agroCultures, isFetching } = useAgroCulturesQuery()
    const { mutateAsync } = useSelectFarmAgroCultureMutation()

    return (
        <>
            <Box
                css={{
                    'd': 'grid',
                    'gridTemplateColumns': 'repeat(3, minmax(200px, 1fr))',
                    'gap': '$2-5 $1-25',
                    '@maxSm': {
                        gridTemplateColumns: 'repeat(2, 1fr)',
                    },
                }}
            >
                {!!agroCultures &&
                    agroCultures.map((culture) => {
                        const cultureIcon = (
                            <Box
                                css={{
                                    size: 28,
                                    background: 'rgba(83, 165, 81, 0.08)',
                                    br: '$0-5',
                                    d: 'flex',
                                    ai: 'center',
                                    jc: 'center',
                                }}
                            >
                                {culture?.iconPath ? (
                                    <Image
                                        css={{
                                            size: 20,
                                        }}
                                        src={culture.iconPath}
                                    />
                                ) : (
                                    <Image
                                        src={PlaceholderIcon}
                                        css={{
                                            size: 20,
                                        }}
                                    />
                                )}
                            </Box>
                        )

                        const isCustom = culture?.iconPath === null
                        return (
                            <CheckboxCard
                                key={culture.id}
                                value={culture.id}
                                name={culture.name}
                                label={culture.name}
                                checked={culture.selected}
                                icon={cultureIcon}
                                onCheckedChange={(isChecked) => {
                                    mutateAsync({ id: culture.id, prevSelected: culture.selected })
                                }}
                                additionalEl={
                                    isCustom ? (
                                        <Box css={{ d: 'flex', ai: 'center', gap: '$1', mr: '$1' }}>
                                            <EditCultureDialog
                                                culture={{ id: culture.id, name: culture.name }}
                                            >
                                                <GhostIconButton>
                                                    <Image src={EditIcon} />
                                                </GhostIconButton>
                                            </EditCultureDialog>
                                            <DeleteCultureDialog cultureId={culture.id}>
                                                <GhostIconButton>
                                                    <Image src={TrashIcon} />
                                                </GhostIconButton>
                                            </DeleteCultureDialog>
                                        </Box>
                                    ) : null
                                }
                            />
                        )
                    })}

                <AddCultureDialog>
                    <Box
                        as={'button'}
                        css={{
                            'd': 'flex',
                            'fd': 'column',
                            'ai': 'flex-start',
                            'bc': '#ffffff',
                            'gap': '$2',
                            'border': '1px solid #E4E5E7',
                            'p': '$2',
                            'br': '$1',
                            'width': '100%',
                            'cursor': 'pointer',
                            'transition': 'box-shadow 0.2s ease-in-out, filter 0.3s ease-in-out',
                            '&:focus-visible': {
                                outline: ' -webkit-focus-ring-color auto 1px',
                            },
                            '&:hover': {
                                filter: 'brightness(105%)',
                                boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                                border: '1px solid rgba(83, 165, 81, 0.3)',
                            },
                        }}
                    >
                        <Box
                            css={{
                                d: 'flex',
                                ai: 'flex-start',
                                jc: 'space-between',
                                width: '100%',
                            }}
                        >
                            <Image src={AddIcon} width={28} height={28} />
                        </Box>

                        <Text
                            css={{
                                cursor: 'pointer',
                                fontSize: '16px',
                                fontWeight: 500,
                                lineHeight: '19px',
                                textAlign: 'left',
                            }}
                        >
                            Dodaj drugu kulturu
                        </Text>
                    </Box>
                </AddCultureDialog>
            </Box>
            {isFetching && <Text>Učitavanje...</Text>}
        </>
    )
}

export default EditCultures
