import { z } from 'zod'
import { FormProvider, SubmitHandler, useFieldArray, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { ReactNode, useEffect, useState } from 'react'
import { t } from 'i18next'

import { GetAllFarmFieldActivitiesResponseItem } from 'library/services/FarmFieldsTypes'

import {
    useDeleteActivityFromFieldMutation,
    useFarmQuery,
    useFinishActivityMutation,
} from 'hooks/api'

import { BaseButton, Button, GhostIconButton } from 'ui/Button'
import { Box, Form, Text } from 'ui/Common'
import { DialogBody, DialogClose, DialogTrigger, Dialog } from 'ui/Dialog'

import { FormDateInput } from './FormDateInput'
import { FormInput } from './FormInput'
import { FormInputSelect } from './FormInputSelect'
import { FormSelect } from './FormSelect'

export const EditCompletedActivityDialog = ({
    children,
    activity,
    fieldId,
}: {
    children: ReactNode
    fieldId: number
    activity: GetAllFarmFieldActivitiesResponseItem
}) => {
    const [open, setOpen] = useState(false)
    const { mutateAsync } = useFinishActivityMutation()
    const { mutateAsync: deleteActivityFromField, isLoading } = useDeleteActivityFromFieldMutation()
    const { data } = useFarmQuery()

    const finishActivitySchema = z.object({
        startDate: z.date(),
        endDate: z.date(),
        notes: z.string(),
        fuelConsumption: z.coerce.number({ invalid_type_error: t('validation:number.type') }),
        expenses: z.array(
            z.object({
                name: z.string(),
                quantity: z.coerce.number({ invalid_type_error: t('validation:number.type') }),
                type: z.string(),
                measureUnit: z.string(),
            }),
        ),
    })

    type FinishActivityValues = z.infer<typeof finishActivitySchema>

    const form = useForm<FinishActivityValues>({
        resolver: zodResolver(finishActivitySchema),
        defaultValues: {
            startDate: activity.startedAt ? new Date(activity.startedAt) : undefined,
            endDate: activity.endedAt ? new Date(activity.endedAt) : undefined,
            notes: activity?.notes ?? '',
            fuelConsumption: activity?.fuelConsumption ?? 0,
            expenses: activity?.expenses ?? [
                {
                    name: '',
                    type: '',
                    quantity: 0,
                    measureUnit: 'kg',
                },
            ],
        },
    })

    const { fields, append, remove } = useFieldArray({
        control: form.control,
        name: 'expenses',
    })
    const handleSubmit: SubmitHandler<FinishActivityValues> = async (values) => {
        try {
            const response = await mutateAsync({
                fieldId,
                fieldSeasonActivityId: activity.fieldSeasonActivityId,
                notes: values.notes,
                startedAt: new Date(new Date(values.startDate).setHours(10))
                    .toISOString()
                    .split('T')[0],
                fuelConsumption: values.fuelConsumption,
                endedAt: new Date(new Date(values.endDate).setHours(10))
                    .toISOString()
                    .split('T')[0],
                expenses: values.expenses,
            })
            setOpen(false)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        form.reset()
    }, [open])

    useEffect(() => {
        form.reset({
            startDate: activity.startedAt ? new Date(activity.startedAt) : undefined,
            endDate: activity.endedAt ? new Date(activity.endedAt) : undefined,
            notes: activity?.notes ?? '',
            fuelConsumption: activity?.fuelConsumption ?? 0,
            expenses: activity?.expenses ?? [
                {
                    name: '',
                    type: '',
                    quantity: 0,
                    measureUnit: 'kg',
                },
            ],
        })
    }, [activity])

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>{children}</DialogTrigger>
            <DialogBody title="Izmeni završenu aktivnost">
                <FormProvider {...form}>
                    <Form
                        css={{
                            d: 'flex',
                            fd: 'column',
                            gap: '$3',
                        }}
                        onSubmit={form.handleSubmit(handleSubmit)}
                    >
                        <Box
                            css={{
                                d: 'flex',
                                ai: 'center',
                                flexWrap: 'wrap',
                                gap: '$3',
                                width: '100%',
                            }}
                        >
                            <FormDateInput
                                name="startDate"
                                label="Datum početka"
                                containerCSS={{ fg: 1 }}
                            />
                            <FormDateInput
                                name="endDate"
                                label="Datum kraja"
                                containerCSS={{ fg: 1 }}
                            />
                        </Box>
                        <FormInput
                            name="fuelConsumption"
                            label={`Utrošak goriva (l/${data?.areaMeasureUnit?.abbreviation})`}
                        />

                        <Box css={{ d: 'flex', fd: 'column', gap: '$1' }}>
                            <Text
                                css={{
                                    fontSize: 12,
                                    lineHeight: '20px',
                                    color: 'rgba(44, 62, 80, 0.64)',
                                    userSelect: 'none',
                                }}
                            >
                                Repromaterijali:
                            </Text>
                            <Box css={{ d: 'flex', fd: 'column', gap: '$1' }}>
                                {fields.map((item, sectionPosition) => {
                                    return (
                                        <Box
                                            key={item.id}
                                            css={{
                                                background: 'rgb(244 246 245)',
                                                p: '$1',
                                                br: '$1',
                                                position: 'relative',
                                                d: 'flex',
                                                fd: 'column',
                                                gap: '$1',
                                            }}
                                        >
                                            <FormSelect
                                                name={`expenses[${sectionPosition}].type`}
                                                label="Tip repromaterijala"
                                                items={[
                                                    {
                                                        label: 'Seme',
                                                        value: 'Seme',
                                                    },
                                                    {
                                                        label: 'Pesticidi',
                                                        value: 'Pesticidi',
                                                    },
                                                    {
                                                        label: 'Đubrivo',
                                                        value: 'Đubrivo',
                                                    },
                                                    {
                                                        label: 'Ostalo',
                                                        value: 'Ostalo',
                                                    },
                                                ]}
                                                placeholder="Odaberite tip"
                                            />
                                            <Box
                                                css={{
                                                    d: 'flex',
                                                    ai: 'flex-start',
                                                    jc: 'space-between',
                                                    gap: '$1',
                                                }}
                                            >
                                                <FormInput
                                                    name={`expenses[${sectionPosition}].name`}
                                                    label="Naziv repromaterijala"
                                                    css={{ fg: 1 }}
                                                />
                                                <FormInput
                                                    name={`expenses[${sectionPosition}].quantity`}
                                                    label="Količina repromaterijala"
                                                    css={{ fg: 1 }}
                                                    rightEl={
                                                        <FormInputSelect
                                                            name={`expenses[${sectionPosition}].measureUnit`}
                                                            css={{
                                                                border: 'none',
                                                                right: 0,
                                                                float: 'right',
                                                            }}
                                                            items={[
                                                                {
                                                                    label: `kg/${data?.areaMeasureUnit?.abbreviation}`,
                                                                    value: `kg`,
                                                                },
                                                                {
                                                                    label: `g/${data?.areaMeasureUnit?.abbreviation}`,
                                                                    value: `g`,
                                                                },
                                                                {
                                                                    label: `sj/${data?.areaMeasureUnit?.abbreviation}`,
                                                                    value: `sj`,
                                                                },
                                                                {
                                                                    label: `l/${data?.areaMeasureUnit?.abbreviation}`,
                                                                    value: `l`,
                                                                },
                                                                {
                                                                    label: `dl/${data?.areaMeasureUnit?.abbreviation}`,
                                                                    value: `dl`,
                                                                },
                                                                {
                                                                    label: `ml/${data?.areaMeasureUnit?.abbreviation}`,
                                                                    value: `ml`,
                                                                },
                                                                {
                                                                    label: `kom/${data?.areaMeasureUnit?.abbreviation}`,
                                                                    value: `kom`,
                                                                },
                                                            ]}
                                                        />
                                                    }
                                                />
                                            </Box>

                                            {fields.length > 1 && (
                                                <GhostIconButton
                                                    onClick={() => remove(sectionPosition)}
                                                    css={{
                                                        position: 'absolute',
                                                        top: 8,
                                                        right: 8,
                                                    }}
                                                >
                                                    <svg
                                                        width="18"
                                                        height="18"
                                                        viewBox="0 0 18 18"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M3 4.95H4.33333M4.33333 4.95H15M4.33333 4.95V14.4C4.33333 14.758 4.47381 15.1014 4.72386 15.3546C4.97391 15.6078 5.31304 15.75 5.66667 15.75H12.3333C12.687 15.75 13.0261 15.6078 13.2761 15.3546C13.5262 15.1014 13.6667 14.758 13.6667 14.4V4.95H4.33333ZM6.33333 4.95V3.6C6.33333 3.24196 6.47381 2.89858 6.72386 2.64541C6.97391 2.39223 7.31304 2.25 7.66667 2.25H10.3333C10.687 2.25 11.0261 2.39223 11.2761 2.64541C11.5262 2.89858 11.6667 3.24196 11.6667 3.6V4.95M7.66667 8.325V12.375M10.3333 8.325V12.375"
                                                            stroke="#2C3E50"
                                                            stroke-width="1.5"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                    </svg>
                                                </GhostIconButton>
                                            )}
                                        </Box>
                                    )
                                })}
                            </Box>
                            <BaseButton
                                onClick={() =>
                                    append({ name: '', quantity: 0, type: '', measureUnit: 'kg' })
                                }
                                type="button"
                                css={{
                                    br: '$1',
                                    color: '#2C3E50',
                                    border: '1px solid #2C3E50',
                                    px: '$2-5',
                                    py: '$1-25',
                                    background: 'transparent',
                                    fontSize: '14px',
                                    fontWeight: 500,
                                }}
                            >
                                + Dodaj još repromaterijala
                            </BaseButton>
                        </Box>

                        <FormInput name="notes" label="Napomena" />

                        <Box
                            css={{
                                d: 'flex',
                                ai: 'center',
                                jc: 'space-between',
                                gap: '$2',
                            }}
                        >
                            <BaseButton
                                onClick={async () => {
                                    await deleteActivityFromField({
                                        fieldId,
                                        fieldSeasonActivityId: activity.fieldSeasonActivityId,
                                    })
                                    setOpen(false)
                                }}
                                type="button"
                                css={{
                                    d: 'flex',
                                    ai: 'center',
                                    gap: '$0-5',
                                    background: '#fff',
                                }}
                            >
                                <svg
                                    width="24"
                                    height="25"
                                    viewBox="0 0 24 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M4 7.1H5.77778M5.77778 7.1H20M5.77778 7.1V19.7C5.77778 20.1774 5.96508 20.6352 6.29848 20.9728C6.63187 21.3104 7.08406 21.5 7.55556 21.5H16.4444C16.9159 21.5 17.3681 21.3104 17.7015 20.9728C18.0349 20.6352 18.2222 20.1774 18.2222 19.7V7.1H5.77778ZM8.44444 7.1V5.3C8.44444 4.82261 8.63175 4.36477 8.96514 4.02721C9.29854 3.68964 9.75073 3.5 10.2222 3.5H13.7778C14.2493 3.5 14.7015 3.68964 15.0349 4.02721C15.3683 4.36477 15.5556 4.82261 15.5556 5.3V7.1M10.2222 11.6V17M13.7778 11.6V17"
                                        stroke="#E74C3C"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                                <Text css={{ color: '#E74C3C', fontSize: '16px', fontWeight: 500 }}>
                                    Ukloni
                                </Text>
                            </BaseButton>
                            <Box css={{ d: 'flex', ai: 'center', gap: '$2' }}>
                                <DialogClose asChild>
                                    <Button variant="secondary" type="button">
                                        Izađi
                                    </Button>
                                </DialogClose>
                                <Button
                                    type="submit"
                                    // isLoading={isLoading}
                                    // disabled={
                                    //     isLoading || !form.formState.isDirty || !form.formState.isValid
                                    // }
                                >
                                    Potvrdi
                                </Button>
                            </Box>
                        </Box>
                    </Form>
                </FormProvider>
            </DialogBody>
        </Dialog>
    )
}
