import {
    PaginationState,
    useReactTable,
    getCoreRowModel,
    ColumnDef,
    flexRender,
    createColumnHelper,
} from '@tanstack/react-table'
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import { useWindowSize } from 'react-use'

import { GetAllFarmFieldsResponseItem } from 'library/services/FarmFieldsTypes'

import { useFieldsQuery, useFarmSeasonsQuery, useFarmQuery } from 'hooks/api'
import { useDebouncedValue } from 'hooks/useDebouncedValue'

import { Box, Table, Tbody, Text, Thead, Link, Image } from 'ui/Common'
import { Input } from 'ui/Input'
import { styled } from 'ui/stitches.config'
import { BaseButton, Button } from 'ui/Button'
import Select from 'ui/Select'

import IconArrowRight from 'assets/icons/arrow_right_gray.svg'
import TruckImage from 'assets/icons/truck.png'
import PlaceholderIcon from 'assets/icons/placeholder.png'

import { FinishFieldsActivitiesDialog } from './FinishFieldsActivitiesDialog'
import { FullViewLoading } from './FullscreenLoading'
import { AddFieldDialog } from './AddFieldDialog'
import { FilterDialog } from './FilterDialog'
const columnHelper = createColumnHelper<GetAllFarmFieldsResponseItem>()

const PAGE_LIMIT = 100
const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }: any, ref) => {
    const defaultRef = useRef()
    const resolvedRef = ref || (defaultRef as any)

    useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
        <input
            type="checkbox"
            ref={resolvedRef}
            {...rest}
            style={{ margin: 0, marginLeft: 10, textAlign: 'center' }}
        />
    )
})

export const FieldsTable = () => {
    const { width } = useWindowSize()
    const [filterSeason, setFilterSeason] = useState<string | undefined>('')

    const [filterCultureIds, setFilterCultureIds] = useState<number[]>([])
    const [filterUnfinishedActivityIds, setFilterUnfinishedActivityIds] = useState<number[]>([])
    const [filterRegions, setFilterRegions] = useState<string[]>([])

    const { data: farm, isLoading: isLoadingFarm } = useFarmQuery()
    const { data: farmSeasons } = useFarmSeasonsQuery()
    const columns = useMemo<ColumnDef<GetAllFarmFieldsResponseItem>[]>(() => {
        if (width > 768) {
            return [
                columnHelper.display({
                    id: 'select',
                    header: ({ table }) => (
                        <IndeterminateCheckbox
                            {...{
                                checked: table.getIsAllRowsSelected(),
                                indeterminate: table.getIsSomeRowsSelected(),
                                onChange: table.getToggleAllRowsSelectedHandler(),
                            }}
                        />
                    ),
                    cell: ({ row }) => (
                        <div className="px-1">
                            <IndeterminateCheckbox
                                {...{
                                    checked: row.getIsSelected(),
                                    disabled: !row.getCanSelect(),
                                    indeterminate: row.getIsSomeSelected(),
                                    onChange: row.getToggleSelectedHandler(),
                                }}
                            />
                        </div>
                    ),
                }),
                {
                    header: 'Naziv njive',
                    accessorKey: 'name',
                },
                {
                    header: 'Potes',
                    accessorKey: 'region',
                },
                {
                    header: 'Kultura',
                    id: 'culture',
                    cell: (props) => (
                        <Box css={{ d: 'flex', ai: 'center', gap: '$0-5' }}>
                            <Box
                                css={{
                                    size: 28,
                                    background: 'rgba(83, 165, 81, 0.08)',
                                    br: '$0-5',
                                    d: 'flex',
                                    ai: 'center',
                                    jc: 'center',
                                }}
                            >
                                <Image
                                    src={props.row.original.agroCulture.iconPath ?? PlaceholderIcon}
                                    css={{ size: 20 }}
                                />
                            </Box>
                            <Text>{props.row.original.agroCulture.name}</Text>
                        </Box>
                    ),
                },
                {
                    header: 'Sorta/Hibrid',
                    accessorKey: 'sort',
                },
                {
                    header: `Površina (${farm?.areaMeasureUnit.abbreviation})`,
                    cell: (props) =>
                        Number(
                            (props.row.original.rentArea ?? 0) +
                                (props.row.original.ownedArea ?? 0),
                        ).toFixed(0),
                },
                {
                    header: 'Na redu',
                    cell: (props) =>
                        props.row.original.nextActivity ? (
                            <Box
                                css={{
                                    d: 'flex',
                                    ai: 'center',
                                }}
                            >
                                <Box
                                    css={{
                                        d: 'flex',
                                        ai: 'center',
                                        background: 'rgba(41, 128, 185, 0.08)',
                                        color: 'rgba(41, 128, 185, 1)',
                                        fontWeight: 400,
                                        fontSize: '14px',
                                        px: '$1-5',
                                        py: '$0-5',
                                        fg: 0,
                                        br: '$1-5',
                                    }}
                                >
                                    <Box
                                        css={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            maxWidth: 120,
                                        }}
                                    >
                                        {props.row.original.nextActivity.name}
                                    </Box>
                                </Box>
                            </Box>
                        ) : null,
                },
                {
                    id: 'details',
                    header: '',
                    cell: ({ row }) => (
                        <Link
                            to={`/kontrolna-tabla/njive/${row.original.id}`}
                            css={{
                                fontSize: '13px',
                                fontWeight: 600,
                                color: '#2C3E50',
                                d: 'flex',
                                ai: 'center',
                            }}
                        >
                            Detaljnije {'>'}
                        </Link>
                    ),
                },
            ]
        } else {
            return [
                columnHelper.display({
                    id: 'select',
                    header: ({ table }) => (
                        <IndeterminateCheckbox
                            {...{
                                checked: table.getIsAllRowsSelected(),
                                indeterminate: table.getIsSomeRowsSelected(),
                                onChange: table.getToggleAllRowsSelectedHandler(),
                            }}
                        />
                    ),
                    cell: ({ row }) => (
                        <div className="px-1">
                            <IndeterminateCheckbox
                                {...{
                                    checked: row.getIsSelected(),
                                    disabled: !row.getCanSelect(),
                                    indeterminate: row.getIsSomeSelected(),
                                    onChange: row.getToggleSelectedHandler(),
                                }}
                            />
                        </div>
                    ),
                }),
                {
                    header: 'Njiva i potes',
                    id: 'filed_and_region',
                    cell: (props) => (
                        <Box css={{ d: 'flex', fd: 'column', gap: '$0-5' }}>
                            <Text
                                css={{
                                    color: '#111827',
                                    fontWeight: 500,
                                    fontSize: '16px',
                                }}
                            >
                                {props.row.original.name}
                            </Text>
                            <Text
                                css={{
                                    color: '#6B7280',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                }}
                            >
                                {props.row.original.region}
                            </Text>
                        </Box>
                    ),
                },
                {
                    header: `Površina`,
                    id: 'area',
                    cell: (props) => (
                        <Box css={{ d: 'flex', fd: 'column', gap: '$0-5' }}>
                            <Text
                                css={{
                                    color: '#111827',
                                    fontWeight: 500,
                                    fontSize: '16px',
                                }}
                            >
                                {Number(
                                    (props.row.original.rentArea ?? 0) +
                                        (props.row.original.ownedArea ?? 0),
                                ).toFixed(0)}
                            </Text>
                            <Text
                                css={{
                                    color: '#6B7280',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                }}
                            >
                                {farm?.areaMeasureUnit?.abbreviation}
                            </Text>
                        </Box>
                    ),
                },
                {
                    header: 'Kultura i sorta/hibrid',
                    id: 'culture_and_sort',
                    cell: (props) => (
                        <Box css={{ d: 'flex', fd: 'column', gap: '$0-5' }}>
                            <Text
                                css={{
                                    color: '#111827',
                                    fontWeight: 500,
                                    fontSize: '16px',
                                }}
                            >
                                {props.row.original.agroCulture.name}
                            </Text>
                            <Text
                                css={{
                                    color: '#6B7280',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                }}
                            >
                                {props.row.original.sort}
                            </Text>
                        </Box>
                    ),
                },
                {
                    id: 'details',
                    header: '',
                    cell: ({ row }) => (
                        <Link
                            to={`/kontrolna-tabla/njive/${row.original.id}`}
                            css={{
                                fontSize: '13px',
                                fontWeight: 600,
                                color: '#2C3E50',
                                d: 'flex',
                                ai: 'center',
                            }}
                        >
                            <Image
                                src={IconArrowRight}
                                css={{
                                    rotate: '0deg',
                                }}
                            />
                        </Link>
                    ),
                },
            ]
        }
    }, [farm, width])

    const [searchText, setSearchText] = useState('')
    const [debouncedSearchText] = useDebouncedValue(searchText, 500)
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: PAGE_LIMIT,
    })

    const {
        data,
        isLoading: isLoadingFields,
        refetch,
    } = useFieldsQuery({
        offset: pageIndex * pageSize,
        limit: PAGE_LIMIT,
        orderBy: 'region',
        orderType: 'asc',
        filterByName: debouncedSearchText,
        seasonId: filterSeason ? filterSeason : undefined,
        agroCultureFilter: filterCultureIds,
        nextActivityFilter: filterUnfinishedActivityIds,
        regionFilter: filterRegions,
    })

    const defaultData = useMemo(() => [], [])

    const pagination = useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize],
    )

    const pageCount = useMemo(() => Math.ceil((data?.totalCount ?? 0) / PAGE_LIMIT), [data])

    const table = useReactTable({
        data: data?.data ?? defaultData,
        columns,
        pageCount: pageCount,
        state: {
            pagination,
        },
        onPaginationChange: setPagination,
        getCoreRowModel: getCoreRowModel(),
        manualPagination: true,
    })

    const selectedFields = useMemo(
        () => table.getSelectedRowModel().flatRows.map((r) => r.original),
        [table.getSelectedRowModel()],
    )

    const isLoadingTable = isLoadingFarm || isLoadingFields
    const noActiveFilters =
        !searchText &&
        !filterSeason &&
        filterCultureIds.length === 0 &&
        filterUnfinishedActivityIds.length === 0 &&
        filterRegions.length === 0

    const filterCount = useMemo(() => {
        let count = 0
        if (filterCultureIds.length) {
            count = count + 1
        }
        if (filterUnfinishedActivityIds.length) {
            count = count + 1
        }
        if (filterRegions.length) {
            count = count + 1
        }
        return count
    }, [filterCultureIds, filterUnfinishedActivityIds, filterRegions])

    if (isLoadingTable) {
        return <FullViewLoading />
    }

    if (!data?.data.length && noActiveFilters) {
        return (
            <Box
                css={{
                    background: '#fff',
                    px: '$1-25',
                    py: '$4',
                    width: '100%',
                    d: 'flex',
                    fd: 'column',
                    ai: 'center',
                    gap: '$2',
                }}
            >
                <Image
                    src={TruckImage}
                    css={{
                        size: 160,
                    }}
                />
                <Box
                    css={{
                        d: 'flex',
                        fd: 'column',
                        ai: 'center',
                        gap: '$1-25',
                    }}
                >
                    <Text
                        css={{
                            fontSize: '24px',
                            fontWeight: 600,
                            color: '#2C3E50',
                        }}
                    >
                        Još uvek niste dodali svoje njive.
                    </Text>
                    <Text
                        css={{
                            fontSize: '14px',
                            color: 'rgba(0, 0, 0, 0.64)',
                        }}
                    >
                        Dodajte ih klikom na dugme ispod.
                    </Text>
                </Box>

                <AddFieldDialog>
                    <Button variant="cta">Dodaj njivu</Button>
                </AddFieldDialog>
            </Box>
        )
    }

    return (
        <Box
            css={{
                'px': '$2',
                '@maxMd': {
                    px: 0,
                },
            }}
        >
            {!!selectedFields.length && (
                <Box
                    css={{
                        'position': 'fixed',
                        'bottom': 70,
                        'zIndex': 1,
                        'background': '#fff',
                        'borderTop': '1px solid #E5E7EB',
                        'width': '100%',
                        'd': 'flex',
                        'ai': 'center',
                        'jc': 'center',
                        'p': '$1',
                        '@minLg': {
                            display: 'none',
                        },
                    }}
                >
                    <FinishFieldsActivitiesDialog
                        fields={selectedFields as any}
                        onFinish={() => table.resetRowSelection()}
                    >
                        <BaseButton
                            css={{
                                'background': '#3B82F6',
                                'color': '#fff',
                                'py': '$1',
                                'fontSize': '12px',
                                'br': '$0-5',
                                'px': '$1-25',
                                '&:disabled': {
                                    background: '#D1D5DB',
                                    cursor: 'not-allowed',
                                },
                            }}
                        >
                            Završi aktivnosti
                        </BaseButton>
                    </FinishFieldsActivitiesDialog>
                </Box>
            )}
            <Box
                css={{
                    'border': '1px solid #D1D5DB',
                    'br': '$1',
                    '@maxMd': {
                        br: 0,
                    },
                }}
            >
                <Box
                    css={{
                        p: '$2',
                        background: '#F9FAFB',
                        display: 'flex',
                        ai: 'center',
                        jc: 'space-between',
                        gap: '$1-25',
                        borderBottom: '1px solid rgba(209, 213, 219, 1)',
                        btrr: '$1',
                        btlr: '$1',
                    }}
                >
                    <Box
                        css={{
                            d: 'flex',
                            ai: 'center',
                            jc: 'space-between',
                            width: '100%',
                        }}
                    >
                        <Box
                            css={{
                                d: 'flex',
                                gap: '$3',
                                ai: 'center',
                                fg: 1,
                            }}
                        >
                            <Input
                                placeholder={width > 768 ? 'Pretraži njive po nazivu' : 'Pretraga'}
                                value={searchText}
                                onChange={({ target }) => setSearchText(target.value)}
                                css={{
                                    'py': '$1-5',
                                    'fontSize': '14px',
                                    'lineHeight': '17px',
                                    'maxWidth': '320px',
                                    'width': '100%',
                                    '@maxMd': {
                                        width: '70%',
                                    },
                                }}
                            />
                            <FinishFieldsActivitiesDialog
                                fields={selectedFields as any}
                                onFinish={() => table.resetRowSelection()}
                            >
                                {!!selectedFields.length && (
                                    <BaseButton
                                        css={{
                                            'background': '#3B82F6',
                                            'color': '#fff',
                                            'py': '$1',
                                            'fontSize': '12px',
                                            'br': '$0-5',
                                            'px': '$1-25',
                                            '&:disabled': {
                                                background: '#D1D5DB',
                                                cursor: 'not-allowed',
                                            },
                                            '@maxMd': {
                                                display: 'none',
                                            },
                                        }}
                                    >
                                        Završi aktivnosti
                                    </BaseButton>
                                )}
                            </FinishFieldsActivitiesDialog>
                        </Box>
                        <Box
                            css={{
                                d: 'flex',
                                gap: '$2',
                                ai: 'center',
                            }}
                        >
                            <Select
                                onValueChange={(value) => setFilterSeason(value)}
                                value={filterSeason}
                                placeholder="Sezona"
                                items={[
                                    { label: 'Sve sezone', value: '' },
                                    farmSeasons?.map((s) => ({
                                        label: s.name,
                                        value: String(s.id),
                                    })) ?? [],
                                ].flat()}
                            />
                            <FilterDialog
                                onFilterChange={(newFilters) => {
                                    refetch()
                                    setFilterCultureIds(newFilters.filterCultureIds)
                                    setFilterUnfinishedActivityIds(
                                        newFilters.filterUnfinishedActivityIds,
                                    )
                                    setFilterRegions(newFilters.filterRegions)
                                }}
                                defaultFilterCulturesIds={filterCultureIds}
                                defaultFilterUnfinishedActivityIds={filterUnfinishedActivityIds}
                                defaultFilterRegions={filterRegions}
                            >
                                <BaseButton
                                    css={{
                                        background: '#fff',
                                        border: '1px solid rgb(228, 229, 231)',
                                        p: '$1-25',
                                        br: '$1',
                                        fontSize: '14px',
                                        fontFamily: '$inter',
                                        d: 'flex',
                                        ai: 'center',
                                        gap: '$0-5',
                                    }}
                                >
                                    Filteri
                                    {!!filterCount && (
                                        <Box
                                            css={{
                                                br: 100,
                                                size: 20,
                                                background: 'rgba(83, 165, 81, 0.16)',
                                                color: '#53A551',
                                                d: 'flex',
                                                ai: 'center',
                                                jc: 'center',
                                                fontWeight: 600,
                                                fontSize: '12px',
                                            }}
                                        >
                                            {filterCount}
                                        </Box>
                                    )}
                                </BaseButton>
                            </FilterDialog>
                        </Box>
                    </Box>
                </Box>
                {table.getRowModel().rows.length ? (
                    <Table
                        css={{
                            borderSpacing: 0,
                            width: '100%',
                        }}
                    >
                        <Thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <FieldsTr key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => {
                                        return (
                                            <FieldsTh key={header.id} colSpan={header.colSpan}>
                                                {header.isPlaceholder ? null : (
                                                    <div>
                                                        {flexRender(
                                                            header.column.columnDef.header,
                                                            header.getContext(),
                                                        )}
                                                    </div>
                                                )}
                                            </FieldsTh>
                                        )
                                    })}
                                </FieldsTr>
                            ))}
                        </Thead>
                        <Tbody>
                            {table.getRowModel().rows.map((row, index) => {
                                return (
                                    <FieldsTr
                                        key={row.id}
                                        css={{
                                            background:
                                                index % 2 === 0 ? 'rgb(249, 250, 251)' : '#fff',
                                        }}
                                    >
                                        {row.getVisibleCells().map((cell) => {
                                            return (
                                                <FieldsTd key={cell.id}>
                                                    {flexRender(
                                                        cell.column.columnDef.cell,
                                                        cell.getContext(),
                                                    )}
                                                </FieldsTd>
                                            )
                                        })}
                                    </FieldsTr>
                                )
                            })}
                        </Tbody>
                    </Table>
                ) : (
                    <Box css={{ p: '$2', textAlign: 'center' }}>
                        <Text css={{ fontSize: '$2', color: '$gray-600' }}>
                            Nema rezultata za odabrane filtere
                        </Text>
                    </Box>
                )}
                <Box
                    css={{
                        bbrr: '$1',
                        bblr: '$1',
                        display: 'flex',
                        ai: 'center',
                        px: '$2',
                        py: '$1-75',
                        background: '#F9FAFB',
                        jc: 'space-between',
                    }}
                >
                    <Text>
                        Prikaz od {pageIndex * pageSize + 1}-
                        {pageIndex * pageSize + (data?.data?.length ?? 0)} od{' '}
                        {data?.totalCount ?? 0} rezultata
                    </Text>
                    <Box
                        css={{
                            d: 'flex',
                            ai: 'center',
                            border: '1px solid #D2D5DA',
                            br: '$1',
                            background: '#fff',
                            height: '36px',
                        }}
                    >
                        <Button
                            css={{
                                background: 'transparent',
                                color: '#6C727F',
                            }}
                            className="border rounded p-1"
                            onClick={() => table.previousPage()}
                            disabled={!table.getCanPreviousPage()}
                        >
                            {'<'}
                        </Button>
                        {[...Array(pageCount)].map((_, index) => (
                            <Button
                                css={{
                                    background: pageIndex === index ? '#EFF2FE' : 'transparent',
                                    color: '#6C727F',
                                }}
                                key={`paginationButton${index}`}
                                onClick={() => table.setPageIndex(index)}
                            >
                                {index + 1}
                            </Button>
                        ))}
                        <Button
                            css={{
                                background: 'transparent',
                                color: '#6C727F',
                            }}
                            className="border rounded p-1"
                            onClick={() => table.nextPage()}
                            disabled={!table.getCanNextPage()}
                        >
                            {'>'}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

const FieldsTd = styled('td', {
    margin: 0,
    py: '$2-5',
    textAlign: 'left',
    px: 5,
    color: '#2C3E50',
    fontSize: '16px',
    fontWeight: 500,
    borderBottom: '1px solid rgba(209, 213, 219, 1)',
})

const FieldsTr = styled('tr', {
    borderBottom: '1px solid rgba(209, 213, 219, 1)',
    background: '#fff',
})

const FieldsTh = styled('th', {
    color: 'rgba(44, 62, 80, 0.64)',
    margin: 0,
    py: '$2',
    px: 5,
    fontSize: '16px',
    textAlign: 'left',
    fontWeight: 400,
    borderBottom: '1px solid rgba(209, 213, 219, 1)',
})
