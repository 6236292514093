import { zodResolver } from '@hookform/resolvers/zod'
import { FormProvider, useForm } from 'react-hook-form'
import { z } from 'zod'
import { t } from 'i18next'

import { FormInput } from 'components/FormInput'
import { FormRadioGroup } from 'components/FormRadioGroup'

import {
    useAreaMeasureUnitsQuery,
    useCreateFarmMutation,
    useUpdateProfileMutation,
} from 'hooks/api'

import { Box, Form, Text } from 'ui/Common'

import OnboardingNavigation from './OnboardingNavigation'
import { useOnboardingState } from './OnboardingState'

export const OnboardingFarm = () => {
    const { data } = useAreaMeasureUnitsQuery()
    const { mutateAsync: createFarm } = useCreateFarmMutation()
    const { mutateAsync: updateProfile } = useUpdateProfileMutation()
    const { onNextStep } = useOnboardingState()
    const farmSchema = z.object({
        fullName: z.string().min(2, t('validation:fullName.min')),
        farmName: z.string().min(1, t('validation:farmName.min')),
        farmLocation: z.string().min(2, t('validation:farmLocation.min')),
        areaMeasureUnits: z.string().min(1, t('validation:areaMeasureUnits.min')),
        // seasonName: z.string().min(1, t('validation:seasonName.min')),
    })

    type farmValues = z.infer<typeof farmSchema>

    const form = useForm({
        resolver: zodResolver(farmSchema),
        defaultValues: {
            fullName: '',
            farmName: '',
            farmLocation: '',
            areaMeasureUnits: '',
            // seasonName: 'Sezona 2022/2023',
        },
    })
    const handleSubmit = async (values: farmValues) => {
        try {
            await Promise.all([
                createFarm({
                    name: values.farmName,
                    location: values.farmLocation,
                    areaMeasureUnitId: Number(values.areaMeasureUnits),
                    seasonName: 'Sezona 2022/2023',
                }),
                updateProfile({
                    firstName: values.fullName.split(' ')[0],
                    lastName: values.fullName.split(' ')[1],
                }),
            ])
            onNextStep()
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Box
            css={{
                d: 'flex',
                fd: 'column',
                p: '$2',
                pt: '$5',
                gap: '$5',
            }}
        >
            <Box
                css={{
                    d: 'flex',
                    fd: 'column',
                    gap: '$0-5',
                }}
            >
                <Text
                    css={{
                        fontWeight: 600,
                        fontsSize: '20px',
                    }}
                >
                    👨🏻‍🌾 Gazdinstvo
                </Text>
                <Text
                    css={{
                        color: 'rgba(0, 0, 0, 0.64)',
                        fontSize: '14px',
                    }}
                >
                    Unesite osnovne podatke o vašem gazdinstvu.
                </Text>
            </Box>
            <Box
                css={{
                    d: 'flex',
                    fd: 'column',
                    gap: '$3',
                }}
            >
                <FormProvider {...form}>
                    <Form
                        css={{
                            d: 'flex',
                            fd: 'column',
                            gap: '$3',
                        }}
                        onSubmit={form.handleSubmit(handleSubmit)}
                    >
                        <FormInput
                            name="fullName"
                            label="Ime i prezime"
                            placeholder="Unesite svoje ime i prezime"
                        />
                        <FormInput
                            name="farmName"
                            label="Naziv gazdinstva"
                            placeholder="Unesite naziv gazdinstva"
                        />
                        <FormInput
                            name="farmLocation"
                            label="Lokacija gazdinstva"
                            placeholder="Unesite lokaciju gazdinstva"
                        />

                        <FormRadioGroup
                            name="areaMeasureUnits"
                            label="Odaberite mernu jedinicu"
                            options={
                                data?.map((a) => {
                                    const secondaryLabel =
                                        a.ratio === 1 ? a.name : `1 ${a.abbreviation}=${a.ratio}ha`
                                    return {
                                        value: String(a.id),
                                        label: a.abbreviation,
                                        secondaryLabel,
                                    }
                                }) ?? []
                            }
                        />

                        {/* <FormSelect
                            name="seasonName"
                            placeholder="Odaberite sezonu"
                            label="Sezona"
                            items={[
                                {
                                    label: `Sezona ${new Date().getFullYear() - 2}/${
                                        new Date().getFullYear() - 1
                                    }`,
                                    value: `Sezona ${new Date().getFullYear() - 2}/${
                                        new Date().getFullYear() - 1
                                    }`,
                                },
                                {
                                    label: `Sezona ${
                                        new Date().getFullYear() - 1
                                    }/${new Date().getFullYear()}`,
                                    value: `Sezona ${
                                        new Date().getFullYear() - 1
                                    }/${new Date().getFullYear()}`,
                                },
                                {
                                    label: `Sezona ${new Date().getFullYear()}/${
                                        new Date().getFullYear() + 1
                                    }`,
                                    value: `Sezona ${new Date().getFullYear()}/${
                                        new Date().getFullYear() + 1
                                    }`,
                                },
                            ]}
                        /> */}
                    </Form>
                </FormProvider>
            </Box>
            <OnboardingNavigation
                onStepComplete={form.handleSubmit(handleSubmit)}
                isLoading={form.formState.isSubmitting}
            />
        </Box>
    )
}
