import { ReactNode, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useDeleteFieldMutation } from 'hooks/api'

import { Button } from 'ui/Button'
import { Box } from 'ui/Common'
import { DialogBody, DialogClose, DialogTrigger, Dialog } from 'ui/Dialog'

interface DeleteFieldDialogProps {
    children: ReactNode
    fieldId: number
}
export const DeleteFieldDialog = ({ children, fieldId }: DeleteFieldDialogProps) => {
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const { mutateAsync, isLoading, error } = useDeleteFieldMutation()

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>{children}</DialogTrigger>
            <DialogBody title="Da li ste sigurni da želite da obrišete ovu njivu?">
                <Box
                    css={{
                        d: 'flex',
                        ai: 'center',
                        jc: 'flex-end',
                        gap: '$2',
                    }}
                >
                    <DialogClose asChild>
                        <Button variant="secondary" type="button">
                            Izađi
                        </Button>
                    </DialogClose>
                    <Button
                        type="button"
                        isLoading={isLoading}
                        onClick={async () => {
                            await mutateAsync({ id: fieldId })
                            setOpen(false)
                            navigate('/kontrolna-tabla/njive', { replace: true })
                        }}
                    >
                        Obriši
                    </Button>
                </Box>
            </DialogBody>
        </Dialog>
    )
}
