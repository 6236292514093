import { styled } from 'ui/stitches.config'

export const DashboardPageLayout = styled('div', {
    'd': 'flex',
    'fd': 'column',
    'gap': '$4',
    'p': '$3',
    'flexGrow': 1,
    '@maxSm': {
        p: '$2',
    },
    'variants': {
        noPadding: {
            true: {
                'p': 0,
                '@maxSm': {
                    p: 0,
                },
            },
        },
    },
})
