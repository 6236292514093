import { ReactNode, useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Controller, useFormContext } from 'react-hook-form'
import * as Label from '@radix-ui/react-label'
import InputMask from 'react-input-mask'

import { useClickOutside } from 'hooks/useClickOutside'

import { Box, Image, Text } from 'ui/Common'
import { CSS, styled } from 'ui/stitches.config'
import { GhostIconButton } from 'ui/Button'

import CalendarIcon from 'assets/icons/calendar.svg'
import './styles.css'

interface InputProps {
    name: string
    label?: string
    labelHelpEl?: ReactNode
    containerCSS?: CSS
    labelContainerCSS?: CSS
    disabled?: boolean
}

export const FormDateInput = function ({
    name,
    label,
    labelHelpEl,
    containerCSS = {},
    labelContainerCSS = {},
    disabled,
    ...inputProps
}: InputProps) {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [isFocused, setIsFocused] = useState<boolean>(false)
    const {
        control,
        formState: { errors },
    } = useFormContext()

    const dateRef = useRef<any>()
    useClickOutside(dateRef, () => {
        setIsOpen(false)
        setIsFocused(false)
    })

    const error = errors[name]?.message?.toString()

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value } }) => {
                return (
                    <Box
                        css={{
                            d: 'flex',
                            fd: 'column',
                            gap: '$1',
                            ...containerCSS,
                        }}
                    >
                        {(!!label || !!labelHelpEl) && (
                            <Box
                                css={{
                                    d: 'flex',
                                    jc: 'space-between',
                                    ...labelContainerCSS,
                                }}
                            >
                                {!!label && (
                                    <LabelRoot htmlFor={name} css={{ fontSize: 12 }}>
                                        {label}
                                        {/* {!!inputProps.required && '*'} */}
                                    </LabelRoot>
                                )}
                                {!!labelHelpEl && labelHelpEl}
                            </Box>
                        )}
                        <Box
                            css={{
                                d: 'flex',
                                ai: 'center',
                                backgroundColor: '#FFFFFF',
                                color: '#6D717F',
                                padding: '$1-25',
                                fontSize: '14px',
                                br: '$1',
                                border: '1px solid #E4E5E7',
                                ...(disabled && {
                                    backgroundColor: '#F9FAFB',
                                    border: '1px solid #F9FAFB',
                                }),
                            }}
                        >
                            <StyledDateTimePicker
                                id={name}
                                dateFormat="dd/MM/yyyy"
                                open={isOpen}
                                selected={value}
                                disabled={disabled}
                                onChange={(d) => {
                                    onChange(d)
                                }}
                                onSelect={(d) => {
                                    onChange(d)
                                    setIsOpen(false)
                                }}
                                onClickOutside={() => null}
                                customInput={
                                    <StyledDateInput
                                        disabled={disabled}
                                        onChange={onChange}
                                        value={value}
                                        mask="99/99/9999"
                                    />
                                }
                            />

                            <GhostIconButton
                                onClick={() => setIsOpen(!isOpen)}
                                type="button"
                                disabled={disabled}
                                css={{
                                    size: '16px',
                                }}
                            >
                                <Image
                                    src={CalendarIcon}
                                    css={{
                                        size: '16px',
                                    }}
                                />
                            </GhostIconButton>
                        </Box>

                        {!!error && (
                            <Text
                                css={{
                                    color: '#EF4444',
                                    fontSize: 14,
                                    marginTop: '$0-25',
                                }}
                            >
                                {error}
                            </Text>
                        )}
                    </Box>
                )
            }}
        />
    )
}

const LabelRoot = styled(Label.Root, {
    fontSize: 14,
    lineHeight: '20px',
    color: 'rgba(44, 62, 80, 0.64)',
    userSelect: 'none',
})

const StyledDateTimePicker = styled(DatePicker, { height: '16px' })

const StyledDateInput = styled(InputMask, {
    width: '100%',
    border: 'none',
    color: '#6D717F',
    fontSize: '14px',
    outline: 'none',
    padding: 0,
})
