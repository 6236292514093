import { useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useWindowSize } from 'react-use'

import { FullViewLoading } from 'components/FullscreenLoading'

import { BaseButton, Button } from 'ui/Button'
import { Box, Link, Text } from 'ui/Common'
import { DashboardPageLayout } from 'ui/DashboardPageLayout'
import { Input } from 'ui/Input'

const CatalogPage = () => {
    const { width, height } = useWindowSize()
    const isHorizontal = width > height
    const { state } = useLocation()

    if (!state.filePath) return <Navigate to="/kontrolna-tabla/katalozi" />

    const [page, setPage] = useState(1)

    const [numPages, setNumPages] = useState(null)

    function onDocumentLoadSuccess({ numPages }: any) {
        setNumPages(numPages)
    }
    return (
        <DashboardPageLayout>
            <Box
                css={{
                    d: 'flex',
                    gap: '$2',
                    jc: 'space-between',
                    ai: 'center',
                }}
            >
                <Box
                    css={{
                        'd': 'flex',
                        'fd': 'column',
                        'gap': '$1',
                        '@maxMd': {
                            width: '100%',
                        },
                    }}
                >
                    <Box
                        css={{
                            d: 'flex',
                            ai: 'center',
                            jc: 'space-between',
                            width: '100%',
                        }}
                    >
                        <Link
                            to="/kontrolna-tabla/katalozi"
                            css={{
                                color: 'rgba(0, 0, 0, 0.64)',
                                fontWeight: 400,
                                fontSize: '14px',
                                lineHeight: '16.94px',
                            }}
                        >
                            {'<'}Nazad
                        </Link>
                        <a href={state.filePath}>
                            <BaseButton
                                css={{
                                    'd': 'flex',
                                    'ai': 'center',
                                    'gap': '$1',
                                    'color': '#53A551',
                                    'fontSize': '14px',
                                    'fontWeight': 400,
                                    '@minMd': {
                                        display: 'none',
                                    },
                                }}
                            >
                                Sačuvaj katalog
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g clip-path="url(#clip0_2055_34873)">
                                        <path
                                            d="M13.8251 7.5H12.5001V3.33333C12.5001 2.875 12.1251 2.5 11.6667 2.5H8.33341C7.87508 2.5 7.50008 2.875 7.50008 3.33333V7.5H6.17508C5.43341 7.5 5.05841 8.4 5.58341 8.925L9.40841 12.75C9.73341 13.075 10.2584 13.075 10.5834 12.75L14.4084 8.925C14.9334 8.4 14.5667 7.5 13.8251 7.5ZM4.16675 15.8333C4.16675 16.2917 4.54175 16.6667 5.00008 16.6667H15.0001C15.4584 16.6667 15.8334 16.2917 15.8334 15.8333C15.8334 15.375 15.4584 15 15.0001 15H5.00008C4.54175 15 4.16675 15.375 4.16675 15.8333Z"
                                            fill="#53A551"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_2055_34873">
                                            <rect width="20" height="20" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </BaseButton>
                        </a>
                    </Box>

                    <Text
                        css={{
                            color: '#2C3E50',
                            fontWeight: 600,
                            fontSize: '24px',
                            lineHeight: '28px',
                        }}
                    >
                        {state.name}
                    </Text>
                </Box>
                <a href={state.filePath}>
                    <Button
                        variant="cta"
                        css={{
                            'gap': '$1',
                            '@maxMd': {
                                display: 'none',
                            },
                        }}
                    >
                        Sačuvaj katalog
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clipPath="url(#clip0_2058_7019)">
                                <path
                                    d="M16.59 9H15V4C15 3.45 14.55 3 14 3H10C9.45 3 9 3.45 9 4V9H7.41C6.52 9 6.07 10.08 6.7 10.71L11.29 15.3C11.68 15.69 12.31 15.69 12.7 15.3L17.29 10.71C17.92 10.08 17.48 9 16.59 9ZM5 19C5 19.55 5.45 20 6 20H18C18.55 20 19 19.55 19 19C19 18.45 18.55 18 18 18H6C5.45 18 5 18.45 5 19Z"
                                    fill="white"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_2058_7019">
                                    <rect width="24" height="24" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </Button>
                </a>
            </Box>
            <Box
                css={{
                    d: 'flex',
                    ai: 'center',
                    jc: 'center',
                    fd: 'column',
                    canvas: {
                        maxWidth: isHorizontal ? 'unset !important' : '90vw !important',
                        maxHeight: isHorizontal ? '80vh' : 'unset !important',
                        width: isHorizontal ? 'unset !important' : 'initial',
                        height: isHorizontal ? 'initial' : 'unset !important',
                    },
                }}
            >
                {!numPages && <FullViewLoading />}
                {/* <AspectRatio.Root ratio={16 / 9}> */}

                {/* </AspectRatio.Root> */}
                {numPages && (
                    <Box css={{ d: 'flex', ai: 'center', mt: '$2', gap: '$2' }}>
                        <Button
                            disabled={page === 1}
                            onClick={() => {
                                if (page > 1) {
                                    setPage(page - 1)
                                }
                            }}
                            variant={'secondary'}
                        >
                            Prethodna
                        </Button>
                        <Box
                            css={{
                                d: 'flex',
                                ai: 'center',
                                gap: '$1',
                            }}
                        >
                            <Input
                                value={page}
                                onChange={(e) => {
                                    if (!Number.isNaN(Number(e.target.value))) {
                                        setPage(Number(e.target.value))
                                    }
                                }}
                                type="tel"
                                css={{
                                    width: '50px',
                                }}
                            />

                            <Text
                                css={{
                                    fontSize: '16px',
                                    fontWeight: 500,
                                }}
                            >
                                od {numPages}
                            </Text>
                        </Box>

                        <Button
                            disabled={page === numPages}
                            onClick={() => {
                                if (page < numPages) {
                                    setPage(page + 1)
                                }
                            }}
                        >
                            Sledeća
                        </Button>
                    </Box>
                )}
            </Box>
        </DashboardPageLayout>
    )
}

export default CatalogPage
