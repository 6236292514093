import { httpClient } from 'library/http'
import { storage, StorageKey } from 'library/local-storage'

import type {
    ConfirmRegistrationRequest,
    ConfirmResetPasswordRequest,
    GetCurrentUserResponse,
    LoginRequest,
    LoginResponse,
    RegisterRequest,
    ResetPasswordRequest,
    UpdateProfileRequest,
} from './UsersTypes'

export class UsersService {
    private _baseUrl = `/users`
    private _registerUrl = `${this._baseUrl}/register`
    private _loginUrl = `${this._baseUrl}/login`
    private _confirmRegistrationUrl = `${this._baseUrl}/register/confirm`
    private _resetPasswordUrl = `${this._baseUrl}/reset-password`

    public async getCurrentUser() {
        return httpClient.get<GetCurrentUserResponse>(this._baseUrl)
    }

    public async updateProfile(request: UpdateProfileRequest) {
        return httpClient.put(this._baseUrl, request)
    }

    public async register(request: RegisterRequest) {
        return httpClient.post(this._registerUrl, request)
    }

    public async confirmRegistration({ registrationUUID }: ConfirmRegistrationRequest) {
        const requestUrl = `${this._confirmRegistrationUrl}/${registrationUUID}`

        return httpClient.get(requestUrl)
    }

    public async login(request: LoginRequest) {
        const { data } = await httpClient.post<LoginResponse>(this._loginUrl, request)

        storage.save(StorageKey.ACCESS_TOKEN, data.accessToken)
        storage.save(StorageKey.REFRESH_TOKEN, data.refreshToken)

        const response = await this.getCurrentUser()
        return response
    }

    public async requestResetPassword(request: ResetPasswordRequest) {
        return httpClient.post(this._resetPasswordUrl, request)
    }

    public async confirmResetPassword(request: ConfirmResetPasswordRequest) {
        return httpClient.post(`${this._resetPasswordUrl}/confirm`, request)
    }

    public async logout() {
        storage.clear()
    }
}

export const usersService = new UsersService()
