import { Box } from 'ui/Common'
import { keyframes } from 'ui/stitches.config'

const spinner = keyframes({
    '0%': {
        transform: 'rotate(0deg)',
    },
    '100%': {
        transform: 'rotate(360deg)',
    },
})

export const FullscreenLoading = () => {
    return (
        <Box
            css={{
                d: 'flex',
                ai: 'center',
                jc: 'center',
                w: '100vw',
                h: '100vh',
                minHeight: '100vh',
                minWidth: '100vw',
            }}
        >
            <Box
                css={{
                    'd': 'inline-block',
                    'w': 40,
                    'h': 40,
                    '&:after': {
                        content: ' ',
                        display: 'block',
                        width: '64px',
                        height: '64px',
                        margin: '8px',
                        borderRadius: '50%',
                        border: '6px solid rgb(20, 144, 69)',
                        borderColor: 'rgb(20, 144, 69) transparent rgb(20, 144, 69) transparent',
                        animation: `${spinner} 1.2s linear infinite`,
                    },
                }}
            />
        </Box>
    )
}

export const FullViewLoading = () => {
    return (
        <Box
            css={{
                d: 'flex',
                ai: 'center',
                jc: 'center',
                width: '100%',
                height: '100%',
                fg: 1,
            }}
        >
            <Box
                css={{
                    'd': 'inline-block',
                    'w': 40,
                    'h': 40,
                    '&:after': {
                        content: ' ',
                        display: 'block',
                        width: '64px',
                        height: '64px',
                        margin: '8px',
                        borderRadius: '50%',
                        border: '6px solid rgb(20, 144, 69)',
                        borderColor: 'rgb(20, 144, 69) transparent rgb(20, 144, 69) transparent',
                        animation: `${spinner} 1.2s linear infinite`,
                    },
                }}
            />
        </Box>
    )
}
