import { httpClient } from 'library/http'

import type {
    CreateFarmAgroActivityRequest,
    CreateFarmAgroCultureRequest,
    CreateFarmRequest,
    DeleteFarmAgroCultureRequest,
    GetFarmAgroActivitiesResponse,
    GetFarmAgroCultureActivitiesRequest,
    GetFarmAgroCultureActivitiesResponse,
    GetFarmAgroCulturesResponse,
    GetFarmDashboardResponse,
    GetFarmResponse,
    GetFarmSeasonsResponse,
    SelectFarmAgroCultureActivitiesRequest,
    SelectFarmAgroCultureRequest,
    UnselectFarmAgroCultureActivitiesRequest,
    UpdateFarmAgroActivityRequest,
    UpdateFarmAgroCultureActivitiesRequest,
    UpdateFarmAgroCultureRequest,
    UpdateFarmAgroCulturesRequest,
    UpdateFarmRequest,
} from './FarmTypes'

export class FarmService {
    private _farmUrl = '/farms'
    private _farmSeasonsUrl = '/farms/seasons'
    private _farmAgroCulturesUrl = '/farms/agro-cultures'
    private _farmAgroActivitiesUrl = '/farms/agro-activities'

    public async getFarm() {
        const { data } = await httpClient.get<GetFarmResponse>(this._farmUrl)

        return data
    }

    public async getFarmDashboardData() {
        const { data } = await httpClient.get<GetFarmDashboardResponse>('/farms/dashboard')
        return data
    }

    public async createFarm(request: CreateFarmRequest) {
        return httpClient.post(this._farmUrl, request)
    }

    public async updateFarm(request: UpdateFarmRequest) {
        return httpClient.put(this._farmUrl, request)
    }

    public async getFarmAgroCultures() {
        const { data } = await httpClient.get<GetFarmAgroCulturesResponse>(
            this._farmAgroCulturesUrl,
        )

        return data.sort((a, b) => (!!a.iconPath === !!b.iconPath ? 0 : a?.iconPath ? -1 : 1))
    }

    public async getFarmAgroActivities() {
        const { data } = await httpClient.get<GetFarmAgroActivitiesResponse>(
            this._farmAgroActivitiesUrl,
        )

        return data
    }

    public async updateFarmAgroCultures(request: UpdateFarmAgroCulturesRequest) {
        return httpClient.put(this._farmAgroCulturesUrl, request)
    }

    public async createFarmAgroCulture(request: CreateFarmAgroCultureRequest) {
        return httpClient.post(this._farmAgroCulturesUrl, request)
    }

    public async getFarmFieldRegions() {
        const { data } = await httpClient.get<string[]>('/farms/fields/regions')
        return data
    }

    public async updateFarmAgroCulture({ id, name }: UpdateFarmAgroCultureRequest) {
        const requestUrl = `${this._farmAgroCulturesUrl}/${id}`

        return httpClient.put(requestUrl, { name })
    }

    public async deleteFarmAgroCulture({ id }: DeleteFarmAgroCultureRequest) {
        const requestUrl = `${this._farmAgroCulturesUrl}/${id}`

        return httpClient.delete(requestUrl)
    }

    public async updateFarmAgroCultureActivities({
        id,
        agroActivityIds,
    }: UpdateFarmAgroCultureActivitiesRequest) {
        const requestUrl = `${this._farmAgroCulturesUrl}/${id}/agro-activities`

        return httpClient.put(requestUrl, { agroActivityIds })
    }

    public async createFarmAgroActivity(request: CreateFarmAgroActivityRequest) {
        return httpClient.post(this._farmAgroActivitiesUrl, request)
    }

    public async updateFarmAgroActivity({ id, name }: UpdateFarmAgroActivityRequest) {
        return httpClient.put(`${this._farmAgroActivitiesUrl}/${id}`, { name })
    }

    public async selectFarmAgroCulture({ id }: SelectFarmAgroCultureRequest) {
        return httpClient.post(`${this._farmAgroCulturesUrl}/${id}`)
    }

    public async getFarmSeasons() {
        const { data } = await httpClient.get<GetFarmSeasonsResponse>(this._farmSeasonsUrl)

        return data
    }

    public async unselectFarmAgroCulture({ id }: SelectFarmAgroCultureRequest) {
        return httpClient.delete(`${this._farmAgroCulturesUrl}/${id}`)
    }

    public async getFarmAgroCultureActivities({
        agroCultureId,
    }: GetFarmAgroCultureActivitiesRequest) {
        const { data } = await httpClient.get<GetFarmAgroCultureActivitiesResponse>(
            `${this._farmAgroCulturesUrl}/${agroCultureId}/agro-activities`,
        )

        return data
    }

    public async selectFarmAgroCultureActivity({
        agroCultureId,
        agroActivityId,
    }: SelectFarmAgroCultureActivitiesRequest) {
        const { data } = await httpClient.put(
            `${this._farmAgroCulturesUrl}/${agroCultureId}/agro-activities/${agroActivityId}`,
        )

        return data
    }
    public async unselectFarmAgroCultureActivity({
        agroCultureId,
        agroActivityId,
        agroCultureActivityId,
    }: UnselectFarmAgroCultureActivitiesRequest) {
        const { data } = await httpClient.delete(
            `${this._farmAgroCulturesUrl}/${agroCultureId}/agro-activities/${agroActivityId}/${agroCultureActivityId}`,
        )

        return data
    }
}

export const farmService = new FarmService()
