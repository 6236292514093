import { ComponentProps, ReactNode, useState } from 'react'
import { Outlet, useMatch } from 'react-router-dom'
import * as Collapsible from '@radix-ui/react-collapsible'

import { useAuth } from 'app/state/AuthContext'

import { Dropdown } from 'components/Dropdown'

import { useLogoutMutation } from 'hooks/api'

import { Box, Image, NavLink, Text } from 'ui/Common'
import { styled } from 'ui/stitches.config'
import { GhostIconButton } from 'ui/Button'

import IconDashboard from 'assets/icons/nav_dashboard.svg'
import IconFields from 'assets/icons/nav_fields.svg'
import EratarLogo from 'assets/eratar_logo_white.png'
import EratarLogoFull from 'assets/eratar_logo.png'
import IconData from 'assets/icons/nav_data.svg'
import IconArrowUp from 'assets/icons/arrow_up.svg'
import IconLogout from 'assets/icons/logout.svg'

export const DashboardLayout = () => {
    const { user } = useAuth()
    const { mutateAsync: logout } = useLogoutMutation()
    return (
        <Box
            css={{
                'd': 'flex',
                'flexGrow': 1,
                '@maxMd': {
                    fd: 'column-reverse',
                    pb: '$10',
                },
            }}
        >
            <Box
                css={{
                    'd': 'flex',
                    'ai': 'center',
                    'gap': '$1-25',
                    'p': '$1',
                    'backgroundColor': '#0E7238',
                    'width': '100%',
                    'jc': 'center',
                    'overflow': 'hidden',
                    'position': 'fixed',
                    'bottom': 0,
                    'left': 0,
                    'right': 0,
                    'zIndex': 1,
                    '@minMd': {
                        display: 'none',
                    },
                }}
            >
                <NavigationItemMobile
                    to="/kontrolna-tabla"
                    end
                    icon={<Image src={IconDashboard} />}
                >
                    Početna
                </NavigationItemMobile>

                <NavigationItemMobile to="/kontrolna-tabla/njive" icon={<Image src={IconFields} />}>
                    Njive
                </NavigationItemMobile>
                <NavigationItemMobile to="/kontrolna-tabla/podaci" icon={<Image src={IconData} />}>
                    Podaci
                </NavigationItemMobile>
                <NavigationItemMobile
                    to="/kontrolna-tabla/katalozi"
                    icon={
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clipPath="url(#clip0_1943_32411)">
                                <path
                                    d="M12.5 5.83203V16.6404L8.99167 15.132L8.33333 14.8487L7.675 15.132L4.16667 16.6404V5.83203H12.5ZM15.8333 0.832031H7.49167C6.575 0.832031 5.83333 1.58203 5.83333 2.4987H14.1667C15.0833 2.4987 15.8333 3.2487 15.8333 4.16536V14.9987L17.5 15.832V2.4987C17.5 1.58203 16.75 0.832031 15.8333 0.832031ZM12.5 4.16536H4.16667C3.25 4.16536 2.5 4.91536 2.5 5.83203V19.1654L8.33333 16.6654L14.1667 19.1654V5.83203C14.1667 4.91536 13.4167 4.16536 12.5 4.16536Z"
                                    fill="white"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_1943_32411">
                                    <rect width="20" height="20" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    }
                >
                    Katalozi
                </NavigationItemMobile>
            </Box>

            <Box
                css={{
                    'd': 'flex',
                    'fd': 'column',
                    'maxWidth': '320px',
                    'width': '100%',
                    'bc': '#0E7238',
                    'p': '$2',
                    'gap': '$3',
                    'flexGrow': 1,
                    'maxHeight': '100vh',

                    '@maxMd': {
                        display: 'none',
                    },
                }}
            >
                <Box
                    css={{
                        p: '$1-25',
                    }}
                >
                    <Image src={EratarLogo} />
                </Box>
                <Box
                    css={{
                        d: 'flex',
                        fd: 'column',
                        gap: '$1',
                        fg: 1,
                    }}
                >
                    <NavigationItem to="/kontrolna-tabla" end icon={<Image src={IconDashboard} />}>
                        Kontrolna tabla
                    </NavigationItem>
                    <NavigationItem to="/kontrolna-tabla/njive" icon={<Image src={IconFields} />}>
                        Njive
                    </NavigationItem>
                    <CollapsibleNavigation
                        basePath="/kontrolna-tabla/podaci"
                        icon={<Image src={IconData} />}
                    >
                        Podaci
                    </CollapsibleNavigation>
                    {/* <NavigationItem
                        to="/kontrolna-tabla/kalkulatori"
                        icon={<Image src={IconCalculator} />}
                    >
                        Kalkulatori
                    </NavigationItem> */}
                    <NavigationItem
                        to="/kontrolna-tabla/katalozi"
                        end
                        isNewItem
                        icon={
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g clipPath="url(#clip0_1943_32411)">
                                    <path
                                        d="M12.5 5.83203V16.6404L8.99167 15.132L8.33333 14.8487L7.675 15.132L4.16667 16.6404V5.83203H12.5ZM15.8333 0.832031H7.49167C6.575 0.832031 5.83333 1.58203 5.83333 2.4987H14.1667C15.0833 2.4987 15.8333 3.2487 15.8333 4.16536V14.9987L17.5 15.832V2.4987C17.5 1.58203 16.75 0.832031 15.8333 0.832031ZM12.5 4.16536H4.16667C3.25 4.16536 2.5 4.91536 2.5 5.83203V19.1654L8.33333 16.6654L14.1667 19.1654V5.83203C14.1667 4.91536 13.4167 4.16536 12.5 4.16536Z"
                                        fill="white"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_1943_32411">
                                        <rect width="20" height="20" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        }
                    >
                        Katalozi
                    </NavigationItem>
                </Box>

                <Box
                    css={{
                        d: 'flex',
                        ai: 'center',
                        gap: '$1',
                    }}
                >
                    {/* Avatar */}
                    <Box
                        css={{
                            d: 'flex',
                            ai: 'center',
                            jc: 'center',
                            size: '$4',
                            bc: '#FBBF24',
                            color: '#2C3E50',
                            br: '$round',
                            fontSize: '14px',
                            fontWeight: 600,
                        }}
                    >
                        {!!user && `${user?.firstName?.[0] ?? ''}${user?.lastName?.[0] ?? ''}`}
                    </Box>
                    <Box css={{ d: 'flex', fd: 'column', fg: 1 }}>
                        <Text
                            css={{
                                fontWeight: 500,
                                fontSize: '16px',
                                color: '#fff',
                            }}
                        >{`${user?.firstName} ${user?.lastName}`}</Text>
                        <Text
                            css={{
                                fontWeight: 500,
                                fontSize: '12px',
                                color: 'rgba(255, 255, 255, 0.64)',
                            }}
                        >{`${user?.email}`}</Text>
                    </Box>

                    <GhostIconButton
                        onClick={() => {
                            logout()
                        }}
                    >
                        <Image src={IconLogout} />
                    </GhostIconButton>
                </Box>
            </Box>
            <Box
                css={{
                    'd': 'flex',
                    'flexGrow': 1,
                    'bc': '#F7F8FC',
                    'maxHeight': '100vh',
                    'overflowY': 'auto',
                    '@maxMd': {
                        pt: '56px',
                    },
                }}
            >
                <Outlet />
            </Box>
            <Box
                css={{
                    'd': 'flex',
                    'ai': 'center',
                    'jc': 'space-between',
                    'height': '56px',
                    'background': '#fff',
                    'width': '100%',
                    'px': '$2',
                    'py': '$1-5',
                    'zIndex': 1,
                    'position': 'fixed',
                    'top': 0,
                    '@minMd': {
                        display: 'none',
                    },
                }}
            >
                <Image src={EratarLogoFull} css={{ height: '32px' }} />
                <Dropdown
                    content={
                        <Box
                            css={{
                                background: '#fff',
                                br: '$0-5',
                                py: '$0-5',
                                border: '1px solid #E5E7EB',
                                d: 'flex',
                                fd: 'column',
                                mr: '$1',
                            }}
                        >
                            <Box
                                css={{
                                    width: '224px',
                                    d: 'flex',
                                    fd: 'column',
                                    px: '$2',
                                    py: '$1-25',
                                }}
                            >
                                <Text
                                    css={{
                                        fontSize: '16px',
                                        fontWeight: 500,
                                        color: '#4B5563',
                                    }}
                                >
                                    {`${user?.firstName} ${user?.lastName}`}
                                </Text>
                                <Text
                                    css={{
                                        fontSize: '12px',
                                        fontWeight: 500,
                                        color: '#4B5563',
                                    }}
                                >
                                    {user?.email}
                                </Text>
                            </Box>
                            <Box
                                css={{
                                    d: 'flex',
                                    ai: 'center',
                                    px: '$2',
                                    py: '$1-25',
                                    gap: '$1',
                                    borderTop: '1px solid #E5E7EB',
                                }}
                                onClick={() => {
                                    logout()
                                }}
                            >
                                <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g clipPath="url(#clip0_1754_18806)">
                                        <path
                                            d="M12.1133 8.16667H5.83325V7.83333H12.1133H13.3171L12.4675 6.98046L11.0997 5.60735L11.3333 5.37377L13.9595 8L11.3345 10.625L11.0991 10.3879L12.4668 9.02022L13.3204 8.16667H12.1133ZM2.66659 2.83333H2.16659V3.33333V12.6667V13.1667H2.66659H7.49992V13.5H2.66659C2.20939 13.5 1.83325 13.1239 1.83325 12.6667V3.33333C1.83325 2.87614 2.20939 2.5 2.66659 2.5H7.49992V2.83333H2.66659Z"
                                            fill="#323232"
                                            stroke="#DC2626"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1754_18806">
                                            <rect width="16" height="16" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <Text
                                    css={{
                                        color: '#DC2626',
                                        fontSize: '14px',
                                        fontWeight: 500,
                                    }}
                                >
                                    Izloguj se
                                </Text>
                            </Box>
                        </Box>
                    }
                >
                    <GhostIconButton
                        css={{
                            d: 'flex',
                            ai: 'center',
                            jc: 'center',
                            size: '$4',
                            bc: '#FBBF24',
                            color: '#2C3E50',
                            br: '$round',
                            fontSize: '14px',
                            fontWeight: 600,
                        }}
                    >
                        {!!user && `${user?.firstName?.[0] ?? ''}${user?.lastName?.[0] ?? ''}`}
                    </GhostIconButton>
                </Dropdown>
            </Box>
        </Box>
    )
}

export interface NavigationItemProps extends ComponentProps<typeof NavLink> {
    children: ReactNode
    icon?: ReactNode
    onActiveCallback?: () => void
    isNewItem?: boolean
}

export const NavigationItem = ({
    children,
    icon,
    onActiveCallback,
    isNewItem,
    ...navLinkprops
}: NavigationItemProps) => {
    return (
        <NavLink {...navLinkprops}>
            {({ isActive }) => {
                if (isActive) {
                    onActiveCallback?.()
                }
                return (
                    <Box
                        css={{
                            d: 'flex',
                            ai: 'center',
                            gap: '$1',
                            bc: isActive ? 'rgba(105, 185, 118, 0.12)' : 'transparent',
                            br: '$1',
                            p: '$1-5',
                            color: '#fff',
                        }}
                    >
                        <Box css={{ size: '20px' }}>{!!icon && icon}</Box>
                        {children}
                        {isNewItem && (
                            <Box css={{ d: 'flex', ai: 'center', jc: 'flex-end', fg: 1 }}>
                                <Box
                                    css={{
                                        d: 'flex',
                                        ai: 'center',
                                        jc: 'center',
                                        p: '$0-75',
                                        background: 'rgba(255,255,255, 0.14)',
                                        fontSize: '12px',
                                        fontWeight: 500,
                                        br: '$1',
                                    }}
                                >
                                    NOVO
                                </Box>
                            </Box>
                        )}
                    </Box>
                )
            }}
        </NavLink>
    )
}

export const NavigationItemMobile = ({
    children,
    icon,
    onActiveCallback,
    ...navLinkprops
}: NavigationItemProps) => {
    return (
        <NavLink
            {...navLinkprops}
            css={{
                fg: 1,
            }}
        >
            {({ isActive }) => {
                if (isActive) {
                    onActiveCallback?.()
                }
                return (
                    <Box
                        css={{
                            d: 'flex',
                            fd: 'column',
                            ai: 'center',
                            py: '$1',
                            br: '$0-5',
                            gap: '$0-75',
                            bc: isActive ? 'rgba(105, 185, 118, 0.12)' : 'transparent',
                        }}
                    >
                        <Box css={{ size: '20px' }}>{!!icon && icon}</Box>
                        <Text
                            css={{
                                fontSize: '10px',
                                color: '#ffffff',
                                minWidth: '60px',
                                textAlign: 'center',
                            }}
                        >
                            {children}
                        </Text>
                    </Box>
                )
            }}
        </NavLink>
    )
}

export interface CollapsibleNavigationProps {
    children: ReactNode
    icon?: ReactNode
    basePath: string
    items?: any[]
}
export const CollapsibleNavigation = ({ children, icon, basePath }: CollapsibleNavigationProps) => {
    const [open, setOpen] = useState(true)

    const isAnyRouteActive = useMatch({
        path: basePath,
        end: false,
    })
    return (
        <Collapsible.Root open={open} onOpenChange={setOpen}>
            <CollapsibleTrigger
                css={{
                    'd': 'flex',
                    'ai': 'center',
                    'gap': '$1',
                    'bc': !open && isAnyRouteActive ? 'rgba(105, 185, 118, 0.12)' : 'transparent',
                    'br': '$1',
                    'p': '$1-5',
                    'color': '#fff',
                    'cursor': 'pointer',
                    'width': '100%',
                    'fontSize': '16px',
                    '&:focus-visible': {
                        outline: ' -webkit-focus-ring-color auto 1px',
                    },
                }}
            >
                <Box css={{ size: '20px' }}>{!!icon && icon}</Box>
                <Box css={{ flexGrow: 1, textAlign: 'left' }}>{children}</Box>
                <Box css={{ size: '20px' }}>
                    <Image
                        src={IconArrowUp}
                        css={{
                            rotate: open ? '0deg' : '180deg',
                        }}
                    />
                </Box>
            </CollapsibleTrigger>

            <Collapsible.Content>
                <Box css={{ d: 'flex', fd: 'column' }}>
                    <NavigationItem to="/kontrolna-tabla/podaci/kulture">Kulture</NavigationItem>
                    <NavigationItem to="/kontrolna-tabla/podaci/operacije">
                        Operacije
                    </NavigationItem>
                    <NavigationItem to="/kontrolna-tabla/podaci/plodored">Plodored</NavigationItem>
                    <NavigationItem to="/kontrolna-tabla/podaci/gazdinstvo">
                        Gazdinstvo
                    </NavigationItem>
                </Box>
            </Collapsible.Content>
        </Collapsible.Root>
    )
}

const CollapsibleTrigger = styled(Collapsible.Trigger, {})
