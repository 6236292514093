import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import {
    ConfirmResetPasswordRequest,
    LoginRequest,
    RegisterRequest,
    ResetPasswordRequest,
    UpdateProfileRequest,
} from 'library/services/UsersTypes'
import { usersService } from 'library/services/UsersService'
import { areaMeasureUnitsService } from 'library/services/AreaMeasureUnitsService'
import { farmService } from 'library/services/FarmService'
import {
    CreateFarmAgroActivityRequest,
    CreateFarmAgroCultureRequest,
    CreateFarmRequest,
    SelectFarmAgroCultureActivitiesRequest,
    UnselectFarmAgroCultureActivitiesRequest,
    UpdateFarmAgroActivityRequest,
    UpdateFarmAgroCultureActivitiesRequest,
    UpdateFarmAgroCultureRequest,
    UpdateFarmRequest,
} from 'library/services/FarmTypes'
import { farmFieldService } from 'library/services/FarmFieldsService'
import {
    AddActivityToFieldRequest,
    CompleteFieldActivityRequest,
    CreateFarmFieldRequest,
    DeleteFarmFieldRequest,
    DeleteFieldActivityRequest,
    FinishFieldActivityRequest,
    GetAllFarmFieldActivitiesRequest,
    GetAllFarmFieldsRequest,
    GetCommonFieldActivitiesRequest,
    GetFarmFieldRequest,
    GetFieldSeasonsRequest,
    UpdateFarmFieldRequest,
} from 'library/services/FarmFieldsTypes'
import { agroCulturesService } from 'library/services/AgroCulturesService'
import {
    GetCatalogGroupCategoriesRequest,
    GetCatalogsRequest,
} from 'library/services/CatalogsServiceTypes'
import { catalogsService } from 'library/services/CatalogsService'

export enum QueryKey {
    FIELDS = 'FIELDS',
    FARM = 'FARM',
    AGRO_CULTURES = 'AGRO_CULTURES',
    AGRO_CULTURE_ACTIVITIES = 'AGRO_CULTURE_ACTIVITIES',
    AGRO_ACTIVITIES = 'AGRO_ACTIVITIES',
    AREA_MEASURE_UNITS = 'AREA_MEASURE_UNITS',
    CURRENT_USER = 'CURRENT_USER',
    FIELD_ACTIVITIES = 'FIELD_ACTIVITIES',
    FIELD_SEASONS = 'FIELD_SEASONS',
    FARM_SEASONS = 'FARM_SEASONS',
    FARM_DASHBOARD = 'FARM_DASHBOARD',
    FARM_FIELD_REGIONS = 'FARM_FIELD_REGIONS',
    CATALOG_GROUPS = 'CATALOG_GROUPS',
    CATALOG_GROUP_CATEGORIES = 'CATALOG_GROUP_CATEGORIES',
    CATALOGS = 'CATALOGS',
}

export const useLogoutMutation = () => {
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    return useMutation({
        mutationFn: () => {
            return usersService.logout()
        },
        onSuccess: () => {
            queryClient.invalidateQueries()
            queryClient.setQueryData(QueryKey.CURRENT_USER, undefined)
            navigate('/')
        },
    })
}
export const useDeleteCultureMutation = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({ cultureId }: { cultureId: number }) => {
            return agroCulturesService.deleteAgroCulture({ cultureId })
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: QueryKey.AGRO_CULTURES })
            queryClient.invalidateQueries({ queryKey: QueryKey.FARM_DASHBOARD })
        },
    })
}

export const useGetFarmDashboardData = () => {
    return useQuery({
        queryKey: QueryKey.FARM_DASHBOARD,
        queryFn: () => {
            return farmService.getFarmDashboardData()
        },
    })
}

export const useDeleteFieldMutation = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (data: DeleteFarmFieldRequest) => {
            return farmFieldService.deleteFarmField(data)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: QueryKey.FIELDS })
            queryClient.invalidateQueries({ queryKey: QueryKey.FARM_DASHBOARD })
        },
    })
}

export const useFarmSeasonsQuery = () => {
    return useQuery({
        queryKey: [QueryKey.FARM_SEASONS],
        staleTime: 1000 * 60 * 5,
        queryFn: () => {
            return farmService.getFarmSeasons()
        },
    })
}

export const useFieldsQuery = (queryParams: GetAllFarmFieldsRequest) => {
    return useQuery({
        queryKey: [QueryKey.FIELDS, ...Object.values(queryParams)],
        queryFn: () => {
            return farmFieldService.getAllFarmFields(queryParams)
        },
        staleTime: 1000 * 60 * 5,

        keepPreviousData: true,
    })
}

export const useCommonFieldActivitiesQuery = (request: GetCommonFieldActivitiesRequest) => {
    return useQuery({
        enabled: !!request.fieldIds.length,
        queryFn: () => {
            return farmFieldService.getCommonFieldActivities(request)
        },
    })
}

export const useFarmQuery = () => {
    return useQuery({
        queryKey: QueryKey.FARM,
        staleTime: 1000 * 60 * 60,
        queryFn: () => {
            return farmService.getFarm()
        },
    })
}

export const useUpdateFarmMutation = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (data: UpdateFarmRequest) => {
            return farmService.updateFarm(data)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: QueryKey.FARM })
        },
    })
}

export const useFieldQuery = (request: GetFarmFieldRequest) => {
    return useQuery({
        queryKey: [QueryKey.FIELDS, request.id],
        queryFn: () => {
            return farmFieldService.getFarmField(request)
        },
        staleTime: 1000 * 60 * 5,

        keepPreviousData: true,
    })
}

export const useFieldActivitiesQuery = (request: GetAllFarmFieldActivitiesRequest) => {
    return useQuery({
        enabled: !!request.id,
        queryKey: [QueryKey.FIELD_ACTIVITIES, request.id, request.completed],
        staleTime: 1000 * 60 * 5,
        queryFn: () => {
            return farmFieldService.getAllFarmFieldActivities(request)
        },
    })
}

export const useFieldSeasonsQuery = (request: GetFieldSeasonsRequest) => {
    return useQuery({
        enabled: !!request.id,
        queryKey: [QueryKey.FIELD_SEASONS, request.id],
        staleTime: 1000 * 60 * 5,
        queryFn: () => {
            return farmFieldService.getFieldSeasons(request)
        },
    })
}

export const useRegisterMutation = () => {
    return useMutation({
        mutationFn: (data: RegisterRequest) => {
            return usersService.register(data)
        },
    })
}

export const useAddFieldMutation = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (data: CreateFarmFieldRequest) => {
            return farmFieldService.createFarmField(data)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: QueryKey.FIELDS })
            queryClient.invalidateQueries({ queryKey: QueryKey.FARM_DASHBOARD })
            queryClient.invalidateQueries({ queryKey: QueryKey.AGRO_CULTURES })
        },
    })
}

export const useUpdateFieldMutation = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (data: UpdateFarmFieldRequest) => {
            return farmFieldService.updateFarmField(data)
        },
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({ queryKey: [QueryKey.FIELDS, variables.id] })
            queryClient.invalidateQueries({ queryKey: QueryKey.FARM_DASHBOARD })
            queryClient.invalidateQueries({ queryKey: QueryKey.FIELDS })
        },
    })
}

export const useConfirmRegistrationMutation = () => {
    return useMutation({
        mutationFn: (data: { registrationUUID: string }) => {
            return usersService.confirmRegistration(data)
        },
    })
}

export const useRequestPasswordResetMutation = () => {
    return useMutation({
        mutationFn: (data: ResetPasswordRequest) => {
            return usersService.requestResetPassword(data)
        },
    })
}

export const useConfirmPasswordResetMutation = () => {
    return useMutation({
        mutationFn: (data: ConfirmResetPasswordRequest) => {
            return usersService.confirmResetPassword(data)
        },
    })
}

export const useLoginMutation = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (data: LoginRequest) => {
            return usersService.login(data)
        },
        onSuccess: (data) => {
            queryClient.setQueryData(QueryKey.CURRENT_USER, data)
            // queryClient.invalidateQueries({ queryKey: QueryKey.CURRENT_USER })
        },
    })
}

export const useCreateFarmAgroCultureMutation = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (data: CreateFarmAgroCultureRequest) => {
            return farmService.createFarmAgroCulture(data)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: QueryKey.AGRO_CULTURES })
            queryClient.invalidateQueries({ queryKey: QueryKey.FARM_DASHBOARD })
        },
    })
}

export const useAgroCulturesQuery = () => {
    return useQuery({
        queryKey: QueryKey.AGRO_CULTURES,
        staleTime: 1000 * 60 * 5,
        queryFn: () => {
            return farmService.getFarmAgroCultures()
        },
    })
}

export const useAgroCultureActivitiesQuery = (id: number) => {
    return useQuery({
        enabled: !!id,
        queryKey: [QueryKey.AGRO_CULTURE_ACTIVITIES, id],
        staleTime: 1000 * 60 * 5,
        queryFn: () => {
            return farmService.getFarmAgroCultureActivities({ agroCultureId: id })
        },
    })
}

export const useSelectFarmAgroCultureActivityMutation = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (data: SelectFarmAgroCultureActivitiesRequest) => {
            return farmService.selectFarmAgroCultureActivity(data)
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries([
                QueryKey.AGRO_CULTURE_ACTIVITIES,
                variables.agroCultureId,
            ])
            // queryClient.setQueryData(QueryKey.AGRO_CULTURES, (prev: any) => {
            //     return prev?.map((item: any) => {
            //         if (item.id === variables.id) {
            //             item.selected = !item.selected
            //         }
            //         return item
            //     })
            // })
        },
    })
}

export const useFinishActivityMutation = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (request: FinishFieldActivityRequest) => {
            return farmFieldService.finishFieldActivity(request)
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries([QueryKey.FIELDS])
            queryClient.invalidateQueries([QueryKey.FIELD_ACTIVITIES, variables.fieldId, true])
            queryClient.invalidateQueries([QueryKey.FIELD_ACTIVITIES, variables.fieldId, false])
        },
    })
}

export const useAddActivityToFieldMutation = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (request: AddActivityToFieldRequest) => {
            return farmFieldService.addActivityToField(request)
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries([QueryKey.FIELD_ACTIVITIES, variables.fieldId, false])
            queryClient.invalidateQueries([QueryKey.FIELDS])
        },
    })
}

export const useDeleteActivityFromFieldMutation = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (request: DeleteFieldActivityRequest) => {
            return farmFieldService.deleteFieldActivity(request)
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries([QueryKey.FIELD_ACTIVITIES, variables.fieldId, false])
            queryClient.invalidateQueries([QueryKey.FIELD_ACTIVITIES, variables.fieldId, true])
        },
    })
}

export const useCompleteActivityMutation = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (request: CompleteFieldActivityRequest) => {
            return farmFieldService.completeFieldActivity(request)
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries([QueryKey.FIELDS])
            queryClient.invalidateQueries([QueryKey.FIELD_ACTIVITIES, variables.fieldId, true])
            queryClient.invalidateQueries([QueryKey.FIELD_ACTIVITIES, variables.fieldId, false])
        },
    })
}

export const useUnselectFarmAgroCultureActivityMutation = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (data: UnselectFarmAgroCultureActivitiesRequest) => {
            return farmService.unselectFarmAgroCultureActivity(data)
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries([
                QueryKey.AGRO_CULTURE_ACTIVITIES,
                variables.agroCultureId,
            ])
            // queryClient.setQueryData(QueryKey.AGRO_CULTURES, (prev: any) => {
            //     return prev?.map((item: any) => {
            //         if (item.id === variables.id) {
            //             item.selected = !item.selected
            //         }
            //         return item
            //     })
            // })
        },
    })
}

export const useSelectFarmAgroCultureMutation = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({ id, prevSelected }: { id: number; prevSelected: boolean }) => {
            if (prevSelected) {
                return farmService.unselectFarmAgroCulture({ id })
            } else {
                return farmService.selectFarmAgroCulture({ id })
            }
        },
        onSuccess: (data, variables) => {
            queryClient.setQueryData(QueryKey.AGRO_CULTURES, (prev: any) => {
                return prev?.map((item: any) => {
                    if (item.id === variables.id) {
                        item.selected = !item.selected
                    }
                    return item
                })
            })
            queryClient.invalidateQueries({ queryKey: QueryKey.FARM_DASHBOARD })
        },
    })
}

export const useAgroActivitiesQuery = () => {
    return useQuery({
        queryKey: QueryKey.AGRO_ACTIVITIES,
        staleTime: 1000 * 60 * 5,
        queryFn: () => {
            return farmService.getFarmAgroActivities()
        },
    })
}

export const useIncompleteActivitiesQuery = () => {
    return useQuery({
        queryFn: () => {
            return farmFieldService.getAllIncompleteActivities()
        },
    })
}

export const useFarmFieldRegionsQuery = () => {
    return useQuery({
        queryKey: QueryKey.FARM_FIELD_REGIONS,
        staleTime: 1000 * 60 * 5,
        queryFn: () => {
            return farmService.getFarmFieldRegions()
        },
    })
}

export const useCreateFarmAgroActivityMutation = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (data: CreateFarmAgroActivityRequest) => {
            return farmService.createFarmAgroActivity(data)
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries(QueryKey.AGRO_ACTIVITIES)
            // queryClient.setQueryData(QueryKey.AGRO_ACTIVITIES, (prev: any) => {
            //     return prev.map((item: any) => {
            //         if (item.id === 0) {
            //             return {
            //                 ...item,
            //                 items: [
            //                     ...item.items,
            //                     { id: Math.floor(Math.random() * 100 + 100), name: variables.name },
            //                 ],
            //             }
            //         }
            //         return item
            //     })
            // })
        },
    })
}

export const useUpdateFarmAgroCultureMutation = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (data: UpdateFarmAgroCultureRequest) => {
            return farmService.updateFarmAgroCulture(data)
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries(QueryKey.AGRO_CULTURES)
            // queryClient.setQueryData(QueryKey.AGRO_ACTIVITIES, (prev: any) => {
            //     return prev.map((item: any) => {
            //         if (item.id === 0) {
            //             return {
            //                 ...item,
            //                 items: [
            //                     ...item.items,
            //                     { id: Math.floor(Math.random() * 100 + 100), name: variables.name },
            //                 ],
            //             }
            //         }
            //         return item
            //     })
            // })
        },
    })
}

export const useUpdateFarmAgroActivityMutation = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (data: UpdateFarmAgroActivityRequest) => {
            return farmService.updateFarmAgroActivity(data)
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries(QueryKey.AGRO_ACTIVITIES)
            queryClient.invalidateQueries(QueryKey.AGRO_CULTURE_ACTIVITIES)
            // queryClient.setQueryData(QueryKey.AGRO_ACTIVITIES, (prev: any) => {
            //     return prev.map((item: any) => {
            //         if (item.id === 0) {
            //             return {
            //                 ...item,
            //                 items: [
            //                     ...item.items,
            //                     { id: Math.floor(Math.random() * 100 + 100), name: variables.name },
            //                 ],
            //             }
            //         }
            //         return item
            //     })
            // })
        },
    })
}

export const useAreaMeasureUnitsQuery = () => {
    return useQuery({
        queryKey: QueryKey.AREA_MEASURE_UNITS,
        queryFn: () => {
            return areaMeasureUnitsService.getAllAreaMeasureUnits()
        },
    })
}
export const useCatalogGroupsQuery = () => {
    return useQuery({
        queryKey: QueryKey.CATALOG_GROUPS,
        queryFn: () => {
            return catalogsService.getCatalogGroups()
        },
    })
}
export const useCatalogGroupCategoriesQuery = (request: GetCatalogGroupCategoriesRequest) => {
    return useQuery({
        queryKey: [QueryKey.CATALOG_GROUP_CATEGORIES, request.catalogGroupId],
        queryFn: () => {
            return catalogsService.getCatalogGroupCategories(request)
        },
        enabled: !!request.catalogGroupId,
    })
}
export const useCatalogsQuery = (request: GetCatalogsRequest) => {
    return useQuery({
        queryKey: [QueryKey.CATALOGS, request.catalogGroupId, request.catalogCategoryId],
        queryFn: () => {
            return catalogsService.getCatalogs(request)
        },
        enabled: !!request.catalogGroupId,
    })
}

export const useCreateFarmMutation = () => {
    return useMutation({
        mutationFn: (data: CreateFarmRequest) => {
            return farmService.createFarm(data)
        },
    })
}

export const useUpdateProfileMutation = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (data: UpdateProfileRequest) => {
            return usersService.updateProfile(data)
        },
        onSuccess: (data, variables) => {
            queryClient.setQueryData(QueryKey.CURRENT_USER, (prev: any) => ({
                ...prev,
                data: {
                    ...prev.data,
                    firstName: variables.firstName,
                    lastName: variables.lastName,
                },
            }))
        },
    })
}

export const useGetCurrentUserQuery = () => {
    return useQuery({
        queryKey: QueryKey.CURRENT_USER,
        queryFn: () => {
            return usersService.getCurrentUser()
        },
        retry: false,
    })
}

export const useUpdateAgroCultureActivitiesMutation = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (data: UpdateFarmAgroCultureActivitiesRequest) => {
            return farmService.updateFarmAgroCultureActivities(data)
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries([QueryKey.AGRO_CULTURE_ACTIVITIES, variables.id])
        },
    })
}
