import { AddFieldDialog } from 'components/AddFieldDialog'
import { FieldsTable } from 'components/FieldsTable'

import { Button } from 'ui/Button'
import { Box, Text } from 'ui/Common'
import { DashboardPageLayout } from 'ui/DashboardPageLayout'

export const FieldsPage = () => {
    return (
        <DashboardPageLayout noPadding>
            <Box
                css={{
                    d: 'flex',
                    gap: '$2',
                    jc: 'space-between',
                    ai: 'center',
                    px: '$2',
                    pt: '$2',
                }}
            >
                <Box
                    css={{
                        d: 'flex',
                        fd: 'column',
                        gap: '$1',
                    }}
                >
                    <Text
                        css={{
                            color: '#2C3E50',
                            fontWeight: 600,
                            fontSize: '24px',
                            lineHeight: '28px',
                        }}
                    >
                        Njive
                    </Text>
                    <Text
                        css={{
                            'color': 'rgba(0, 0, 0, 0.64)',
                            'fontWeight': 400,
                            'fontSize': '14px',
                            'lineHeight': '16.94px',
                            '@maxMd': {
                                display: 'none',
                            },
                        }}
                    >
                        Ispod se nalazi spisak njiva gazdinstva.
                    </Text>
                </Box>
                <AddFieldDialog>
                    <Button variant="cta">Dodaj njivu</Button>
                </AddFieldDialog>
            </Box>
            <FieldsTable />
        </DashboardPageLayout>
    )
}
