import * as Label from '@radix-ui/react-label'
import { useFormContext } from 'react-hook-form'
import { ReactNode } from 'react'
import get from 'lodash/get'

import { Box, Text } from 'ui/Common'
import { Input } from 'ui/Input'
import { CSS, styled } from 'ui/stitches.config'

export interface FormInputProps extends React.ComponentProps<typeof Input> {
    name: string
    label?: string
    labelHelpEl?: ReactNode
    containerCSS?: CSS
    labelContainerCSS?: CSS
    rightEl?: ReactNode
}

export const FormInput = ({
    name,
    label,
    labelHelpEl,
    containerCSS = {},
    labelContainerCSS = {},
    rightEl,
    ...inputProps
}: FormInputProps) => {
    const {
        register,
        formState: { errors },
    } = useFormContext()

    const error = get(errors, name)?.message?.toString()

    const borderColor = error ? '#EF4444' : 'transparent'

    return (
        <Box
            css={{
                d: 'flex',
                fd: 'column',
                gap: '$1',
                width: '100%',
                ...containerCSS,
            }}
        >
            {(!!label || !!labelHelpEl) && (
                <Box
                    css={{
                        d: 'flex',
                        jc: 'space-between',
                        ...labelContainerCSS,
                    }}
                >
                    {!!label && (
                        <LabelRoot htmlFor={name} css={{ fontSize: 12 }}>
                            {label}
                            {!!inputProps.required && '*'}
                        </LabelRoot>
                    )}
                    {!!labelHelpEl && labelHelpEl}
                </Box>
            )}
            <Box css={{ d: 'flex', position: 'relative', width: '100%' }}>
                <Input
                    id={name}
                    {...inputProps}
                    {...register(name)}
                    css={{
                        width: '100%',
                        ...inputProps.css,
                        border: error ? `1px solid ${borderColor}` : undefined,
                    }}
                    suppressContentEditableWarning={true}
                />
                {!!rightEl && (
                    <Box
                        css={{
                            position: 'absolute',
                            right: 12,
                            bottom: 'calc(50% - 12px)',
                            size: 24,
                            d: 'inline-flex',
                            ai: 'center',
                            jc: 'flex-end',
                            width: '60px',
                            float: 'right',
                        }}
                    >
                        <Box css={{ fg: 1 }} />
                        {rightEl}
                    </Box>
                )}
            </Box>
            {!!error && (
                <Text
                    css={{
                        color: '#EF4444',
                        fontSize: 14,
                        marginTop: '$0-25',
                    }}
                >
                    {error}
                </Text>
            )}
        </Box>
    )
}

const LabelRoot = styled(Label.Root, {
    fontSize: 14,
    lineHeight: '20px',
    color: 'rgba(44, 62, 80, 0.64)',
    userSelect: 'none',
})
