import { Navigate, Route, Routes } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Partytown } from '@builder.io/partytown/react'

import { useGlobalStyles } from 'hooks/useGlobalStyles'

import { AddFieldPage } from './pages/AddFieldPage'
import { CropRotationPage } from './pages/CropRotationPage'
import { CulturesPage } from './pages/CulturesPage'
import { DashboardLayout } from './pages/DashboardLayout'
import { DashboardPage } from './pages/DashboardPage'
import { EditCulturesPage } from './pages/EditCulturesPage'
import { EditActivitiesPage } from './pages/EditActivitiesPage'
import { FarmPage } from './pages/FarmPage'
import FieldDetailsPage from './pages/FieldDetailsPage'
import { FieldsPage } from './pages/FieldsPage'
import { LoginPage } from './pages/LoginPage'
import { OnboardingPage } from './pages/OnboardingPage'
import { ActivitiesPage } from './pages/ActivitiesPage'
import { RegisterPage } from './pages/RegisterPage'
import {
    AuthProvider,
    RequireAuth,
    RequireNoAuth,
    RequireNotOnboarded,
    RequireOnboarding,
} from './state/AuthContext'
import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'
import { ConfirmRegistrationPage } from './pages/ConfirmRegistrationPage'
import { ResetPasswordPage } from './pages/ResetPasswordPage'
import { UnknownPage } from './pages/UnknownPage'
import { CalculatorsPage } from './pages/CalculatorsPage'
import { DataPage } from './pages/DataPage'
import { RequestPasswordResetPage } from './pages/RequestPasswordResetPage'
import CatalogsPage from './pages/CatalogsPage'
import CatalogPage from './pages/CatalogPage'

function App() {
    useGlobalStyles()

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
            },
        },
    })

    return (
        <QueryClientProvider client={queryClient}>
            {window.location.hostname === 'beta.eratar.rs' && (
                <Partytown forward={['dataLayer.push']} />
            )}
            <AuthProvider>
                <Routes>
                    <Route path="/" element={<Navigate to="/prijava" />} />
                    <Route path="/potvrda-registracije/:id" element={<ConfirmRegistrationPage />} />
                    <Route
                        path="/promena-lozinke"
                        element={
                            <RequireNoAuth>
                                <RequestPasswordResetPage />
                            </RequireNoAuth>
                        }
                    />
                    <Route
                        path="/promena-lozinke/:id"
                        element={
                            <RequireNoAuth>
                                <ResetPasswordPage />
                            </RequireNoAuth>
                        }
                    />
                    <Route
                        path="/onboarding"
                        element={
                            <RequireAuth>
                                <RequireNotOnboarded>
                                    <OnboardingPage />
                                </RequireNotOnboarded>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/prijava"
                        element={
                            <RequireNoAuth>
                                <LoginPage />
                            </RequireNoAuth>
                        }
                    />
                    <Route
                        path="/registracija"
                        element={
                            <RequireNoAuth>
                                <RegisterPage />
                            </RequireNoAuth>
                        }
                    />
                    <Route
                        path="/kontrolna-tabla"
                        element={
                            <RequireAuth>
                                <RequireOnboarding>
                                    <DashboardLayout />
                                </RequireOnboarding>
                            </RequireAuth>
                        }
                    >
                        <Route index element={<DashboardPage />} />
                        <Route path="njive">
                            <Route index element={<FieldsPage />} />
                            <Route path="dodaj" element={<AddFieldPage />} />
                            <Route path=":id" element={<FieldDetailsPage />} />
                        </Route>
                        <Route path="podaci">
                            <Route index element={<DataPage />} />
                            <Route path="kulture">
                                <Route index element={<CulturesPage />} />
                                <Route path="izmeni" element={<EditCulturesPage />} />
                            </Route>
                            <Route path="operacije">
                                <Route index element={<ActivitiesPage />} />
                                <Route path="izmeni/:id" element={<EditActivitiesPage />} />
                            </Route>
                            <Route path="plodored" element={<CropRotationPage />} />
                            <Route path="gazdinstvo" element={<FarmPage />} />
                        </Route>
                        <Route path="kalkulatori" element={<CalculatorsPage />} />
                        <Route path="katalozi">
                            <Route index element={<CatalogsPage />} />
                            <Route path=":id" element={<CatalogPage />} />
                        </Route>
                    </Route>
                    <Route path="*" element={<UnknownPage />} />
                </Routes>
            </AuthProvider>
        </QueryClientProvider>
    )
}

export default App
