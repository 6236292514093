import { Link as RRLink, NavLink as RRNavLink } from 'react-router-dom'

import { styled } from './stitches.config'

export const Box = styled('div')
export const Label = styled('label')
export const Text = styled('p', {
    fontFamily: '$inter',
})
export const Link = styled(RRLink, {
    color: '#397BF1',
})

export const NavLink = styled(RRNavLink, {})

export const Image = styled('img')
export const Form = styled('form')
export const Table = styled('table')
export const Thead = styled('thead')
export const Tbody = styled('tbody')
export const Tr = styled('tr')
export const Th = styled('th')
export const Td = styled('td')
export const Ul = styled('ul')
export const Li = styled('li')
