import { ReactNode, useEffect, useMemo, useState } from 'react'

import {
    useAgroCulturesQuery,
    useFarmFieldRegionsQuery,
    useIncompleteActivitiesQuery,
} from 'hooks/api'

import { BaseButton, Button } from 'ui/Button'
import { Box, Image, Text } from 'ui/Common'
import { DialogBody, DialogTrigger, Dialog } from 'ui/Dialog'

import { ComboBox } from './ComboBox'

export const FilterDialog = ({
    children,
    onFilterChange,
    defaultFilterCulturesIds = [],
    defaultFilterUnfinishedActivityIds = [],
    defaultFilterRegions = [],
}: {
    children?: ReactNode
    onFilterChange: (newFilters: {
        filterCultureIds: number[]
        filterUnfinishedActivityIds: number[]
        filterRegions: string[]
    }) => void
    defaultFilterCulturesIds?: number[]
    defaultFilterUnfinishedActivityIds?: number[]
    defaultFilterRegions?: string[]
}) => {
    const [open, setOpen] = useState(false)
    const { data: cultures, refetch: refetchCultures } = useAgroCulturesQuery()
    const { data: activities, refetch: refetchActivities } = useIncompleteActivitiesQuery()
    const { data: regions, refetch: refetchRegions } = useFarmFieldRegionsQuery()
    const activeCultures = cultures ? cultures?.filter((culture) => culture.selected) : []
    const [filterCultureIds, setFilterCultureIds] = useState<number[]>(defaultFilterCulturesIds)
    const [filterUnfinishedActivityIds, setFilterUnfinishedActivityIds] = useState<number[]>(
        defaultFilterUnfinishedActivityIds,
    )

    const activityItems = useMemo(
        () =>
            activities?.map((a) => ({ value: a.id, label: a.name })).filter((p) => p.label !== ''),
        [activities],
    )

    const regionItems = useMemo(
        () => regions?.map((r) => ({ value: r, label: r })).filter((p) => p.label !== ''),
        [regions],
    )

    const [filterRegions, setFilterRegions] = useState<string[]>(defaultFilterRegions)

    const onFilterCulture = (cultureId: number) => {
        setFilterCultureIds((prev) =>
            prev.includes(cultureId) ? prev.filter((id) => id !== cultureId) : [...prev, cultureId],
        )
    }
    const defaultRegionItems = useMemo(
        () => defaultFilterRegions.map((r) => ({ value: r, label: r })),
        [defaultFilterRegions],
    )

    const defaultActivityItems = useMemo(
        () =>
            defaultFilterUnfinishedActivityIds
                .map((r) => {
                    const activity = activities?.find((a) => a.id === r)

                    if (!activity) return []
                    return {
                        value: activity?.id.toString(),
                        label: activity?.name,
                    }
                })
                .flat(),
        [defaultFilterUnfinishedActivityIds],
    )

    useEffect(() => {
        if (open) {
            refetchCultures()
            refetchActivities()
            refetchRegions()
        }
    }, [open])

    const onResetFilters = () => {
        onFilterChange({
            filterCultureIds: [],
            filterUnfinishedActivityIds: [],
            filterRegions: [],
        })
        setOpen(false)
    }
    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger
                asChild
                onClick={(e) => {
                    e.stopPropagation()
                    setOpen(true)
                }}
            >
                {children}
            </DialogTrigger>
            <DialogBody
                title="Filteri"
                contentCss={{
                    right: 0,
                    top: 0,
                    bottom: 0,
                    maxHeight: '100vh',
                    height: '100vh',
                    maxWidth: '512px',
                    left: 'unset',
                    transform: 'unset',
                    br: '0',
                    animation: 'unset',
                }}
            >
                {open && (
                    <>
                        <Box css={{ position: 'absolute', top: '$2', right: '$2' }}>
                            <Box css={{ d: 'flex', ai: 'center', gap: '$2' }}>
                                <Button
                                    variant="secondary"
                                    onClick={() => {
                                        setOpen(false)
                                    }}
                                >
                                    Izađi
                                </Button>
                                <Button
                                    onClick={() => {
                                        setOpen(false)
                                        onFilterChange({
                                            filterCultureIds,
                                            filterUnfinishedActivityIds,
                                            filterRegions,
                                        })
                                    }}
                                >
                                    Sačuvaj
                                </Button>
                            </Box>
                        </Box>

                        <Box
                            css={{
                                d: 'flex',
                                fd: 'column',
                                gap: '$3',
                            }}
                        >
                            <Box>
                                <Button onClick={onResetFilters} variant={'secondary'}>
                                    Poništi filtere
                                </Button>
                            </Box>
                            <Text
                                css={{
                                    fontWeight: 500,
                                    fontSize: '16px',
                                    color: '#374151',
                                }}
                            >
                                Kultura
                            </Text>
                            <Box
                                css={{
                                    d: 'flex',
                                    ai: 'center',
                                    gap: '$1',
                                    flexWrap: 'wrap',
                                }}
                            >
                                {activeCultures.map((culture) => (
                                    <BaseButton
                                        key={culture.id}
                                        css={{
                                            p: '$1',
                                            pr: '$1-5',
                                            border: filterCultureIds.includes(culture.id)
                                                ? '1px solid #53A551'
                                                : '1px solid #E4E5E7',
                                            fontSize: '16px',
                                            fontWeight: 500,
                                            d: 'flex',
                                            ai: 'center',
                                            gap: '$0-5',
                                            br: '$0-5',
                                        }}
                                        onClick={() => onFilterCulture(culture.id)}
                                    >
                                        <Image
                                            src={culture.iconPath}
                                            css={{
                                                size: 20,
                                            }}
                                        />
                                        {culture.name}
                                    </BaseButton>
                                ))}
                            </Box>
                            <Text
                                css={{
                                    fontWeight: 500,
                                    fontSize: '16px',
                                    color: '#374151',
                                }}
                            >
                                Akitvnost koja nije urađena
                            </Text>

                            <ComboBox
                                data={activityItems ?? []}
                                onSelectedChange={(selectedItems) => {
                                    setFilterUnfinishedActivityIds(
                                        selectedItems.map((s) => s.value),
                                    )
                                }}
                                placeholder="Ukucajte naziv aktivnosti"
                                defaultSelected={defaultActivityItems}
                                singleSelect
                            />

                            <Text
                                css={{
                                    fontWeight: 500,
                                    fontSize: '16px',
                                    color: '#374151',
                                }}
                            >
                                Potes
                            </Text>
                            <ComboBox
                                data={regionItems ?? []}
                                onSelectedChange={(selectedItems) => {
                                    setFilterRegions(selectedItems.map((s) => s.value))
                                }}
                                defaultSelected={defaultRegionItems}
                                placeholder="Ukucajte naziv potesa"
                            />
                        </Box>
                    </>
                )}
            </DialogBody>
        </Dialog>
    )
}
