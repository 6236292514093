import { ReactNode, useState } from 'react'

import { Dialog, DialogBody, DialogTrigger } from 'ui/Dialog'

import { AddActivities } from './EditActivities'

export interface AddActivitiesToCultureDialog {
    children: ReactNode
    cultureId: number
}

export const AddActivitiesToCultureDialog = ({
    children,
    cultureId,
}: AddActivitiesToCultureDialog) => {
    const [open, setOpen] = useState(false)
    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>{children}</DialogTrigger>
            <DialogBody title="Dodajte novu aktivnost" showCloseIcon>
                <AddActivities cultureId={cultureId} />
            </DialogBody>
        </Dialog>
    )
}
