import { EditFarmDialog } from 'components/EditFarmDialog'

import { useFarmQuery } from 'hooks/api'

import { Button } from 'ui/Button'
import { Box, Text } from 'ui/Common'
import { DashboardPageLayout } from 'ui/DashboardPageLayout'

export const FarmPage = () => {
    const { data: farm } = useFarmQuery()

    return (
        <DashboardPageLayout>
            <Box
                css={{
                    d: 'flex',
                    gap: '$2',
                    jc: 'space-between',
                    ai: 'center',
                }}
            >
                <Box
                    css={{
                        d: 'flex',
                        fd: 'column',
                        gap: '$1',
                    }}
                >
                    <Text
                        css={{
                            color: '#2C3E50',
                            fontWeight: 600,
                            fontSize: '24px',
                            lineHeight: '28px',
                        }}
                    >
                        Gazdinstvo
                    </Text>
                    <Text
                        css={{
                            color: 'rgba(0, 0, 0, 0.64)',
                            fontWeight: 400,
                            fontSize: '14px',
                            lineHeight: '16.94px',
                        }}
                    >
                        Ispod se nalaze podaci o gazdinstvu.
                    </Text>
                </Box>

                <EditFarmDialog farm={farm!}>
                    <Button variant="cta">Izmeni podatke</Button>
                </EditFarmDialog>
            </Box>
            <Box
                css={{
                    'd': 'grid',
                    'gap': '$3',
                    'gridTemplateColumns': '1fr 1fr 1fr',
                    '@maxLg': {
                        gridTemplateColumns: '1fr 1fr',
                    },
                    '@maxSm': {
                        gridTemplateColumns: '1fr',
                    },
                }}
            >
                <Box
                    css={{
                        background: '#fff',
                        fg: 1,
                        br: '$1',
                        p: '$2',
                        d: 'flex',
                        fd: 'column',
                        gap: '$1-25',
                    }}
                >
                    <Text
                        css={{
                            fontSize: '24px',
                            fontWeight: 600,
                        }}
                    >
                        {farm?.name}
                    </Text>
                    <Text
                        css={{
                            fontSize: '14px',
                            fontWeight: 400,
                            color: 'rgba(0, 0, 0, 0.64)',
                        }}
                    >
                        Naziv gazdinstva
                    </Text>
                </Box>

                <Box
                    css={{
                        background: '#fff',
                        fg: 1,
                        br: '$1',
                        p: '$2',
                        d: 'flex',
                        fd: 'column',
                        gap: '$1-25',
                    }}
                >
                    <Text
                        css={{
                            fontSize: '24px',
                            fontWeight: 600,
                        }}
                    >
                        {farm?.location}
                    </Text>
                    <Text
                        css={{
                            fontSize: '14px',
                            fontWeight: 400,
                            color: 'rgba(0, 0, 0, 0.64)',
                        }}
                    >
                        Lokacija gazdinstva
                    </Text>
                </Box>

                <Box
                    css={{
                        background: '#fff',
                        fg: 1,
                        br: '$1',
                        p: '$2',
                        d: 'flex',
                        fd: 'column',
                        gap: '$1-25',
                    }}
                >
                    <Text
                        css={{
                            fontSize: '24px',
                            fontWeight: 600,
                        }}
                    >
                        {farm?.areaMeasureUnit.abbreviation}
                    </Text>
                    <Text
                        css={{
                            fontSize: '14px',
                            fontWeight: 400,
                            color: 'rgba(0, 0, 0, 0.64)',
                        }}
                    >
                        Merna jedinica
                    </Text>
                </Box>
            </Box>
        </DashboardPageLayout>
    )
}
