import { httpClient } from 'library/http'

import type {
    AddActivityToFieldRequest,
    CompleteFieldActivityRequest,
    CreateFarmFieldRequest,
    DeleteFarmFieldRequest,
    DeleteFieldActivityRequest,
    FinishFieldActivityRequest,
    GetAllFarmFieldActivitiesRequest,
    GetAllFarmFieldActivitiesResponse,
    GetAllFarmFieldsRequest,
    GetAllFarmFieldsResponse,
    GetCommonFieldActivitiesRequest,
    GetCommonFieldActivitiesResponse,
    GetFarmFieldRequest,
    GetFarmFieldResponse,
    GetFieldSeasonsRequest,
    GetFieldSeasonsResponse,
    UpdateFarmFieldRequest,
} from './FarmFieldsTypes'

export class FarmFieldService {
    private _farmFieldsUrl = '/farms/fields'

    public async createFarmField(request: CreateFarmFieldRequest) {
        return httpClient.post(this._farmFieldsUrl, request)
    }

    public async getAllFarmFields({
        nextActivityFilter,
        agroCultureFilter,
        regionFilter,
        ...request
    }: GetAllFarmFieldsRequest) {
        const requestUrl = `${this._farmFieldsUrl}`

        const initialParams = new URLSearchParams({
            order_by: request.orderBy,
            offset: request.offset.toString(),
            limit: request.limit.toString(),
            filter_by_name: request.filterByName ?? '',
            order_type: request.orderType,
        })

        const regionParams = regionFilter?.length
            ? `region=${regionFilter?.join('&region=') ?? ''}`
            : ''
        const cultureParams = agroCultureFilter?.length
            ? `agro_culture_id=${agroCultureFilter?.join('&agro_culture_id=') ?? ''}`
            : ''
        const nextActivityParams = nextActivityFilter?.length
            ? `incomplete_activity_id=${nextActivityFilter?.join('&incomplete_activity_id=') ?? ''}`
            : ''

        const seasonParams = request.seasonId
            ? `season_id=${request.seasonId?.toString() ?? ''}`
            : ''
        const completeParams = `${initialParams.toString()}${
            regionParams ? `&${regionParams}` : ''
        }${cultureParams ? `&${cultureParams}` : ''}${
            nextActivityParams ? `&${nextActivityParams}` : ''
        }${seasonParams ? `&${seasonParams}` : ''}`

        const { data } = await httpClient.get<GetAllFarmFieldsResponse>(
            `${requestUrl}?${completeParams}`,
        )
        return data
    }

    public async getFarmField({ id }: GetFarmFieldRequest) {
        const requestUrl = `${this._farmFieldsUrl}/${id}`

        const { data } = await httpClient.get<GetFarmFieldResponse>(requestUrl)

        return data
    }

    public async updateFarmField({ id, ...request }: UpdateFarmFieldRequest) {
        const requestUrl = `${this._farmFieldsUrl}/${id}`

        return httpClient.put(requestUrl, request)
    }

    public async deleteFarmField({ id }: DeleteFarmFieldRequest) {
        const requestUrl = `${this._farmFieldsUrl}/${id}`

        return httpClient.delete(requestUrl)
    }

    public async getAllFarmFieldActivities({ id, completed }: GetAllFarmFieldActivitiesRequest) {
        const requestUrl = `${this._farmFieldsUrl}/${id}/activities`
        const { data } = await httpClient.get<GetAllFarmFieldActivitiesResponse>(requestUrl, {
            params: {
                completed:
                    typeof completed !== 'undefined' ? (completed ? 'true' : 'false') : undefined,
            },
        })
        return data
    }

    public async addActivityToField({ fieldId, agroActivityIds }: AddActivityToFieldRequest) {
        const requestUrl = `${this._farmFieldsUrl}/${fieldId}/activities`

        return httpClient.post(requestUrl, {
            agroActivityIds,
        })
    }

    public async getFieldSeasons({ id }: GetFieldSeasonsRequest) {
        const requestUrl = `${this._farmFieldsUrl}/${id}/seasons`
        const { data } = await httpClient.get<GetFieldSeasonsResponse>(requestUrl)

        return data
    }

    public async getCommonFieldActivities(request: GetCommonFieldActivitiesRequest) {
        const requestUrl = `${this._farmFieldsUrl}/common-agro-activities`
        const { data } = await httpClient.post<GetCommonFieldActivitiesResponse>(
            requestUrl,
            request,
        )

        return data
    }

    public async finishFieldActivity({
        fieldId,
        fieldSeasonActivityId,
        notes,
        startedAt,
        endedAt,
        fuelConsumption,
        expenses,
    }: FinishFieldActivityRequest) {
        const requestUrl = `${this._farmFieldsUrl}/${fieldId}/activities/${fieldSeasonActivityId}`

        const { data } = await httpClient.put(requestUrl, {
            notes,
            startedAt,
            endedAt,
            fuelConsumption,
            expenses,
        })

        return data
    }

    public async deleteFieldActivity({
        fieldId,
        fieldSeasonActivityId,
    }: DeleteFieldActivityRequest) {
        const requestUrl = `${this._farmFieldsUrl}/${fieldId}/activities/${fieldSeasonActivityId}`

        const { data } = await httpClient.delete(requestUrl)

        return data
    }

    public async completeFieldActivity({
        fieldId,
        agroActivityId,
        ...rest
    }: CompleteFieldActivityRequest) {
        const requestUrl = `${this._farmFieldsUrl}/${fieldId}/activities/${agroActivityId}/complete`

        const { data } = await httpClient.patch(requestUrl, { ...rest })

        return data
    }

    public async getAllIncompleteActivities() {
        const requestUrl = `${this._farmFieldsUrl}/get-incomplete-activities`

        const { data } = await httpClient.get<{ id: number; name: string }[]>(requestUrl)

        return data
    }
}

export const farmFieldService = new FarmFieldService()
