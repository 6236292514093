import { z } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { ReactNode, useEffect, useState } from 'react'
import { t } from 'i18next'

import { FormInput } from 'components/FormInput'

import { useCreateFarmAgroActivityMutation } from 'hooks/api'

import { Button } from 'ui/Button'
import { Box, Form } from 'ui/Common'
import { DialogBody, DialogClose, DialogTrigger, Dialog } from 'ui/Dialog'

export const AddActivityDialog = ({ children }: { children?: ReactNode }) => {
    const [open, setOpen] = useState(false)
    const { mutateAsync, isLoading, error } = useCreateFarmAgroActivityMutation()

    const loginSchema = z.object({
        name: z.string().min(2, t('validation:activityName.min')),
    })

    type loginValues = z.infer<typeof loginSchema>

    const form = useForm({
        resolver: zodResolver(loginSchema),
        defaultValues: { name: '' },
    })

    const handleSubmit = async (values: loginValues) => {
        try {
            const response = await mutateAsync({ name: values.name })
            setOpen(false)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        form.reset()
    }, [open])

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>{children}</DialogTrigger>
            <DialogBody title="Dodajte novu aktivnost">
                <FormProvider {...form}>
                    <Form
                        css={{
                            d: 'flex',
                            fd: 'column',
                            gap: '$3',
                        }}
                        onSubmit={form.handleSubmit(handleSubmit)}
                    >
                        <FormInput name="name" placeholder="Unesite naziv nove aktivnosti" />

                        <Box
                            css={{
                                d: 'flex',
                                ai: 'center',
                                jc: 'flex-end',
                                gap: '$2',
                            }}
                        >
                            <DialogClose asChild>
                                <Button variant="secondary" type="button">
                                    Izađi
                                </Button>
                            </DialogClose>
                            <Button
                                type="submit"
                                isLoading={isLoading}
                                disabled={isLoading || !form.formState.isDirty}
                            >
                                Dodaj
                            </Button>
                        </Box>
                    </Form>
                </FormProvider>
            </DialogBody>
        </Dialog>
    )
}
