import { Box, Text } from 'ui/Common'
import { DashboardPageLayout } from 'ui/DashboardPageLayout'

export const CropRotationPage = () => {
    return (
        <DashboardPageLayout>
            <Box
                css={{
                    d: 'flex',
                    gap: '$2',
                    jc: 'space-between',
                    ai: 'center',
                }}
            >
                <Box
                    css={{
                        d: 'flex',
                        fd: 'column',
                        gap: '$1',
                    }}
                >
                    <Text
                        css={{
                            color: '#2C3E50',
                            fontWeight: 600,
                            fontSize: '24px',
                            lineHeight: '28px',
                        }}
                    >
                        Plodored
                    </Text>
                    <Text
                        css={{
                            color: 'rgba(0, 0, 0, 0.64)',
                            fontWeight: 400,
                            fontSize: '14px',
                            lineHeight: '16.94px',
                        }}
                    >
                        Napravite plodored kaka je najčešći na vašem gazdinstvu, možete ih svakako
                        izmeniti u svakom trenutku.
                    </Text>
                </Box>
            </Box>
            <PlaceholderScreen />
        </DashboardPageLayout>
    )
}

export const PlaceholderScreen = () => (
    <Box
        css={{
            d: 'flex',
            fg: 1,
            jc: 'center',
            ai: 'center',
        }}
    >
        <Box
            css={{
                d: 'flex',
                fd: 'column',
                ai: 'center',
                background: '#ffffff',
                maxWidth: '500px',
                width: '100%',
                p: '$2',
            }}
        >
            <Text css={{ fontSize: 70 }}>🧱</Text>
            <Text
                css={{
                    fontSize: 24,
                    fontWeight: 600,
                }}
            >
                USKORO
            </Text>
            <Text
                css={{
                    fontSize: 14,
                    textAlign: 'center',
                }}
            >
                Trenutno razvijamo ovu funkcionalnost, obavestićemo te kada bude dostupna
            </Text>
        </Box>
    </Box>
)
