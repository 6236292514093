import * as AspectRatio from '@radix-ui/react-aspect-ratio'

import { GetCatalogsResponseItem } from 'library/services/CatalogsServiceTypes'

import { Image } from 'ui/Common'

interface CatalogPreviewProps {
    catalog: GetCatalogsResponseItem
}

export const CatalogPreview = ({ catalog }: CatalogPreviewProps) => {
    return (
        <a
            href={catalog.filePath}
            // state={{
            //     id: catalog.id,
            //     name: catalog.name,
            //     filePath: catalog.filePath,
            // }}
            target="_blank"
        >
            <AspectRatio.Root ratio={1.414}>
                <Image
                    src={catalog.thumbnailImgPath}
                    css={{ maxWidth: '100%', overflow: 'hidden' }}
                />
            </AspectRatio.Root>
        </a>
    )
}
