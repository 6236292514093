import { useLocation, useNavigate } from 'react-router-dom'
import { z } from 'zod'
import { t } from 'i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { trimString } from 'library/utils'

import { FormInput } from 'components/FormInput'

import { useRequestPasswordResetMutation } from 'hooks/api'

import { Button } from 'ui/Button'
import { Box, Form, Image, Text } from 'ui/Common'

import EratarLogo from 'assets/eratar_logo.png'

export const RequestPasswordResetPage = () => {
    const { state } = useLocation()
    const navigate = useNavigate()

    const { mutateAsync, isLoading, error } = useRequestPasswordResetMutation()

    const requestPasswordResetSchema = z.object({
        email: z.preprocess(trimString, z.string().email(t('validation:email.format'))),
    })

    type requestPasswordResetValues = z.infer<typeof requestPasswordResetSchema>

    const form = useForm({
        resolver: zodResolver(requestPasswordResetSchema),
        defaultValues: { email: '', password: '' },
    })

    const handleSubmit = async (values: requestPasswordResetValues) => {
        try {
            const response = await mutateAsync({ email: values.email })
            if (response.status === 200) {
                // display message for password reset
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <FormProvider {...form}>
            <Form
                css={{
                    width: '100%',
                    height: '100%',
                    d: 'flex',
                    ai: 'center',
                    jc: 'center',
                    fd: 'column',
                    flexGrow: 1,
                    gap: '$5',
                    px: '$2',
                }}
                onSubmit={form.handleSubmit(handleSubmit)}
            >
                <Image src={EratarLogo} />

                {state?.redirected && (
                    <Box>
                        <Text>Uspšeno ste potvrdili registraciju! Ulogujte se na formi ispod!</Text>
                    </Box>
                )}
                <Box
                    css={{
                        maxWidth: '584px',
                        width: '100%',
                        br: '$1',
                        bc: '#ffffff',
                        border: '1px solid #E4E5E7',
                        d: 'flex',
                        fd: 'column',
                        gap: '$4',
                        py: '$8',
                        px: '$5',
                        ai: 'center',
                        margin: '0 auto',
                    }}
                >
                    <>
                        <Box
                            css={{
                                d: 'flex',
                                fd: 'column',
                                gap: '$0-5',
                                textAlign: 'center',
                            }}
                        >
                            <Text
                                as="h1"
                                css={{
                                    fontWeight: 600,
                                    fontSize: '20px',
                                    lineHeight: '24px',
                                }}
                            >
                                Resetuj lozinku
                            </Text>
                            <Text
                                as="h2"
                                css={{
                                    fontWeight: 400,
                                    lineHeight: '17px',
                                    fontSize: '14px',
                                }}
                            >
                                Unesite email
                            </Text>
                        </Box>

                        <FormInput
                            name="email"
                            label="Email"
                            placeholder="youremail@comp.com"
                            required
                            autoComplete="email"
                        />

                        <Button
                            css={{ width: '100%' }}
                            type="submit"
                            disabled={form.formState.isSubmitting}
                            isLoading={form.formState.isSubmitting}
                        >
                            Resetuj lozinku
                        </Button>

                        {form.formState.isSubmitSuccessful && (
                            <Box
                                css={{
                                    d: 'flex',
                                    ai: 'flex-start',
                                    borderLeft: '4px solid #53A551',
                                    background: 'rgba(83, 165, 81, 0.08)',
                                    width: '100%',
                                    px: '$2',
                                    py: '$1-25',
                                    gap: '$1-25',
                                }}
                            >
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g clipPath="url(#clip0_1118_26764)">
                                        <path
                                            d="M10.0001 1.66699C5.40008 1.66699 1.66675 5.40033 1.66675 10.0003C1.66675 14.6003 5.40008 18.3337 10.0001 18.3337C14.6001 18.3337 18.3334 14.6003 18.3334 10.0003C18.3334 5.40033 14.6001 1.66699 10.0001 1.66699ZM8.33342 14.167L4.16675 10.0003L5.34175 8.82533L8.33342 11.8087L14.6584 5.48366L15.8334 6.66699L8.33342 14.167Z"
                                            fill="#53A551"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1118_26764">
                                            <rect width="20" height="20" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>

                                <Box css={{ d: 'flex', fd: 'column', gap: '$0-5' }}>
                                    <Text
                                        css={{
                                            fontWeight: 600,
                                            fontSize: '20px',
                                            color: '#374151',
                                        }}
                                    >
                                        Lozinka je uspešno resetovana, proverite Vaš email.
                                    </Text>
                                    <Text
                                        css={{
                                            fontWeight: 400,
                                            fontSize: '16px',
                                            color: '#6B7280',
                                        }}
                                    >
                                        Ukoliko Vam ništa nije stiglo, proverite SPAM folder.
                                    </Text>
                                </Box>
                            </Box>
                        )}
                    </>
                </Box>
            </Form>
        </FormProvider>
    )
}
