import { useCallback } from 'react'

import { Box } from 'ui/Common'
import { ProgressBar } from 'ui/ProgressBar'

import { OnboardingCultures } from './OnboardingSteps/OnboardingCultures'
import { OnboardingActivities } from './OnboardingSteps/OnboardingActivities'
import {
    OnboardingStateProvider,
    Step,
    useOnboardingState,
} from './OnboardingSteps/OnboardingState'
import { OnboardingFarm } from './OnboardingSteps/OnboardingFarm'

export const OnboardingPage = () => (
    <OnboardingStateProvider>
        <OnboardingPageLayout />
    </OnboardingStateProvider>
)

const OnboardingPageLayout = () => {
    const { step, progress } = useOnboardingState()

    const renderStep = useCallback(() => {
        switch (step) {
            case Step.CULTURES:
                return <OnboardingCultures />
            case Step.ACTIVITIES:
                return <OnboardingActivities />
            case Step.FARM:
                return <OnboardingFarm />
            default:
                return null
        }
    }, [step])

    return (
        <Box css={{ d: 'flex', fd: 'column', minHeight: '100vh' }}>
            <Box
                css={{
                    d: 'flex',
                    fd: 'column',
                    maxWidth: '927px',
                    width: '100%',
                    margin: '0 auto',
                    flexGrow: 1,
                    pb: '$10',
                }}
            >
                <Box
                    css={{
                        position: 'fixed',
                        top: 0,
                        overflow: 'hidden',
                        margin: '0 auto',
                        maxWidth: '927px',
                        width: '100%',
                    }}
                >
                    <ProgressBar progress={progress} />
                </Box>
                {renderStep()}
            </Box>
        </Box>
    )
}
