import { Box, Link, Text } from 'ui/Common'

export const DataPage = () => {
    return (
        <Box
            css={{
                d: 'flex',
                fd: 'column',
                width: '100%',
                gap: '$2',
                p: '$2',
            }}
        >
            <Text
                css={{
                    mt: '$2',
                    mb: '$1',
                    fontWeight: 600,
                    fontSize: '20px',
                    color: '#2C3E50',
                }}
            >
                Podaci
            </Text>
            <Box
                css={{
                    d: 'flex',
                    ai: 'center',
                    jc: 'space-between',
                    background: '#fff',
                    p: '$2',
                    width: '100%',
                    border: '1px solid #D1D5DB',
                    br: '$1',
                }}
            >
                <Text
                    css={{
                        fontSize: '16px',
                        fontWeight: 500,
                        color: '#2C3E50',
                    }}
                >
                    Kulture
                </Text>
                <Link
                    to="/kontrolna-tabla/podaci/kulture"
                    css={{
                        color: '#53A551',
                        fontSize: '16px',
                        fontWeight: 600,
                        d: 'flex',
                        ai: 'center',
                    }}
                >
                    Detaljnije
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clipPath="url(#clip0_1620_30612)">
                            <path
                                d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z"
                                fill="#53A551"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_1620_30612">
                                <rect width="24" height="24" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </Link>
            </Box>
            <Box
                css={{
                    d: 'flex',
                    ai: 'center',
                    jc: 'space-between',
                    background: '#fff',
                    p: '$2',
                    width: '100%',
                    border: '1px solid #D1D5DB',
                    br: '$1',
                }}
            >
                <Text
                    css={{
                        fontSize: '16px',
                        fontWeight: 500,
                        color: '#2C3E50',
                    }}
                >
                    Operacije
                </Text>
                <Link
                    to="/kontrolna-tabla/podaci/operacije"
                    css={{
                        color: '#53A551',
                        fontSize: '16px',
                        fontWeight: 600,
                        d: 'flex',
                        ai: 'center',
                    }}
                >
                    Detaljnije
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clipPath="url(#clip0_1620_30612)">
                            <path
                                d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z"
                                fill="#53A551"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_1620_30612">
                                <rect width="24" height="24" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </Link>
            </Box>
            <Box
                css={{
                    d: 'flex',
                    ai: 'center',
                    jc: 'space-between',
                    background: '#fff',
                    p: '$2',
                    width: '100%',
                    border: '1px solid #D1D5DB',
                    br: '$1',
                }}
            >
                <Text
                    css={{
                        fontSize: '16px',
                        fontWeight: 500,
                        color: '#2C3E50',
                    }}
                >
                    Plodored
                </Text>
                <Box
                    css={{
                        color: '#53A551',
                        fontSize: '12px',
                        fontWeight: 500,
                        px: '$1-5',
                        py: '$0-5',
                        br: '$2',
                        background: 'rgba(83, 165, 81, 0.12)',
                    }}
                >
                    USKORO
                </Box>
            </Box>
            <Box
                css={{
                    d: 'flex',
                    ai: 'center',
                    jc: 'space-between',
                    background: '#fff',
                    p: '$2',
                    width: '100%',
                    border: '1px solid #D1D5DB',
                    br: '$1',
                }}
            >
                <Text
                    css={{
                        fontSize: '16px',
                        fontWeight: 500,
                        color: '#2C3E50',
                    }}
                >
                    Gazdinstvo
                </Text>
                <Link
                    to="/kontrolna-tabla/podaci/gazdinstvo"
                    css={{
                        color: '#53A551',
                        fontSize: '16px',
                        fontWeight: 600,
                        d: 'flex',
                        ai: 'center',
                    }}
                >
                    Detaljnije
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clipPath="url(#clip0_1620_30612)">
                            <path
                                d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z"
                                fill="#53A551"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_1620_30612">
                                <rect width="24" height="24" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </Link>
            </Box>
        </Box>
    )
}
