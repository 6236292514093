import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import rsCommon from './languages/rs/common.json'
import rsValidation from './languages/rs/validation.json'

export const resources = {
    rs: {
        common: rsCommon,
        validation: rsValidation,
    },
}
export const supportedLngs = Object.keys(resources)
export const defaultLanguage = 'rs'

export const ns = Object.keys(resources['rs'])

i18n.use(initReactI18next).init({
    fallbackLng: defaultLanguage,
    lng: defaultLanguage,
    supportedLngs,
    ns,
    resources,
})
