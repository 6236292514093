import { Link } from 'react-router-dom'

import { useAgroCulturesQuery } from 'hooks/api'

import { Button } from 'ui/Button'
import { Box, Image, Text } from 'ui/Common'
import { DashboardPageLayout } from 'ui/DashboardPageLayout'

export const ActivitiesPage = () => {
    const { data } = useAgroCulturesQuery()

    const filteredCultures = data ? data?.filter((culture) => culture.selected) : []

    return (
        <DashboardPageLayout>
            <Box
                css={{
                    d: 'flex',
                    gap: '$2',
                    jc: 'space-between',
                    ai: 'center',
                }}
            >
                <Box
                    css={{
                        d: 'flex',
                        fd: 'column',
                        gap: '$1',
                    }}
                >
                    <Text
                        css={{
                            color: '#2C3E50',
                            fontWeight: 600,
                            fontSize: '24px',
                            lineHeight: '28px',
                        }}
                    >
                        Operacije
                    </Text>
                    <Text
                        css={{
                            color: 'rgba(0, 0, 0, 0.64)',
                            fontWeight: 400,
                            fontSize: '14px',
                            lineHeight: '16.94px',
                        }}
                    >
                        Ispod se nalaze operacije za svaku kulturu, možete ih izmeniti u svakom
                        trenutku.
                    </Text>
                </Box>
            </Box>
            {filteredCultures && filteredCultures.length ? (
                <Box css={{ d: 'flex', fd: 'column', gap: '$2' }}>
                    {filteredCultures.map((culture) => (
                        <Box
                            css={{
                                d: 'flex',
                                ai: 'center',
                                gap: '$1-5',
                                background: '#fff',
                                p: '$2',
                                br: '$1',
                            }}
                            key={culture.id}
                        >
                            {culture?.iconPath && (
                                <Box
                                    css={{
                                        size: '28px',
                                        background: 'rgba(83, 165, 81, 0.08)',
                                        d: 'flex',
                                        ai: 'center',
                                        jc: 'center',
                                        br: '$0-5',
                                    }}
                                >
                                    <Image
                                        src={culture?.iconPath}
                                        css={{
                                            size: 20,
                                        }}
                                    />
                                </Box>
                            )}
                            <Text css={{ fg: 1 }}>{culture.name}</Text>
                            <Link to={`/kontrolna-tabla/podaci/operacije/izmeni/${culture.id}`}>
                                <Button variant={'secondary'}>Izmeni</Button>
                            </Link>
                        </Box>
                    ))}
                </Box>
            ) : (
                <Box
                    css={{
                        d: 'flex',
                        fd: 'column',
                        width: '100%',
                        justifyContent: 'center',
                        mt: 50,
                        gap: '$2',
                    }}
                >
                    <Box
                        css={{
                            d: 'flex',
                            fd: 'column',
                            gap: '$1-25',
                            textAlign: 'center',
                        }}
                    >
                        <Text
                            css={{
                                fontSize: '24px',
                                fontWeight: 600,
                                color: '#2C3E50',
                            }}
                        >
                            Operacije će se pojaviti ovde kada dodate svoje kulture.
                        </Text>
                        <Text
                            css={{
                                fontSize: '14px',
                                color: 'rgba(0,0,0,0.64)',
                            }}
                        >
                            Dodajte ih klikom na dugme ispod.
                        </Text>
                    </Box>
                    <Box
                        css={{
                            d: 'flex',
                            jc: 'center',
                        }}
                    >
                        <Link to="/kontrolna-tabla/podaci/kulture/izmeni">
                            <Button variant="cta">Izmeni kulture</Button>
                        </Link>
                    </Box>
                </Box>
            )}
        </DashboardPageLayout>
    )
}
