import mem from 'mem'

import { storage, StorageKey } from 'library/local-storage'
import { LoginResponse } from 'library/services/UsersTypes'

import { baseHttpClient } from './HttpClient'

const refreshTokenFn = async () => {
    const currentAccessToken = JSON.parse(storage.load(StorageKey.ACCESS_TOKEN))
    const currentRefreshToken = JSON.parse(storage.load(StorageKey.REFRESH_TOKEN))

    if (!currentAccessToken || !currentRefreshToken) {
        storage.clear()
        return
    }

    try {
        const response = await baseHttpClient.post<LoginResponse>('/users/refresh-token', {
            refreshToken: currentRefreshToken,
        })

        const { accessToken, refreshToken } = response.data

        if (!accessToken) {
            storage.clear()
        }

        storage.save(StorageKey.ACCESS_TOKEN, accessToken)
        storage.save(StorageKey.REFRESH_TOKEN, refreshToken)

        return { accessToken, refreshToken }
    } catch (error) {
        storage.clear()
    }
}

const maxAge = 10000

export const memoizedRefreshToken = mem(refreshTokenFn, {
    maxAge,
})
