import { httpClient } from 'library/http'

import type {
    CreateAreaMeasureUnitRequest,
    GetAllAreaMeasureUnitsResponse,
} from './AreaMeasureUnitsTypes'

export class AreaMeasureUnitsService {
    private _areaMeasureUnitsUrl = '/area-measure-units'

    public async getAllAreaMeasureUnits() {
        const { data } = await httpClient.get<GetAllAreaMeasureUnitsResponse>(
            this._areaMeasureUnitsUrl,
        )

        return data.sort((a, b) => (a.ratio > b.ratio ? -1 : 1))
    }

    public async createAreaMeasureUnit(request: CreateAreaMeasureUnitRequest) {
        return httpClient.post(this._areaMeasureUnitsUrl, request)
    }
}

export const areaMeasureUnitsService = new AreaMeasureUnitsService()
