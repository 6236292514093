import { Controller, useFormContext } from 'react-hook-form'

import Select, { BaseSelect } from 'ui/Select'

export interface FormInputProps extends React.ComponentProps<typeof Select> {
    name: string
}

export const FormInputSelect = ({ name, ...selectProps }: FormInputProps) => {
    const { control } = useFormContext()

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => {
                return (
                    <BaseSelect
                        onValueChange={onChange}
                        value={value}
                        {...selectProps}
                        css={{
                            width: '100%',
                            ...selectProps.css,
                        }}
                    />
                )
            }}
        />
    )
}
