import * as Tabs from '@radix-ui/react-tabs'
import { useState } from 'react'

import { FullViewLoading } from 'components/FullscreenLoading'
import { CatalogPreview } from 'components/CatalogPreview'

import { useCatalogGroupCategoriesQuery, useCatalogGroupsQuery, useCatalogsQuery } from 'hooks/api'

import { Box, Text } from 'ui/Common'
import { DashboardPageLayout } from 'ui/DashboardPageLayout'
import { styled } from 'ui/stitches.config'

const CatalogsPage = () => {
    const [selectedCatalogGroupIndex, setSelectedCatalogGroupIndex] = useState(0)

    const { data: catalogGroups, isLoading: isLoadingCatalogGroups } = useCatalogGroupsQuery()

    return (
        <DashboardPageLayout>
            <Box
                css={{
                    d: 'flex',
                    gap: '$2',
                    jc: 'space-between',
                    ai: 'center',
                }}
            >
                <Box
                    css={{
                        d: 'flex',
                        fd: 'column',
                        gap: '$1',
                    }}
                >
                    <Text
                        css={{
                            color: '#2C3E50',
                            fontWeight: 600,
                            fontSize: '24px',
                            lineHeight: '28px',
                        }}
                    >
                        Katalozi
                    </Text>
                    <Text
                        css={{
                            color: 'rgba(0, 0, 0, 0.64)',
                            fontWeight: 400,
                            fontSize: '14px',
                            lineHeight: '16.94px',
                        }}
                    >
                        Ispod možete pogledati ponudu kataloga od partnera naše platforme.
                    </Text>
                </Box>
            </Box>
            <Box>
                {isLoadingCatalogGroups ? (
                    <FullViewLoading />
                ) : (
                    <TabsRoot
                        defaultValue={catalogGroups?.[selectedCatalogGroupIndex]?.id.toString()}
                        onValueChange={(value) => setSelectedCatalogGroupIndex(Number(value))}
                    >
                        <TabsList aria-label="">
                            {catalogGroups?.map((catalogGroup, index) => (
                                <TabsTrigger
                                    value={catalogGroup.id.toString()}
                                    key={catalogGroup.id}
                                    css={{
                                        'd': 'flex',
                                        'ai': 'center',
                                        'gap': '$1-25',
                                        '&:disabled': {
                                            color: '#6B7280',
                                            cursor: 'default',
                                        },
                                        '&:disabled &:hover': {
                                            color: '#6B7280',
                                        },
                                    }}
                                    disabled={catalogGroup.name !== 'Repromaterijali'}
                                >
                                    {catalogGroup.name}
                                    {catalogGroup.name !== 'Repromaterijali' && (
                                        <Box
                                            css={{
                                                fontSize: '10px',
                                                fontWeight: 500,
                                                color: '#6B7280',
                                                px: '$0-75',
                                                py: '$0-5',
                                                background: '#fff',
                                                br: '50px',
                                            }}
                                        >
                                            USKORO
                                        </Box>
                                    )}
                                </TabsTrigger>
                            ))}
                        </TabsList>
                        {catalogGroups?.map((catalogGroup, index) => (
                            <CatalogGroup catalogGroup={catalogGroup} key={index} />
                        ))}
                    </TabsRoot>
                )}
            </Box>
        </DashboardPageLayout>
    )
}

const CatalogGroup = ({ catalogGroup }: { catalogGroup: any }) => {
    const [selectedCatalogGroupCategoryIndex, setSelectedCatalogGroupCategoryIndex] = useState<
        number | undefined
    >(undefined)
    const { data: catalogGroupCategories, isLoading: isLoadingCatalogGroupCategories } =
        useCatalogGroupCategoriesQuery({
            catalogGroupId: catalogGroup.id,
        })

    const { data: catalogs, isLoading: isLoadingCatalogs } = useCatalogsQuery({
        catalogGroupId: catalogGroup.id,
        catalogCategoryId:
            selectedCatalogGroupCategoryIndex !== undefined
                ? catalogGroupCategories?.[selectedCatalogGroupCategoryIndex]?.id
                : undefined,
    })

    return (
        <TabsContent value={catalogGroup.id.toString()} key={catalogGroup.id}>
            {isLoadingCatalogGroupCategories ? (
                <FullViewLoading />
            ) : (
                <Box
                    css={{
                        d: 'flex',
                        fd: 'column',
                    }}
                >
                    {/* <Box css={{ d: 'flex', fd: 'row', ai: 'center', gap: '$1' }}>
                        <Button
                            css={{
                                background:
                                    selectedCatalogGroupCategoryIndex === undefined
                                        ? 'green'
                                        : 'gray',
                            }}
                            onClick={() => setSelectedCatalogGroupCategoryIndex(undefined)}
                        >
                            Prikaži sve
                        </Button>
                        {catalogGroupCategories?.map((catalogGroupCategory, index) => {
                            const isActive = index === selectedCatalogGroupCategoryIndex
                            return (
                                <Button
                                    css={{
                                        background: isActive ? 'green' : 'gray',
                                    }}
                                    key={catalogGroupCategory.id}
                                    onClick={() => setSelectedCatalogGroupCategoryIndex(index)}
                                >
                                    {catalogGroupCategory.name}
                                </Button>
                            )
                        })}
                    </Box> */}

                    <Box>
                        {isLoadingCatalogs ? (
                            <FullViewLoading />
                        ) : (
                            <Box
                                css={{
                                    d: 'grid',
                                    gridTemplateColumns: 'repeat(auto-fill, 200px)',
                                    gridAutoRows: '284px',
                                    gap: '$1',
                                    a: {
                                        width: '100% !important',
                                        height: '100% !important',
                                        overflow: 'hidden',
                                    },
                                }}
                            >
                                {catalogs?.map((catalog) => (
                                    <CatalogPreview key={catalog.id} catalog={catalog} />
                                ))}
                            </Box>
                        )}
                    </Box>
                </Box>
            )}
        </TabsContent>
    )
}

const TabsRoot = styled(Tabs.Root, {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
})

const TabsList = styled(Tabs.List, {
    flexShrink: 0,
    display: 'flex',
    ai: 'center',
    jc: 'flex-start',
    borderBottom: '1px solid #E5E7EB',
})

const TabsTrigger = styled(Tabs.Trigger, {
    'all': 'unset',
    'fontFamily': 'inherit',
    'padding': '0 20px',
    'height': 45,

    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'center',
    'fontSize': 15,
    'lineHeight': 1,
    'cursor': 'pointer',
    'color': '#6B7280',
    'userSelect': 'none',
    '&:hover': { color: '#1975FF' },
    '&[data-state="active"]': {
        color: '#1975FF',
        boxShadow: 'inset 0 -2px 0 0 currentColor, 0 1px 0 0 currentColor',
    },
    '&:focus-visible': {
        outline: ' -webkit-focus-ring-color auto 1px',
    },
})

const TabsContent = styled(Tabs.Content, {
    fg: 1,
    py: '$3',
    outline: 'none',
    // '&:focus': { boxShadow: `0 0 0 2px black` },
})

export default CatalogsPage
