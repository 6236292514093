import { Controller, useFormContext } from 'react-hook-form'
import { ReactNode } from 'react'
import * as RadioGroup from '@radix-ui/react-radio-group'
import * as Label from '@radix-ui/react-label'

import { Box, Image, Text } from 'ui/Common'
import { Input } from 'ui/Input'
import { CSS, styled } from 'ui/stitches.config'

import IconCheckmark from 'assets/icons/checkmark.svg'

export interface RadioOption {
    label: string
    value: string
    secondaryLabel?: string
}

export interface FormRadioGroupProps extends React.ComponentProps<typeof Input> {
    name: string
    label?: string
    labelHelpEl?: ReactNode
    containerCSS?: CSS
    labelContainerCSS?: CSS
    options: RadioOption[]
}

export const FormRadioGroup = ({
    name,
    label,
    labelHelpEl,
    containerCSS = {},
    labelContainerCSS = {},
    options,
    ...inputProps
}: FormRadioGroupProps) => {
    const {
        control,
        formState: { errors },
    } = useFormContext()

    const error = errors[name]?.message?.toString()

    const borderColor = error ? '#EF4444' : 'transparent'

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => {
                return (
                    <Box
                        css={{
                            d: 'flex',
                            fd: 'column',
                            gap: '$1',
                            width: '100%',
                            ...containerCSS,
                        }}
                    >
                        {(!!label || !!labelHelpEl) && (
                            <Box
                                css={{
                                    d: 'flex',
                                    jc: 'space-between',
                                    ...labelContainerCSS,
                                }}
                            >
                                {!!label && (
                                    <LabelRoot htmlFor={name} css={{ fontSize: 12 }}>
                                        {label}
                                        {!!inputProps.required && '*'}
                                    </LabelRoot>
                                )}
                                {!!labelHelpEl && labelHelpEl}
                            </Box>
                        )}
                        <RadioGroupRoot value={value} onValueChange={onChange}>
                            {options.map((o) => {
                                const isChecked = o.value === value
                                return (
                                    <FormRadioGroupItem
                                        checked={isChecked}
                                        item={o}
                                        key={o.value}
                                    />
                                )
                            })}
                        </RadioGroupRoot>
                        {!!error && (
                            <Text
                                css={{
                                    color: '#EF4444',
                                    fontSize: 14,
                                    marginTop: '$0-25',
                                }}
                            >
                                {error}
                            </Text>
                        )}
                    </Box>
                )
            }}
        />
    )
}

export const FormRadioGroupItem = ({ checked, item }: { checked: boolean; item: RadioOption }) => {
    return (
        <RadioGroupItem checked={!!checked} value={item.value}>
            <Box
                css={{
                    'd': 'flex',
                    'fd': 'column',
                    'ai': 'flex-start',
                    'bc': '#ffffff',
                    'gap': '$2',
                    'p': '$2',
                    'br': '$1',
                    'width': '100%',
                    '&:focus-visible': {
                        outline: ' -webkit-focus-ring-color auto 1px',
                    },
                }}
            >
                <Box css={{ d: 'flex', ai: 'flex-start', jc: 'space-between', width: '100%' }}>
                    <Box
                        css={{
                            size: '$3-5',
                            d: 'flex',
                            ai: 'center',
                        }}
                    >
                        {item.label ? (
                            <Text
                                css={{
                                    fontSize: '24px',
                                    fontWeight: 600,
                                    lineHeight: '29px',
                                    fontFamily: '$inter',
                                    color: '#2C3E50',
                                }}
                            >
                                {item.label}
                            </Text>
                        ) : (
                            <div />
                        )}
                    </Box>
                    <Box css={{ d: 'flex', ai: 'center' }}>
                        <Box
                            css={{
                                d: 'flex',
                                ai: 'center',
                                jc: 'center',
                                br: '$round',
                                border: '1px solid #53A551',
                                size: '24px',
                            }}
                        >
                            <RadioGroupIndicator>
                                <Image src={IconCheckmark} />
                            </RadioGroupIndicator>
                        </Box>
                    </Box>
                </Box>

                <OtherLabel htmlFor={''}>{item.secondaryLabel || ` `}</OtherLabel>
            </Box>
        </RadioGroupItem>
    )
}

const OtherLabel = styled(Label.Root, {
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '19px',
    textAlign: 'left',
    color: '#2C3E50',
    fontFamily: '$inter',
    height: '19px',
    whiteSpace: 'nowrap',
})
const RadioGroupItem = styled(RadioGroup.Item, {
    'd': 'flex',
    'fd': 'column',
    'ai': 'flex-start',
    'bc': '#ffffff',
    'border': '1px solid #E4E5E7',

    'transition': 'box-shadow 0.2s ease-in-out, filter 0.3s ease-in-out',
    '&:hover': {
        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
        filter: 'brightness(105%)',
        border: '1px solid rgba(83, 165, 81, 0.3)',
    },
    'br': '$1',
    '&:focus-visible': {
        outline: ' -webkit-focus-ring-color auto 1px',
    },
    'variants': {
        checked: {
            true: {
                'border': '1px solid #53A551',
                '&:hover': {
                    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                    filter: 'brightness(105%)',
                    border: '1px solid #53A551',
                },
            },
        },
    },
})

const RadioGroupIndicator = styled(RadioGroup.Indicator, {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    position: 'relative',
    backgroundColor: '#53A551',
    br: '$round',
})

const RadioGroupRoot = styled(RadioGroup.Root, {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(166px, 1fr))',
    gap: '$2',
})

const RadioItemContainer = styled(Box, {})

const LabelRoot = styled(Label.Root, {
    fontSize: 14,
    lineHeight: '20px',
    color: 'rgba(44, 62, 80, 0.64)',
    userSelect: 'none',
})
