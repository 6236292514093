import { createContext, useContext } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { GetCurrentUserResponse } from 'library/services/UsersTypes'

import { FullscreenLoading } from 'components/FullscreenLoading'

import { useGetCurrentUserQuery } from 'hooks/api'

export interface AuthContextType {
    user: GetCurrentUserResponse | undefined
}

export const AuthContext = createContext<AuthContextType>(null!)

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const { data, isLoading, isFetching, isRefetching } = useGetCurrentUserQuery()

    if (isLoading || isFetching || isRefetching) return <FullscreenLoading />

    return (
        <AuthContext.Provider
            value={{
                user: data?.data,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => {
    return useContext(AuthContext)
}

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
    const { user } = useAuth()
    const location = useLocation()

    if (!user) {
        return <Navigate to="/prijava" state={{ from: location }} />
    }

    return children
}

export const RequireNoAuth = ({ children }: { children: JSX.Element }) => {
    const { user } = useAuth()

    if (user) {
        return <Navigate to="/kontrolna-tabla" />
    }

    return children
}

export const RequireNotOnboarded = ({ children }: { children: JSX.Element }) => {
    const { user } = useAuth()

    if (user?.isOnboarded) {
        return <Navigate to="/kontrolna-tabla" />
    }

    return children
}

export const RequireOnboarding = ({ children }: { children: JSX.Element }) => {
    const { user } = useAuth()

    if (user?.isOnboarded === false) {
        return <Navigate to="/onboarding" />
    }

    return children
}
