import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'
import humps from 'humps'

import { storage, StorageKey } from 'library/local-storage'

import { memoizedRefreshToken } from './refreshToken'

export class HttpClient {
    http: AxiosInstance

    constructor() {
        this.http = axios.create({
            baseURL: '/api',
        })

        this.http.interceptors.request.use((request) => {
            const accessToken = storage.load(StorageKey.ACCESS_TOKEN)

            if (accessToken) {
                request.headers = {
                    ...request.headers,
                    authorization: `Bearer ${accessToken}`,
                }
            }

            request.data = humps.decamelizeKeys(request.data)
            request.params = humps.decamelizeKeys(request.params)

            return request
        })

        this.http.interceptors.response.use(
            (response) => {
                response.data = humps.camelizeKeys(response.data)

                return response
            },
            async (error: AxiosError) => {
                if (error.response?.data) {
                    error.response.data = humps.camelizeKeys(error.response?.data)
                }

                const config = error.config

                if (config) {
                    if (error?.response?.status === 401) {
                        const refreshTokenResponse = await memoizedRefreshToken()

                        if (refreshTokenResponse?.accessToken) {
                            config.headers = {
                                ...config.headers,
                                authorization: `Bearer ${refreshTokenResponse?.accessToken}`,
                            }
                        }

                        return axios(config)
                    }
                }

                return Promise.reject(error)
            },
        )
    }

    async get<T>(path: string, config?: AxiosRequestConfig) {
        return this.http.get<T>(path, config)
    }

    async post<T>(path: string, payload: unknown = {}, config?: AxiosRequestConfig) {
        return this.http.post<T>(path, payload, config)
    }

    async put<T>(path: string, payload: unknown = {}, config?: AxiosRequestConfig) {
        return this.http.put<T>(path, payload, config)
    }

    async patch<T>(path: string, payload: unknown, config?: AxiosRequestConfig) {
        return this.http.patch<T>(path, payload, config)
    }

    async delete<T>(path: string, config?: AxiosRequestConfig) {
        return this.http.delete<T>(path, config)
    }
}

export const httpClient = new HttpClient()

export class BaseHttpClient {
    http: AxiosInstance

    constructor() {
        this.http = axios.create({
            baseURL: '/api',
        })

        this.http.interceptors.request.use((request) => {
            request.data = humps.decamelizeKeys(request.data)

            return request
        })

        this.http.interceptors.response.use(
            (response) => {
                response.data = humps.camelizeKeys(response.data)

                return response
            },
            (error: AxiosError) => {
                if (error.response?.data) {
                    error.response.data = humps.camelizeKeys(error.response?.data)
                }

                return Promise.reject(error)
            },
        )
    }

    async get<T>(path: string, config?: AxiosRequestConfig) {
        return this.http.get<T>(path, config)
    }

    async post<T>(path: string, payload: unknown = {}, config?: AxiosRequestConfig) {
        return this.http.post<T>(path, payload, config)
    }

    async put<T>(path: string, payload: unknown = {}, config?: AxiosRequestConfig) {
        return this.http.put<T>(path, payload, config)
    }

    async patch<T>(path: string, payload: unknown, config?: AxiosRequestConfig) {
        return this.http.patch<T>(path, payload, config)
    }

    async delete<T>(path: string, config?: AxiosRequestConfig) {
        return this.http.delete<T>(path, config)
    }
}

export const baseHttpClient = new BaseHttpClient()
