import { z } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { ReactNode, useEffect, useState } from 'react'
import { t } from 'i18next'

import { GetFarmResponse } from 'library/services/FarmTypes'

import { FormInput } from 'components/FormInput'

import { useAreaMeasureUnitsQuery, useUpdateFarmMutation } from 'hooks/api'

import { Box, Form } from 'ui/Common'
import { DialogBody, DialogTrigger, Dialog, DialogClose } from 'ui/Dialog'
import { Button } from 'ui/Button'

import { FormRadioGroup } from './FormRadioGroup'

export const EditFarmDialog = ({
    children,
    farm,
}: {
    children?: ReactNode
    farm: GetFarmResponse
}) => {
    const [open, setOpen] = useState(false)
    const { data } = useAreaMeasureUnitsQuery()
    const { mutateAsync: updateFarm, isLoading } = useUpdateFarmMutation()
    const farmSchema = z.object({
        farmName: z.string().min(1, t('validation:farmName.min')),
        farmLocation: z.string().min(2, t('validation:farmLocation.min')),
        areaMeasureUnits: z.string().min(1, t('validation:areaMeasureUnits.min')),
    })

    type FarmValues = z.infer<typeof farmSchema>

    const form = useForm<FarmValues>({
        resolver: zodResolver(farmSchema),
        defaultValues: {
            farmName: farm?.name ?? '',
            farmLocation: farm?.location ?? '',
            areaMeasureUnits: farm?.areaMeasureUnit.id.toString() ?? '',
        },
    })
    const handleSubmit = async (values: FarmValues) => {
        try {
            await updateFarm({
                name: values.farmName,
                location: values.farmLocation,
                areaMeasureUnitId: Number(values.areaMeasureUnits),
            })

            setOpen(false)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        form.reset()
    }, [open])

    useEffect(() => {
        form.reset({
            farmName: farm?.name ?? '',
            farmLocation: farm?.location ?? '',
            areaMeasureUnits: farm?.areaMeasureUnit.id.toString() ?? '',
        })
    }, [farm])

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger
                asChild
                onClick={(e) => {
                    e.stopPropagation()
                    setOpen(true)
                }}
            >
                {children}
            </DialogTrigger>
            <DialogBody title="Izmenite gazdinstvo">
                <FormProvider {...form}>
                    <Form
                        css={{
                            d: 'flex',
                            fd: 'column',
                            gap: '$3',
                        }}
                        onSubmit={form.handleSubmit(handleSubmit)}
                    >
                        <FormInput
                            name="farmName"
                            label="Naziv gazdinstva"
                            placeholder="Unesite naziv gazdinstva"
                        />
                        <FormInput
                            name="farmLocation"
                            label="Lokacija gazdinstva"
                            placeholder="Unesite lokaciju gazdinstva"
                        />

                        <FormRadioGroup
                            name="areaMeasureUnits"
                            label="Odaberite mernu jedinicu"
                            options={
                                data?.map((a) => {
                                    const secondaryLabel =
                                        a.ratio === 1 ? a.name : `1 ${a.abbreviation}=${a.ratio}ha`
                                    return {
                                        value: String(a.id),
                                        label: a.abbreviation,
                                        secondaryLabel,
                                    }
                                }) ?? []
                            }
                        />

                        <Box
                            css={{
                                d: 'flex',
                                ai: 'center',
                                jc: 'flex-end',
                                gap: '$2',
                            }}
                        >
                            <DialogClose asChild>
                                <Button variant="secondary" type="button">
                                    Izađi
                                </Button>
                            </DialogClose>
                            <Button
                                type="submit"
                                isLoading={isLoading}
                                disabled={isLoading || !form.formState.isValid}
                            >
                                Sačuvaj
                            </Button>
                        </Box>
                    </Form>
                </FormProvider>
            </DialogBody>
        </Dialog>
    )
}
