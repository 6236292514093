import * as DropdownMenu from '@radix-ui/react-dropdown-menu'

interface DropdownProps {
    children: React.ReactNode
    content: React.ReactNode
}
export const Dropdown = ({ children, content }: DropdownProps) => {
    return (
        <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>{children}</DropdownMenu.Trigger>
            <DropdownMenu.Portal>
                <DropdownMenu.Content sideOffset={12}>{content}</DropdownMenu.Content>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    )
}
