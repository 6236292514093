import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { FullscreenLoading } from 'components/FullscreenLoading'

import { useConfirmRegistrationMutation } from 'hooks/api'

export const ConfirmRegistrationPage = () => {
    const params = useParams()
    const navigate = useNavigate()
    const { mutateAsync } = useConfirmRegistrationMutation()

    const onConfirmRegistration = async () => {
        if (!params?.id) {
            return
        }

        try {
            const response = await mutateAsync({ registrationUUID: params.id })
            if (response.status === 200) {
                navigate('/prijava', { state: { redirected: true } })
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        onConfirmRegistration()
    }, [])

    return <FullscreenLoading />
}
