import EditCultures from 'components/EditCultures'

import { Box, Text } from 'ui/Common'

import { useOnboardingState } from './OnboardingState'
import OnboardingNavigation from './OnboardingNavigation'

export const OnboardingCultures = () => {
    const { onNextStep } = useOnboardingState()

    const onSubmit = () => {
        onNextStep()
    }
    return (
        <Box
            css={{
                d: 'flex',
                fd: 'column',
                p: '$2',
                pt: '$5',
                gap: '$5',
            }}
        >
            <Box
                css={{
                    d: 'flex',
                    fd: 'column',
                    gap: '$0-5',
                }}
            >
                <Text
                    css={{
                        fontWeight: 600,
                        fontsSize: '20px',
                    }}
                >
                    🌱 Kulture
                </Text>
                <Text
                    css={{
                        color: 'rgba(0, 0, 0, 0.64)',
                        fontSize: '14px',
                    }}
                >
                    Odaberite kulture koje gajite u toku jedne sezone.
                </Text>
            </Box>

            <EditCultures />
            <OnboardingNavigation onStepComplete={onSubmit} />
        </Box>
    )
}
