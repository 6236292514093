import { useParams } from 'react-router-dom'
import * as Tabs from '@radix-ui/react-tabs'

import { DeleteFieldDialog } from 'components/DeleteFieldDialog'
import { FullViewLoading } from 'components/FullscreenLoading'
import { FinishActivityDialog } from 'components/FinishActivityDialog'
import { EditFieldDialog } from 'components/EditFieldDialog'
import { EditCompletedActivityDialog } from 'components/EditCompletedActivityDialog'
import { AddActivityToFieldDialog } from 'components/AddActivityToFieldDialog'
import { DeleteFieldActivityDialog } from 'components/DeleteFieldActivityDialog'

import {
    useDeleteActivityFromFieldMutation,
    useFarmQuery,
    useFieldActivitiesQuery,
    useFieldQuery,
    useFieldSeasonsQuery,
} from 'hooks/api'

import { BaseButton, Button, GhostIconButton } from 'ui/Button'
import { Box, Image, Link, Text } from 'ui/Common'
import { DashboardPageLayout } from 'ui/DashboardPageLayout'
import { styled } from 'ui/stitches.config'

import EditIcon from 'assets/icons/edit.svg'
import FarmerImage from 'assets/icons/farmer.png'
import PlaceholderIcon from 'assets/icons/placeholder.png'

export default function FieldDetailsPage() {
    const { data: farm, isLoading: isLoadingFarm } = useFarmQuery()

    const params = useParams()

    const fieldId = params.id
    const { mutateAsync: deleteActivityFromField } = useDeleteActivityFromFieldMutation()
    const { data: field, isLoading: isLoadingField } = useFieldQuery({ id: Number(fieldId) })
    const { data: activities } = useFieldActivitiesQuery({ id: Number(fieldId), completed: false })
    const { data: completedActivities } = useFieldActivitiesQuery({
        id: Number(fieldId),
        completed: true,
    })
    const noActivitiesForField = !activities?.length && !completedActivities?.length
    const { data: seasons } = useFieldSeasonsQuery({ id: Number(fieldId) })

    const isLoading = isLoadingFarm || isLoadingField

    if (isLoading)
        return (
            <DashboardPageLayout>
                <FullViewLoading />
            </DashboardPageLayout>
        )

    return (
        <DashboardPageLayout>
            <Box
                css={{
                    d: 'flex',
                    gap: '$2',
                    jc: 'space-between',
                    ai: 'center',
                }}
            >
                <Box
                    css={{
                        d: 'flex',
                        fd: 'column',
                        gap: '$1',
                    }}
                >
                    <Link
                        to="/kontrolna-tabla/njive"
                        css={{
                            'color': 'rgba(0, 0, 0, 0.64)',
                            'fontWeight': 400,
                            'fontSize': '14px',
                            'lineHeight': '16.94px',
                            '@maxSm': {
                                display: 'none',
                            },
                        }}
                    >
                        {'<'}Nazad na njive
                    </Link>
                    <Text
                        css={{
                            color: '#2C3E50',
                            fontWeight: 600,
                            fontSize: '24px',
                            lineHeight: '28px',
                        }}
                    >
                        {field?.name}
                    </Text>
                </Box>
                <Box
                    css={{
                        d: 'flex',
                        gap: '$2',
                        ai: 'center',
                    }}
                >
                    <EditFieldDialog field={field!}>
                        <BaseButton
                            css={{
                                br: '$1',
                                color: '#2C3E50',
                                border: '1px solid #2C3E50',
                                px: '$2-5',
                                py: '$1-25',
                                background: 'transparent',
                                fontSize: '14px',
                                fontWeight: 600,
                            }}
                        >
                            Izmeni
                        </BaseButton>
                    </EditFieldDialog>
                    <DeleteFieldDialog fieldId={Number(fieldId)}>
                        <BaseButton
                            css={{
                                br: '$1',
                                color: '#E74C3C',
                                border: '1px solid #E74C3C',
                                px: '$2-5',
                                py: '$1-25',
                                background: 'transparent',
                                fontSize: '14px',
                                fontWeight: 600,
                            }}
                        >
                            Ukloni
                        </BaseButton>
                    </DeleteFieldDialog>
                </Box>
            </Box>
            <Box>
                <TabsRoot defaultValue="activities">
                    <TabsList aria-label="Manage your account">
                        <TabsTrigger value="activities">Aktivnosti</TabsTrigger>
                        <TabsTrigger value="seasons">Sezone</TabsTrigger>
                        <TabsTrigger value="information">Informacije</TabsTrigger>
                    </TabsList>
                    <TabsContent value="activities">
                        {noActivitiesForField ? (
                            <Box
                                css={{
                                    background: '#fff',
                                    px: '$1-25',
                                    py: '$4',
                                    width: '100%',
                                    d: 'flex',
                                    fd: 'column',
                                    ai: 'center',
                                    gap: '$2',
                                    textAlign: 'center',
                                }}
                            >
                                <Image
                                    src={FarmerImage}
                                    css={{
                                        size: 160,
                                    }}
                                />
                                <Box
                                    css={{
                                        d: 'flex',
                                        fd: 'column',
                                        ai: 'center',
                                        gap: '$1-25',
                                    }}
                                >
                                    <Text
                                        css={{
                                            fontSize: '24px',
                                            fontWeight: 600,
                                            color: '#2C3E50',
                                        }}
                                    >
                                        Nema aktivnosti za odabranu kulturu
                                    </Text>
                                    <Text
                                        css={{
                                            fontSize: '14px',
                                            color: 'rgba(0, 0, 0, 0.64)',
                                        }}
                                    >
                                        Dodajte ih klikom na dugme ispod.
                                    </Text>
                                </Box>

                                <AddActivityToFieldDialog fieldId={fieldId!}>
                                    <Button variant="cta">Dodaj aktivnost</Button>
                                </AddActivityToFieldDialog>
                            </Box>
                        ) : (
                            <Box
                                css={{
                                    'd': 'grid',
                                    'width': '100%',
                                    'gridTemplateColumns': '1fr 1fr',
                                    'gap': '$3',
                                    '@maxMd': {
                                        gridTemplateColumns: '1fr',
                                    },
                                }}
                            >
                                <Box
                                    css={{
                                        d: 'flex',
                                        fd: 'column',
                                        fg: 1,
                                        gap: '$2',
                                    }}
                                >
                                    <Box
                                        css={{
                                            d: 'flex',
                                            jc: 'space-between',
                                            ai: 'center',
                                        }}
                                    >
                                        <Text
                                            css={{
                                                fontWeight: 600,
                                                fontSize: '16px',
                                                lineHeight: '28px',
                                                color: '#2C3E50',
                                            }}
                                        >
                                            Predstojeće aktivnosti
                                        </Text>
                                        <AddActivityToFieldDialog fieldId={fieldId!}>
                                            <BaseButton
                                                css={{
                                                    br: '$1',
                                                    color: '#2C3E50',
                                                    border: '1px solid #2C3E50',
                                                    px: '$2',
                                                    py: '$1',
                                                    background: 'transparent',
                                                    fontSize: '14px',
                                                    fontWeight: 600,
                                                }}
                                            >
                                                Dodaj aktivnost
                                            </BaseButton>
                                        </AddActivityToFieldDialog>
                                    </Box>
                                    <Box
                                        css={{
                                            d: 'flex',
                                            fd: 'column',
                                            gap: '$1-5',
                                        }}
                                    >
                                        {activities?.map((a) => (
                                            <Box
                                                key={a.id + Math.random()}
                                                css={{
                                                    d: 'flex',
                                                    p: '$1-5',
                                                    br: '$1',
                                                    background: '#fff',
                                                    ai: 'center',
                                                    jc: 'space-between',
                                                }}
                                            >
                                                <Text
                                                    css={{
                                                        fontWeight: 500,
                                                        fontSize: '14px',
                                                        color: '#2C3E50',
                                                    }}
                                                >
                                                    {a.name}
                                                </Text>
                                                <Box css={{ d: 'flex', ai: 'center', gap: '$1' }}>
                                                    <FinishActivityDialog
                                                        fieldId={Number(fieldId)}
                                                        fieldSeasonActivityId={
                                                            a.fieldSeasonActivityId
                                                        }
                                                    >
                                                        <BaseButton
                                                            css={{
                                                                color: '#2C3E50',
                                                                fontSize: '13px',
                                                                fontWeight: 600,
                                                                px: '$1-5',
                                                                py: '$0-75',
                                                                br: '$1',
                                                                border: '1px solid #2C3E50',
                                                                background: '#fff',
                                                            }}
                                                        >
                                                            Završi
                                                        </BaseButton>
                                                    </FinishActivityDialog>
                                                    <DeleteFieldActivityDialog
                                                        fieldId={Number(fieldId)}
                                                        fieldSeasonActivityId={
                                                            a.fieldSeasonActivityId
                                                        }
                                                    >
                                                        <GhostIconButton>
                                                            <svg
                                                                width="18"
                                                                height="18"
                                                                viewBox="0 0 18 18"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M3 4.95H4.33333M4.33333 4.95H15M4.33333 4.95V14.4C4.33333 14.758 4.47381 15.1014 4.72386 15.3546C4.97391 15.6078 5.31304 15.75 5.66667 15.75H12.3333C12.687 15.75 13.0261 15.6078 13.2761 15.3546C13.5262 15.1014 13.6667 14.758 13.6667 14.4V4.95H4.33333ZM6.33333 4.95V3.6C6.33333 3.24196 6.47381 2.89858 6.72386 2.64541C6.97391 2.39223 7.31304 2.25 7.66667 2.25H10.3333C10.687 2.25 11.0261 2.39223 11.2761 2.64541C11.5262 2.89858 11.6667 3.24196 11.6667 3.6V4.95M7.66667 8.325V12.375M10.3333 8.325V12.375"
                                                                    stroke="#E74C3C"
                                                                    stroke-width="1.5"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                />
                                                            </svg>
                                                        </GhostIconButton>
                                                    </DeleteFieldActivityDialog>
                                                </Box>
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                                <Box
                                    css={{
                                        d: 'flex',
                                        fd: 'column',
                                        fg: 1,
                                        gap: '$2',
                                    }}
                                >
                                    <Box
                                        css={{
                                            d: 'flex',
                                            jc: 'space-between',
                                            ai: 'center',
                                        }}
                                    >
                                        <Text
                                            css={{
                                                fontWeight: 600,
                                                fontSize: '16px',
                                                lineHeight: '28px',
                                                color: '#2C3E50',
                                            }}
                                        >
                                            Završene aktivnosti
                                        </Text>
                                    </Box>
                                    <Box
                                        css={{
                                            d: 'flex',
                                            fd: 'column',
                                            gap: '$1-5',
                                        }}
                                    >
                                        {completedActivities?.map((a) => (
                                            <Box
                                                key={a.id + Math.random()}
                                                css={{
                                                    d: 'flex',
                                                    p: '$1-5',
                                                    br: '$1',
                                                    background: '#fff',
                                                    ai: 'center',
                                                    jc: 'space-between',
                                                    outline: '1px solid rgba(39, 174, 96, 0.4)',
                                                }}
                                            >
                                                <Text
                                                    css={{
                                                        fontWeight: 500,
                                                        fontSize: '14px',
                                                        color: '#2C3E50',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        maxWidth: 180,
                                                    }}
                                                >
                                                    {a.name}
                                                </Text>
                                                <Box
                                                    css={{
                                                        d: 'flex',
                                                        ai: 'center',
                                                        gap: '$1',
                                                    }}
                                                >
                                                    <Box
                                                        css={{
                                                            background: 'rgba(41, 128, 185, 0.08)',
                                                            px: '$1-5',
                                                            py: '$0-5',
                                                            br: '$1-5',
                                                        }}
                                                    >
                                                        <Text
                                                            css={{
                                                                color: '#2980B9',
                                                                fontSize: '14px',
                                                                fontWeight: 400,
                                                            }}
                                                        >
                                                            {new Date(
                                                                a.endedAt!,
                                                            ).toLocaleDateString()}
                                                        </Text>
                                                    </Box>

                                                    <EditCompletedActivityDialog
                                                        activity={a}
                                                        fieldId={field?.id as number}
                                                    >
                                                        <GhostIconButton>
                                                            <Image
                                                                src={EditIcon}
                                                                css={{ size: 24 }}
                                                            />
                                                        </GhostIconButton>
                                                    </EditCompletedActivityDialog>
                                                </Box>
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                            </Box>
                        )}
                    </TabsContent>
                    <TabsContent value="seasons">
                        <Box css={{ d: 'flex', fd: 'column' }}>
                            {seasons?.map((season) => (
                                <Box
                                    key={season.id}
                                    css={{
                                        d: 'flex',
                                        ai: 'center',
                                        p: '$2',
                                        br: '$0-5',
                                        background: '#fff',
                                        gap: '$1-5',
                                    }}
                                >
                                    <Box
                                        css={{
                                            background: '#F1F8F1',
                                            p: '$1-25',
                                            fontSize: '16px',
                                            fontWeight: 600,
                                            br: '$0-5',
                                        }}
                                    >
                                        {season.name}
                                    </Box>
                                    <Box
                                        css={{
                                            d: 'flex',
                                            ai: 'center',
                                            gap: '$1',
                                        }}
                                    >
                                        <Box
                                            css={{
                                                size: 28,
                                                background: 'rgba(83, 165, 81, 0.08)',
                                                br: '$0-5',
                                                d: 'flex',
                                                ai: 'center',
                                                jc: 'center',
                                            }}
                                        >
                                            <Image
                                                src={season.agroCulture.iconPath ?? PlaceholderIcon}
                                                css={{
                                                    size: 20,
                                                }}
                                            />
                                        </Box>
                                        <Text
                                            css={{
                                                fontSize: '16px',
                                                fontWeight: 500,
                                                color: '#2C3E50',
                                            }}
                                        >
                                            {season.agroCulture.name}
                                        </Text>
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    </TabsContent>
                    <TabsContent value="information">
                        <Box
                            css={{
                                'd': 'grid',
                                'gap': '$3',
                                'gridTemplateColumns': '1fr 1fr 1fr',
                                '@maxLg': {
                                    gridTemplateColumns: '1fr 1fr',
                                },
                                '@maxSm': {
                                    gridTemplateColumns: '1fr',
                                },
                            }}
                        >
                            <Box
                                css={{
                                    background: '#fff',
                                    fg: 1,
                                    br: '$1',
                                    p: '$2',
                                    d: 'flex',
                                    fd: 'column',
                                    gap: '$1-25',
                                }}
                            >
                                <Text
                                    css={{
                                        fontSize: '24px',
                                        fontWeight: 600,
                                    }}
                                >
                                    {field?.region}
                                </Text>
                                <Text
                                    css={{
                                        fontSize: '14px',
                                        fontWeight: 400,
                                        color: 'rgba(0, 0, 0, 0.64)',
                                    }}
                                >
                                    Potes
                                </Text>
                            </Box>
                            <Box
                                css={{
                                    background: '#fff',
                                    fg: 1,
                                    br: '$1',
                                    p: '$2',
                                    d: 'flex',
                                    fd: 'column',
                                    gap: '$1-25',
                                }}
                            >
                                <Text
                                    css={{
                                        fontSize: '24px',
                                        fontWeight: 600,
                                    }}
                                >
                                    {field?.agroCulture.name}
                                </Text>
                                <Text
                                    css={{
                                        fontSize: '14px',
                                        fontWeight: 400,
                                        color: 'rgba(0, 0, 0, 0.64)',
                                    }}
                                >
                                    Kultura
                                </Text>
                            </Box>
                            <Box
                                css={{
                                    background: '#fff',
                                    fg: 1,
                                    br: '$1',
                                    p: '$2',
                                    d: 'flex',
                                    fd: 'column',
                                    gap: '$1-25',
                                }}
                            >
                                <Text
                                    css={{
                                        fontSize: '24px',
                                        fontWeight: 600,
                                    }}
                                >
                                    {field?.sort}
                                </Text>
                                <Text
                                    css={{
                                        fontSize: '14px',
                                        fontWeight: 400,
                                        color: 'rgba(0, 0, 0, 0.64)',
                                    }}
                                >
                                    Sorta/Hibrid
                                </Text>
                            </Box>
                            <Box
                                css={{
                                    background: '#fff',
                                    fg: 1,
                                    br: '$1',
                                    p: '$2',
                                    d: 'flex',
                                    fd: 'column',
                                    gap: '$1-25',
                                }}
                            >
                                <Text
                                    css={{
                                        fontSize: '24px',
                                        fontWeight: 600,
                                    }}
                                >
                                    {((field?.rentArea ?? 0) + (field?.ownedArea ?? 0)).toFixed(2)}{' '}
                                    <Text
                                        as="span"
                                        css={{
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            color: 'rgba(0, 0, 0, 0.64)',
                                        }}
                                    >
                                        {farm?.areaMeasureUnit.abbreviation}
                                    </Text>
                                </Text>
                                <Text
                                    css={{
                                        fontSize: '14px',
                                        fontWeight: 400,
                                        color: 'rgba(0, 0, 0, 0.64)',
                                    }}
                                >
                                    Površina
                                </Text>
                            </Box>
                        </Box>
                    </TabsContent>
                </TabsRoot>
            </Box>
        </DashboardPageLayout>
    )
}

const TabsRoot = styled(Tabs.Root, {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
})

const TabsList = styled(Tabs.List, {
    flexShrink: 0,
    display: 'flex',
    ai: 'center',
    jc: 'flex-start',
    borderBottom: '1px solid #E5E7EB',
})

const TabsTrigger = styled(Tabs.Trigger, {
    'all': 'unset',
    'fontFamily': 'inherit',
    'padding': '0 20px',
    'height': 45,

    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'center',
    'fontSize': 15,
    'lineHeight': 1,
    'cursor': 'pointer',
    'color': '#6B7280',
    'userSelect': 'none',
    '&:hover': { color: '#1975FF' },
    '&[data-state="active"]': {
        color: '#1975FF',
        boxShadow: 'inset 0 -2px 0 0 currentColor, 0 1px 0 0 currentColor',
    },
    '&:focus-visible': {
        outline: ' -webkit-focus-ring-color auto 1px',
    },
})

const TabsContent = styled(Tabs.Content, {
    fg: 1,
    py: '$3',
    outline: 'none',
    // '&:focus': { boxShadow: `0 0 0 2px black` },
})
