import { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'

import { GetAllAgroCulturesResponseItem } from 'library/services/AgroCulturesTypes'

import EditActivities from 'components/EditActivities'

import { QueryKey, useAgroCulturesQuery } from 'hooks/api'

import { Box, Image, Text } from 'ui/Common'

import IconArrowRight from 'assets/icons/arrow_right_gray.svg'

import OnboardingNavigation from './OnboardingNavigation'
import { useOnboardingState } from './OnboardingState'

const CultureItem = ({
    culture,
    isActive,
    ordinalNumber,
}: {
    culture: GetAllAgroCulturesResponseItem
    isActive: boolean
    ordinalNumber: number
}) => {
    return (
        <Box
            css={{
                d: 'flex',
                ai: 'center',
                gap: '$1',
                p: '$1-5',
            }}
        >
            <Box
                css={{
                    d: 'flex',
                    ai: 'center',
                    jc: 'center',
                    size: '$3',
                    background: '#2C3E50',
                    color: '#FFFFFF',
                    br: '$0-5',
                    opacity: isActive ? 1 : 0.4,
                }}
            >
                {ordinalNumber}
            </Box>
            <Text
                css={{ fontSize: '14px', fontWeight: 600, color: '#2C3E50', whiteSpace: 'nowrap' }}
            >
                {culture.name}
            </Text>
        </Box>
    )
}

export const OnboardingActivities = () => {
    const { data: cultures, isLoading: isLoadingCultures } = useAgroCulturesQuery()
    const queryClient = useQueryClient()

    const { onNextStep } = useOnboardingState()
    const filteredCultures = cultures ? cultures?.filter((culture) => culture.selected) : []
    const [activeCultureIndex, setActiveCultureIndex] = useState<number>(0)
    const onSubmit = () => {
        onNextStep()
    }

    useEffect(() => {
        if (!isLoadingCultures && (!filteredCultures || filteredCultures?.length === 0)) {
            queryClient.invalidateQueries(QueryKey.CURRENT_USER)
        }
    }, [isLoadingCultures, filteredCultures, queryClient])

    if (!isLoadingCultures && (!filteredCultures || filteredCultures?.length === 0)) return null
    return (
        <Box
            css={{
                d: 'flex',
                fd: 'column',
                p: '$2',
                pt: '$5',
                gap: '$5',
            }}
        >
            <Box
                css={{
                    d: 'flex',
                    fd: 'column',
                    gap: '$0-5',
                }}
            >
                <Text
                    css={{
                        fontWeight: 600,
                        fontsSize: '20px',
                    }}
                >
                    🚜 Aktivnosti
                </Text>
                <Text
                    css={{
                        color: 'rgba(0, 0, 0, 0.64)',
                        fontSize: '14px',
                    }}
                >
                    Odaberite aktivnosti za svaku kulturu koje gajite i u toku jedne sezone.
                </Text>
            </Box>
            <Box css={{ d: 'flex', ai: 'center', overflow: 'auto' }}>
                {filteredCultures?.map((culture, index) => {
                    const isLast = index === filteredCultures.length - 1
                    return (
                        <Box
                            key={culture.id}
                            onClick={() => setActiveCultureIndex(index)}
                            css={{ d: 'flex', ai: 'center' }}
                        >
                            <CultureItem
                                culture={culture}
                                isActive={index === activeCultureIndex}
                                ordinalNumber={index + 1}
                            />
                            {!isLast && (
                                <Image
                                    src={IconArrowRight}
                                    css={{
                                        rotate: '0deg',
                                    }}
                                />
                            )}
                        </Box>
                    )
                })}
            </Box>
            <EditActivities cultureId={filteredCultures[activeCultureIndex].id} />
            <OnboardingNavigation onStepComplete={onSubmit} />
        </Box>
    )
}
