import { useCallback, useMemo, useState } from 'react'
import { useCombobox, useMultipleSelection } from 'downshift'

import { Box, Li, Ul } from 'ui/Common'
import { Input } from 'ui/Input'
import { Text } from 'ui/Common'

interface Item {
    value: any
    label: string
}

export type ComboBoxProps<T> = {
    data: T[]
    filterFn: (data: T) => boolean
}
export const ComboBox = ({
    data,
    onSelectedChange,
    defaultSelected,
    placeholder,
    singleSelect,
}: {
    data: Item[]
    defaultSelected?: Item[]
    placeholder?: string
    singleSelect?: boolean
    // items: Item[]
    // filterFn?: (data: any[]) => boolean
    onSelectedChange: (selectedItems: Item[]) => void
}) => {
    const getFilteredItems = useCallback(
        (selectedItems: Item[], inputValue: string) => {
            const lowerCasedInputValue = inputValue.toLowerCase()

            return data.filter((item) => {
                return (
                    !selectedItems.find((i) => i.value === item.value) &&
                    item.label.toLowerCase().startsWith(lowerCasedInputValue)
                )
            })
        },
        [data],
    )

    const [inputValue, setInputValue] = useState('')
    // const [selectedItems, setSelectedItems] = useState<Item[]>([])

    const {
        getSelectedItemProps,
        getDropdownProps,
        removeSelectedItem,
        selectedItems,
        setSelectedItems,
    } = useMultipleSelection<Item>({
        onStateChange({ selectedItems: newSelectedItems, type }) {
            switch (type) {
                case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownBackspace:
                case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
                case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
                case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
                    setSelectedItems(newSelectedItems ?? [])
                    onSelectedChange(newSelectedItems ?? [])
                    break
                default:
                    setSelectedItems(newSelectedItems ?? [])
                    onSelectedChange(newSelectedItems ?? [])
                    break
            }
        },
        onSelectedItemsChange(changes) {
            // onSelectedChange(newSelectedItems!)
        },
        defaultSelectedItems: defaultSelected ?? [],
    })
    const items = useMemo(
        () => getFilteredItems(selectedItems, inputValue),
        [selectedItems, inputValue],
    )
    const {
        isOpen,
        getToggleButtonProps,
        getLabelProps,
        getMenuProps,
        getInputProps,
        highlightedIndex,
        getItemProps,
    } = useCombobox({
        items,
        inputValue,
        selectedItem: null,
        stateReducer(state, actionAndChanges) {
            const { changes, type } = actionAndChanges

            switch (type) {
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                case useCombobox.stateChangeTypes.ItemClick:
                case useCombobox.stateChangeTypes.InputBlur:
                    return {
                        ...changes,
                        ...(changes.selectedItem && { isOpen: false, highlightedIndex: 0 }),
                    }
                default:
                    return changes
            }
        },
        onStateChange({ inputValue: newInputValue, type, selectedItem: newSelectedItem }) {
            switch (type) {
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                case useCombobox.stateChangeTypes.ItemClick:
                    if (!newSelectedItem) {
                        break
                    }
                    setSelectedItems([...selectedItems!, newSelectedItem!])

                    break
                case useCombobox.stateChangeTypes.InputChange:
                    setInputValue(newInputValue!)
                    break
                default:
                    break
            }
        },
    })

    return (
        <Box
            css={{
                d: 'flex',
                fd: 'column',
            }}
        >
            {/* <label {...getLabelProps()}>Choose some elements:</label> */}
            <Input
                {...getInputProps(
                    getDropdownProps({
                        preventKeyAction: isOpen,
                        placeholder:
                            singleSelect && selectedItems.length > 0
                                ? 'Mozete uneti samo jednu akivnost'
                                : placeholder,
                    }),
                )}
                disabled={singleSelect && selectedItems.length > 0}
            />
            {/* <button {...getToggleButtonProps()} aria-label={'toggle menu'}>
                        &#8595;
                    </button> */}
            <Ul
                {...getMenuProps()}
                css={{
                    listStyle: 'none',
                    position: 'absolute',
                    mt: 38,
                    background: '#fff',
                    width: 300,
                    maxHeight: 300,
                    overflowY: 'scroll',
                    boxShadow: '0 0 10px rgba(0,0,0,0.2)',
                    p: '$1',
                    br: '$1',
                    display: isOpen ? 'block' : 'none',
                }}
            >
                {isOpen && items.length > 0 ? (
                    items.map((item, index) => (
                        <Li
                            css={{
                                p: '$0-5',
                                background: highlightedIndex === index ? '#bde4ff' : '#fff',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: 292,
                                br: '$0-5',
                            }}
                            key={`${item.label}${index}`}
                            {...getItemProps({ item, index })}
                        >
                            {item.label}
                        </Li>
                    ))
                ) : (
                    <Li css={{ p: '$0-5' }}>Nema rezultata</Li>
                )}
            </Ul>
            <Box
                css={{
                    d: 'flex',
                    gap: '$1',
                    mt: '$2',
                    flexWrap: 'wrap',
                }}
            >
                {selectedItems.map((selectedItem, index) => (
                    <Box
                        key={`selected-item-${index}`}
                        css={{
                            d: 'flex',
                            ai: 'center',
                            background: '#F3F4F6',
                            px: '$1',
                            py: '$0-25',
                            gap: '$0-5',
                            br: '$0-5',
                        }}
                    >
                        <Text
                            {...getSelectedItemProps({ selectedItem, index })}
                            as="span"
                            css={{
                                color: '#111827',
                                fontWeight: 500,
                            }}
                        >
                            {selectedItem.label}
                        </Text>
                        <svg
                            width="9"
                            height="10"
                            viewBox="0 0 9 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={() => removeSelectedItem(selectedItem)}
                            style={{ cursor: 'pointer' }}
                        >
                            <path
                                d="M1.06067 0.934578L8.13174 8.00565L7.07108 9.06631L8.79169e-06 1.99524L1.06067 0.934578Z"
                                fill="#6B7280"
                            />
                            <path
                                d="M0 8.00564L7.07107 0.93457L8.13173 1.99523L1.06066 9.0663L0 8.00564Z"
                                fill="#6B7280"
                            />
                        </svg>
                    </Box>
                ))}
            </Box>
        </Box>
    )
}
