import { useNavigate, useParams } from 'react-router-dom'

import EditActivities from 'components/EditActivities'

import { Button } from 'ui/Button'
import { Box, Text } from 'ui/Common'
import { DashboardPageLayout } from 'ui/DashboardPageLayout'

export const EditActivitiesPage = () => {
    const params = useParams()

    const cultureId = Number(params?.id)

    const navigate = useNavigate()

    const onSubmit = () => {
        navigate(-1)
    }

    return (
        <DashboardPageLayout>
            <Box
                css={{
                    d: 'flex',
                    gap: '$2',
                    jc: 'space-between',
                    ai: 'center',
                }}
            >
                <Box
                    css={{
                        d: 'flex',
                        fd: 'column',
                        gap: '$1',
                    }}
                >
                    <Text
                        css={{
                            color: '#2C3E50',
                            fontWeight: 600,
                            fontSize: '24px',
                            lineHeight: '28px',
                        }}
                    >
                        Izmeni operacije
                    </Text>
                    <Text
                        css={{
                            'color': 'rgba(0, 0, 0, 0.64)',
                            'fontWeight': 400,
                            'fontSize': '14px',
                            'lineHeight': '16.94px',
                            '@maxSm': {
                                display: 'none',
                            },
                        }}
                    >
                        Izmenite operacije za kulturu.
                    </Text>
                </Box>
                <Button variant="cta" onClick={onSubmit}>
                    Nazad
                </Button>
            </Box>
            <EditActivities cultureId={cultureId} />
        </DashboardPageLayout>
    )
}
