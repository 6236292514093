import { createContext, ReactNode, useContext, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { QueryKey } from 'hooks/api'

export enum Step {
    CULTURES = 'CULTURES',
    ACTIVITIES = 'ACTIVITIES',
    FARM = 'FARM',
}

export const STEP_PROGRESS = {
    [Step.FARM]: 25,
    [Step.CULTURES]: 50,
    [Step.ACTIVITIES]: 75,
}

export type OnboardingStateType = {
    step: Step
    progress: number
    onNextStep: () => void
    onPreviousStep: () => void
}

export const OnboardingStateContext = createContext<OnboardingStateType>({
    step: Step.FARM,
    progress: STEP_PROGRESS[Step.FARM],
    onNextStep: () => {
        throw new Error('OnboardingStateContext: onNextStep is not implemented')
    },
    onPreviousStep: () => {
        throw new Error('OnboardingStateContext: onPreviousStep is not implemented')
    },
})

export const OnboardingStateProvider = ({ children }: { children: ReactNode }) => {
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const [step, setStep] = useState<Step>(Step.FARM)

    const progress = STEP_PROGRESS[step]

    const onNextStep = () => {
        switch (step) {
            case Step.FARM:
                setStep(Step.CULTURES)
                break
            case Step.CULTURES:
                setStep(Step.ACTIVITIES)
                break
            case Step.ACTIVITIES:
                navigate('/kontrolna-tabla')
                queryClient.invalidateQueries(QueryKey.CURRENT_USER)
                break
            default:
                break
        }
    }

    const onPreviousStep = () => {
        switch (step) {
            case Step.FARM:
                break
            case Step.CULTURES:
                setStep(Step.FARM)
                break
            case Step.ACTIVITIES:
                setStep(Step.CULTURES)
                break
            default:
                break
        }
    }

    return (
        <OnboardingStateContext.Provider value={{ step, progress, onNextStep, onPreviousStep }}>
            {children}
        </OnboardingStateContext.Provider>
    )
}

export const useOnboardingState = () => {
    const state = useContext(OnboardingStateContext)

    return state
}
