import { useLocation, useNavigate } from 'react-router-dom'
import { z } from 'zod'
import { t } from 'i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useState } from 'react'

import { trimString } from 'library/utils'

import { FormInput } from 'components/FormInput'

import { useLoginMutation } from 'hooks/api'

import { Button, GhostIconButton } from 'ui/Button'
import { Box, Form, Image, Link, Text } from 'ui/Common'

import EratarLogo from 'assets/eratar_logo.png'

export const LoginPage = () => {
    const [showPassword, setShowPassword] = useState(false)
    const { state } = useLocation()
    const navigate = useNavigate()

    const { mutateAsync, isLoading, error } = useLoginMutation()

    const loginSchema = z.object({
        email: z.preprocess(trimString, z.string().email(t('validation:email.format'))),
        password: z.string().min(1, t('validation:password.min')),
    })

    type loginValues = z.infer<typeof loginSchema>

    const form = useForm({
        resolver: zodResolver(loginSchema),
        defaultValues: { email: '', password: '' },
    })

    const handleSubmit = async (values: loginValues) => {
        try {
            const response = await mutateAsync({ email: values.email, password: values.password })
            if (response.status === 200) {
                if (response.data.isOnboarded) {
                    navigate('/kontrolna-tabla')
                } else {
                    navigate('/onboarding')
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <FormProvider {...form}>
            <Form
                css={{
                    width: '100%',
                    height: '100%',
                    d: 'flex',
                    ai: 'center',
                    jc: 'center',
                    fd: 'column',
                    flexGrow: 1,
                    gap: '$5',
                    px: '$2',
                }}
                onSubmit={form.handleSubmit(handleSubmit)}
            >
                <Image src={EratarLogo} />

                {state?.redirected && (
                    <Box>
                        <Text>
                            Uspešeno ste potvrdili registraciju! Ulogujte se na formi ispod!
                        </Text>
                    </Box>
                )}
                <Box
                    css={{
                        maxWidth: '584px',
                        width: '100%',
                        br: '$1',
                        bc: '#ffffff',
                        border: '1px solid #E4E5E7',
                        d: 'flex',
                        fd: 'column',
                        gap: '$4',
                        py: '$8',
                        px: '$5',
                        ai: 'center',
                        margin: '0 auto',
                    }}
                >
                    <>
                        <Box
                            css={{
                                d: 'flex',
                                fd: 'column',
                                gap: '$0-5',
                                textAlign: 'center',
                            }}
                        >
                            <Text
                                as="h1"
                                css={{
                                    fontWeight: 600,
                                    fontSize: '20px',
                                    lineHeight: '24px',
                                }}
                            >
                                Ulogujte se na svoj eratar nalog
                            </Text>
                            <Text
                                as="h2"
                                css={{
                                    fontWeight: 400,
                                    lineHeight: '17px',
                                    fontSize: '14px',
                                }}
                            >
                                Koristite svoju email adresu i lozinku.
                            </Text>
                        </Box>

                        {state?.redirected && (
                            <Box
                                css={{
                                    d: 'flex',
                                    ai: 'flex-start',
                                    borderLeft: '4px solid #53A551',
                                    background: 'rgba(83, 165, 81, 0.08)',
                                    width: '100%',
                                    px: '$2',
                                    py: '$1-25',
                                    gap: '$1-25',
                                }}
                            >
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g clipPath="url(#clip0_1118_26764)">
                                        <path
                                            d="M10.0001 1.66699C5.40008 1.66699 1.66675 5.40033 1.66675 10.0003C1.66675 14.6003 5.40008 18.3337 10.0001 18.3337C14.6001 18.3337 18.3334 14.6003 18.3334 10.0003C18.3334 5.40033 14.6001 1.66699 10.0001 1.66699ZM8.33342 14.167L4.16675 10.0003L5.34175 8.82533L8.33342 11.8087L14.6584 5.48366L15.8334 6.66699L8.33342 14.167Z"
                                            fill="#53A551"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1118_26764">
                                            <rect width="20" height="20" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>

                                <Box css={{ d: 'flex', fd: 'column', gap: '$0-5' }}>
                                    <Text
                                        css={{
                                            fontWeight: 600,
                                            fontSize: '20px',
                                            color: '#374151',
                                        }}
                                    >
                                        Uspešeno ste potvrdili registraciju!
                                    </Text>
                                    <Text
                                        css={{
                                            fontWeight: 400,
                                            fontSize: '16px',
                                            color: '#6B7280',
                                        }}
                                    >
                                        Ulogujte se na formi ispod!
                                    </Text>
                                </Box>
                            </Box>
                        )}

                        {state?.resetPassword && (
                            <Box
                                css={{
                                    d: 'flex',
                                    ai: 'flex-start',
                                    borderLeft: '4px solid #53A551',
                                    background: 'rgba(83, 165, 81, 0.08)',
                                    width: '100%',
                                    px: '$2',
                                    py: '$1-25',
                                    gap: '$1-25',
                                }}
                            >
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g clipPath="url(#clip0_1118_26764)">
                                        <path
                                            d="M10.0001 1.66699C5.40008 1.66699 1.66675 5.40033 1.66675 10.0003C1.66675 14.6003 5.40008 18.3337 10.0001 18.3337C14.6001 18.3337 18.3334 14.6003 18.3334 10.0003C18.3334 5.40033 14.6001 1.66699 10.0001 1.66699ZM8.33342 14.167L4.16675 10.0003L5.34175 8.82533L8.33342 11.8087L14.6584 5.48366L15.8334 6.66699L8.33342 14.167Z"
                                            fill="#53A551"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1118_26764">
                                            <rect width="20" height="20" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>

                                <Box css={{ d: 'flex', fd: 'column', gap: '$0-5' }}>
                                    <Text
                                        css={{
                                            fontWeight: 600,
                                            fontSize: '20px',
                                            color: '#374151',
                                        }}
                                    >
                                        Uspešeno ste resetovali lozinku!
                                    </Text>
                                    <Text
                                        css={{
                                            fontWeight: 400,
                                            fontSize: '16px',
                                            color: '#6B7280',
                                        }}
                                    >
                                        Ulogujte se sa novom lozinkom na formi ispod!
                                    </Text>
                                </Box>
                            </Box>
                        )}

                        <FormInput
                            name="email"
                            label="Email"
                            placeholder="youremail@comp.com"
                            required
                        />
                        <FormInput
                            name="password"
                            label="Lozinka"
                            placeholder="***"
                            required
                            labelHelpEl={
                                <Link
                                    to="/promena-lozinke"
                                    css={{
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                    }}
                                >
                                    Resetuj lozinku
                                </Link>
                            }
                            type={showPassword ? 'text' : 'password'}
                            autoComplete="current-password"
                            rightEl={
                                <GhostIconButton
                                    onClick={() => setShowPassword((p) => !p)}
                                    type="button"
                                >
                                    {showPassword ? (
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M10.7429 5.09232C11.1494 5.03223 11.5686 5 12.0004 5C17.1054 5 20.4553 9.50484 21.5807 11.2868C21.7169 11.5025 21.785 11.6103 21.8231 11.7767C21.8518 11.9016 21.8517 12.0987 21.8231 12.2236C21.7849 12.3899 21.7164 12.4985 21.5792 12.7156C21.2793 13.1901 20.8222 13.8571 20.2165 14.5805M6.72432 6.71504C4.56225 8.1817 3.09445 10.2194 2.42111 11.2853C2.28428 11.5019 2.21587 11.6102 2.17774 11.7765C2.1491 11.9014 2.14909 12.0984 2.17771 12.2234C2.21583 12.3897 2.28393 12.4975 2.42013 12.7132C3.54554 14.4952 6.89541 19 12.0004 19C14.0588 19 15.8319 18.2676 17.2888 17.2766M3.00042 3L21.0004 21M9.8791 9.87868C9.3362 10.4216 9.00042 11.1716 9.00042 12C9.00042 13.6569 10.3436 15 12.0004 15C12.8288 15 13.5788 14.6642 14.1217 14.1213"
                                                stroke="#6B7280"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                    ) : (
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g clipPath="url(#clip0_1118_26716)">
                                                <path
                                                    d="M12 6.5C15.79 6.5 19.17 8.63 20.82 12C19.17 15.37 15.8 17.5 12 17.5C8.2 17.5 4.83 15.37 3.18 12C4.83 8.63 8.21 6.5 12 6.5ZM12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 9.5C13.38 9.5 14.5 10.62 14.5 12C14.5 13.38 13.38 14.5 12 14.5C10.62 14.5 9.5 13.38 9.5 12C9.5 10.62 10.62 9.5 12 9.5ZM12 7.5C9.52 7.5 7.5 9.52 7.5 12C7.5 14.48 9.52 16.5 12 16.5C14.48 16.5 16.5 14.48 16.5 12C16.5 9.52 14.48 7.5 12 7.5Z"
                                                    fill="#6B7280"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1118_26716">
                                                    <rect width="24" height="24" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    )}
                                </GhostIconButton>
                            }
                        />
                        <Button
                            css={{ width: '100%' }}
                            type="submit"
                            disabled={form.formState.isSubmitting}
                            isLoading={form.formState.isSubmitting}
                        >
                            Prijavite se
                        </Button>
                        {form.formState.isSubmitted && error && (
                            <Box
                                css={{
                                    d: 'flex',
                                    ai: 'flex-start',
                                    borderLeft: '4px solid #D64949',
                                    background: 'rgba(215, 81, 81, 0.08)',
                                    width: '100%',
                                    px: '$2',
                                    py: '$1-25',
                                    gap: '$1-25',
                                }}
                            >
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ minWidth: 20, minHeight: 20 }}
                                >
                                    <path
                                        d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM15 13.59L13.59 15L10 11.41L6.41 15L5 13.59L8.59 10L5 6.41L6.41 5L10 8.59L13.59 5L15 6.41L11.41 10L15 13.59Z"
                                        fill="#D64949"
                                    />
                                </svg>

                                <Box css={{ d: 'flex', fd: 'column', gap: '$0-5' }}>
                                    <Text
                                        css={{
                                            fontWeight: 600,
                                            fontSize: '20px',
                                            color: '#374151',
                                        }}
                                    >
                                        Vaši podaci za prijavu nisu ispravni!
                                    </Text>
                                    <Text
                                        css={{
                                            fontWeight: 400,
                                            fontSize: '16px',
                                            color: '#6B7280',
                                        }}
                                    >
                                        Molimo Vas da pažljivo proverite unetu email adresu i
                                        lozinku.
                                        <br />
                                        <br />
                                        Ukoliko ste nedavno registrovali nalog, proverite da li ste
                                        obavili verifikaciju putem email-a koji smo Vam poslali.
                                        <br /> <br />
                                        Ako i dalje imate problema sa prijavom, možda je potrebno da
                                        resetujete lozinku. Da biste to učinili, kliknite na opciju
                                        "Resetuj lozinku".
                                    </Text>
                                </Box>
                            </Box>
                        )}
                    </>
                </Box>
                <Text
                    css={{
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: '#2C3E50',
                    }}
                >
                    Još uvek nemate nalog? <Link to="/registracija">Registrujte se.</Link>{' '}
                </Text>
            </Form>
        </FormProvider>
    )
}
