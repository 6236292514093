import { ReactNode, useState } from 'react'

import { useAddActivityToFieldMutation, useAgroActivitiesQuery } from 'hooks/api'

import { Box, Image, Text } from 'ui/Common'
import { BaseButton } from 'ui/Button'
import { DialogBody, DialogTrigger, Dialog } from 'ui/Dialog'

import AddIcon from 'assets/icons/add.svg'
import AddGreenIcon from 'assets/icons/add_green.svg'
import EditIcon from 'assets/icons/edit.svg'

import { AddActivityDialog } from './AddActivityDialog'
import { EditActivityDialog } from './EditActivityDialog'

export const AddActivityToFieldDialog = ({
    children,
    fieldId,
}: {
    children: ReactNode
    fieldId: string
}) => {
    const [open, setOpen] = useState(false)
    const { data: activities, isFetching: isLoadingActivities } = useAgroActivitiesQuery()

    const { mutateAsync: selectFarmAgroCultureActivity } = useAddActivityToFieldMutation()

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>{children}</DialogTrigger>
            <DialogBody title="Dodaj aktivnost" showCloseIcon>
                <Box
                    css={{
                        d: 'flex',
                        fg: 2,
                        fd: 'column',
                        p: '$1-5',
                        gap: '$3',
                    }}
                >
                    {activities?.map((activity) => (
                        <Box
                            css={{
                                d: 'flex',
                                fd: 'column',
                                gap: '$1',
                            }}
                            key={activity.id}
                        >
                            <Text
                                css={{
                                    fontSize: '16px',
                                    color: 'rgba(44, 62, 80, 0.64)',
                                }}
                            >
                                {activity.name}
                            </Text>
                            <Box
                                css={{
                                    d: 'flex',
                                    flexWrap: 'wrap',
                                    gap: '$1-25',
                                }}
                            >
                                {activity.id === 0 && (
                                    <AddActivityDialog>
                                        <BaseButton
                                            css={{
                                                d: 'flex',
                                                ai: 'center',
                                                pl: '$1',
                                                background: '#fff',
                                                pr: '$1-5',
                                                py: '$1-25',
                                                gap: '$0-5',
                                                br: '$0-5',
                                                border: '1px solid #53A551',
                                            }}
                                        >
                                            <Image src={AddGreenIcon} />
                                            <Text
                                                css={{
                                                    fontSize: '14px',
                                                    color: '#53A551',
                                                }}
                                            >
                                                Dodaj
                                            </Text>
                                        </BaseButton>
                                    </AddActivityDialog>
                                )}
                                {activity.items.map((item, index) => (
                                    <BaseButton
                                        css={{
                                            d: 'flex',
                                            ai: 'center',
                                            background: '#FFFFFF',
                                            pl: '$1',
                                            pr: '$1-5',
                                            py: '$1-25',
                                            gap: '$0-5',
                                            br: '$0-5',
                                            border: '1px solid #D1D5DB',
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            selectFarmAgroCultureActivity({
                                                agroActivityIds: [item.id],
                                                fieldId: Number(fieldId),
                                            })
                                            setOpen(false)
                                        }}
                                        key={item.name + item.id + index}
                                    >
                                        <Image src={AddIcon} />
                                        <Text
                                            css={{
                                                fontSize: '14px',
                                                color: '#2C3E50',
                                            }}
                                        >
                                            {item.name}
                                        </Text>
                                        {activity.id === 0 && (
                                            <EditActivityDialog activity={item}>
                                                <Image src={EditIcon} />
                                            </EditActivityDialog>
                                        )}
                                    </BaseButton>
                                ))}
                            </Box>
                        </Box>
                    ))}
                </Box>
            </DialogBody>
        </Dialog>
    )
}
