import { httpClient } from 'library/http'

import {
    GetCatalogGroupCategoriesRequest,
    GetCatalogGroupCategoriesResponse,
    GetCatalogGroupsResponse,
    GetCatalogsRequest,
    GetCatalogsResponse,
} from './CatalogsServiceTypes'

export class CatalogsService {
    private _catalogsUrl = '/catalogs'
    public async getCatalogGroups() {
        const { data } = await httpClient.get<GetCatalogGroupsResponse>(
            `${this._catalogsUrl}/groups`,
        )
        return data.reverse()
    }

    public async getCatalogGroupCategories({ catalogGroupId }: GetCatalogGroupCategoriesRequest) {
        const { data } = await httpClient.get<GetCatalogGroupCategoriesResponse>(
            `${this._catalogsUrl}/groups/${catalogGroupId}/categories`,
        )

        return data
    }

    public async getCatalogs(request: GetCatalogsRequest) {
        const { data } = await httpClient.get<GetCatalogsResponse>(`${this._catalogsUrl}`, {
            params: request,
        })

        return data
    }
}

export const catalogsService = new CatalogsService()
