import { z } from 'zod'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { ReactNode, useEffect, useState } from 'react'
import { t } from 'i18next'

import { GetFarmFieldResponse } from 'library/services/FarmFieldsTypes'

import { FormInput } from 'components/FormInput'

import { useAgroCulturesQuery, useFarmQuery, useUpdateFieldMutation } from 'hooks/api'

import { Box, Form } from 'ui/Common'
import { DialogBody, DialogTrigger, Dialog, DialogClose } from 'ui/Dialog'
import { Button } from 'ui/Button'

import { FormSelect } from './FormSelect'

export const EditFieldDialog = ({
    children,
    field,
}: {
    children?: ReactNode
    field: GetFarmFieldResponse
}) => {
    const { data: farm } = useFarmQuery()
    const { data } = useAgroCulturesQuery()
    const filteredCultures = data ? data?.filter((culture) => culture.selected) : []

    const cultureOptions = filteredCultures?.map((culture) => ({
        value: String(culture.id),
        label: culture.name,
    }))

    const [open, setOpen] = useState(false)
    const { mutateAsync, isLoading, error } = useUpdateFieldMutation()

    const fieldSchema = z.object({
        name: z.string().min(2, t('validation:cultureName.min')),
        agroCultureId: z.coerce.number({
            invalid_type_error: t('validation:area.min'),
            required_error: t('validation:area.min'),
        }),

        sort: z.string().min(2, t('validation:cultureName.min')).optional().or(z.literal('')),
        region: z.string().min(2, t('validation:cultureName.min')).optional().or(z.literal('')),
        ownedArea: z.coerce
            .number({
                invalid_type_error: t('validation:area.min'),
            })
            .nonnegative(t('validation:area.min'))
            .optional(),
        rentArea: z.coerce
            .number({
                invalid_type_error: t('validation:area.min'),
            })
            .nonnegative(t('validation:area.min'))
            .optional(),
    })

    type FieldValues = z.infer<typeof fieldSchema>

    const form = useForm<FieldValues>({
        resolver: zodResolver(fieldSchema),
        defaultValues: {
            name: field?.name ?? '',
            agroCultureId: (field?.agroCulture.id.toString() as unknown as number) ?? undefined,
            sort: field?.sort ?? '',
            region: field?.region ?? '',
            ownedArea: field?.ownedArea ?? 0,
            rentArea: field?.rentArea ?? 0,
        },
    })

    const handleSubmit: SubmitHandler<FieldValues> = async (values) => {
        try {
            const response = await mutateAsync({
                id: field.id,
                name: values.name,
                rentArea: values.rentArea,
                ownedArea: values.ownedArea,
                agroCultureId: values.agroCultureId,
                sort: values.sort,
                region: values.region,
            })
            setOpen(false)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        form.reset()
    }, [open])

    useEffect(() => {
        form.reset({
            name: field?.name ?? '',
            agroCultureId: (field?.agroCulture.id.toString() as unknown as number) ?? undefined,
            sort: field?.sort ?? '',
            region: field?.region ?? '',
            ownedArea: field?.ownedArea ?? 0,
            rentArea: field?.rentArea ?? 0,
        })
    }, [field])

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger
                asChild
                onClick={(e) => {
                    e.stopPropagation()
                    setOpen(true)
                }}
            >
                {children}
            </DialogTrigger>
            <DialogBody title="Izmenite njivu">
                <FormProvider {...form}>
                    <Form
                        css={{
                            d: 'flex',
                            fd: 'column',
                            gap: '$3',
                        }}
                        onSubmit={form.handleSubmit(handleSubmit)}
                    >
                        <FormInput
                            name="name"
                            label="Naziv njive"
                            placeholder="Unesite naziv njive"
                            required
                        />

                        {/* <FormInput
                            name="area"
                            label={`Površina (${farm?.areaMeasureUnit.abbreviation})`}
                            placeholder="Unesite površinu"
                        /> */}

                        <FormSelect
                            name="agroCultureId"
                            placeholder="Odaberite kulturu"
                            label="Kultura*"
                            items={cultureOptions}
                        />

                        <FormInput
                            name="sort"
                            label="Sorta/Hibrid"
                            placeholder="Unesite naziv sorte/hibrida"
                        />

                        <FormInput name="region" label="Potes" placeholder="Unesite potes" />
                        <Box css={{ d: 'flex', jc: 'space-between', gap: '$3' }}>
                            <FormInput
                                name="ownedArea"
                                label={`Vlasništvo (${farm?.areaMeasureUnit.abbreviation})`}
                                placeholder="Unesite površinu"
                            />
                            <FormInput
                                name="rentArea"
                                label={`Zakup (${farm?.areaMeasureUnit.abbreviation})`}
                                placeholder="Unesite površinu"
                            />
                        </Box>
                        <Box
                            css={{
                                d: 'flex',
                                ai: 'center',
                                jc: 'flex-end',
                                gap: '$2',
                            }}
                        >
                            <DialogClose asChild>
                                <Button variant="secondary" type="button">
                                    Izađi
                                </Button>
                            </DialogClose>
                            <Button
                                type="submit"
                                isLoading={isLoading}
                                disabled={isLoading || !form.formState.isDirty}
                            >
                                Sačuvaj
                            </Button>
                        </Box>
                    </Form>
                </FormProvider>
            </DialogBody>
        </Dialog>
    )
}
