import React from 'react'
import * as RSelect from '@radix-ui/react-select'
import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons'

import { CSS, styled } from './stitches.config'

interface SelectProps extends React.ComponentProps<typeof RSelect.Root> {
    items: ({ label: string; value: string } | null)[]
    placeholder?: string
    label?: string
    css?: CSS
    name?: string
}

export const Select = ({ items, placeholder, label, css, ...selectProps }: SelectProps) => {
    return (
        <RSelect.Root {...selectProps} value={selectProps.value ? selectProps.value : undefined}>
            <SelectTrigger aria-label={label} css={css}>
                <RSelect.Value placeholder={placeholder} />
                <SelectIcon>
                    <ChevronDownIcon />
                </SelectIcon>
            </SelectTrigger>
            <RSelect.Portal>
                <SelectContent css={{ zIndex: 4 }}>
                    <SelectScrollUpButton>
                        <ChevronUpIcon />
                    </SelectScrollUpButton>
                    <SelectViewport>
                        <RSelect.Group>
                            {items.map((item) => {
                                if (item) {
                                    return (
                                        <SelectItem value={item.value} key={item.value}>
                                            {item.label}
                                        </SelectItem>
                                    )
                                } else {
                                    return <SelectSeparator />
                                }
                            })}
                        </RSelect.Group>
                    </SelectViewport>
                    <SelectScrollDownButton>
                        <ChevronDownIcon />
                    </SelectScrollDownButton>
                </SelectContent>
            </RSelect.Portal>
        </RSelect.Root>
    )
}

export const BaseSelect = ({ items, placeholder, label, css, ...selectProps }: SelectProps) => {
    return (
        <RSelect.Root {...selectProps} value={selectProps.value ? selectProps.value : undefined}>
            <SelectTrigger
                aria-label={label}
                css={{
                    border: '1px solid transparent',
                    background: 'transparent',
                    jc: 'flex-end',
                    p: 0,
                    ...css,
                }}
            >
                <RSelect.Value placeholder={placeholder} />
                <SelectIcon>
                    <ChevronDownIcon />
                </SelectIcon>
            </SelectTrigger>
            <RSelect.Portal>
                <SelectContent>
                    <SelectScrollUpButton>
                        <ChevronUpIcon />
                    </SelectScrollUpButton>
                    <SelectViewport>
                        <RSelect.Group>
                            {items.map((item) => {
                                if (item) {
                                    return (
                                        <SelectItem value={item.value} key={item.value}>
                                            {item.label}
                                        </SelectItem>
                                    )
                                } else {
                                    return <SelectSeparator />
                                }
                            })}
                        </RSelect.Group>
                    </SelectViewport>
                    <SelectScrollDownButton>
                        <ChevronDownIcon />
                    </SelectScrollDownButton>
                </SelectContent>
            </RSelect.Portal>
        </RSelect.Root>
    )
}

const SelectSeparator = styled(RSelect.Separator, {
    height: 1,
    backgroundColor: 'rgb(209, 213, 219)',
    margin: 5,
})

const SelectTrigger = styled(RSelect.SelectTrigger, {
    'all': 'unset',
    'boxSizing': 'border-box',
    'display': 'inline-flex',
    'alignItems': 'center',
    'justifyContent': 'space-between',
    'lineHeight': 1,
    'gap': 5,
    '&[data-placeholder]': { color: '#6D717F' },
    'backgroundColor': '#FFFFFF',
    'color': '#6D717F',
    'padding': '$1-25',
    'fontSize': '14px',
    'br': '$1',
    'border': '1px solid #E4E5E7',
    '&:focus-visible': {
        outline: ' -webkit-focus-ring-color auto 1px',
    },
})

const SelectIcon = styled(RSelect.SelectIcon, {
    color: 'black',
})

const SelectContent = styled(RSelect.Content, {
    overflow: 'hidden',
    backgroundColor: '#fff',
    br: '$1',
    border: '1px solid #E4E5E7',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
})

const SelectViewport = styled(RSelect.Viewport, {
    padding: 5,
})

const SelectItem = React.forwardRef(({ children, ...props }: any, forwardedRef) => {
    return (
        <StyledItem {...props} ref={forwardedRef}>
            <RSelect.ItemText>{children}</RSelect.ItemText>
            <StyledItemIndicator>
                <CheckIcon />
            </StyledItemIndicator>
        </StyledItem>
    )
})

const StyledItem = styled(RSelect.Item, {
    'fontSize': 13,
    'lineHeight': 1,
    'color': '#6D717F',
    'borderRadius': 3,
    'display': 'flex',
    'alignItems': 'center',
    'height': 25,
    'padding': '0 35px 0 25px',
    'position': 'relative',
    'userSelect': 'none',

    '&[data-disabled]': {
        color: 'salmon',
        pointerEvents: 'none',
    },

    '&[data-highlighted]': {
        outline: 'none',
        backgroundColor: '#fafafa',
        color: '#6D717F',
    },
})

const StyledItemIndicator = styled(RSelect.ItemIndicator, {
    position: 'absolute',
    left: 0,
    width: 25,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
})

const scrollButtonStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 25,
    backgroundColor: 'white',
    color: 'blue',
    cursor: 'default',
}

const SelectScrollUpButton = styled(RSelect.ScrollUpButton, scrollButtonStyles)

const SelectScrollDownButton = styled(RSelect.ScrollDownButton, scrollButtonStyles)

export default Select
